import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AuthService } from '../services/auth/auth.service';
import { WindowRef } from '../services/window/window.service';

@Injectable()
export class CdiRedirectResolver implements Resolve<string> {
  constructor(private windowRef: WindowRef, private authService: AuthService) {}

  /**
   * Resolve methods
   * @param route - route snpashot
   */
  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    const externalUrl = `${this.authService.getCdiUrl()}/${route.data.externalUrl}`;
    // redirect user to external url
    this.windowRef.nativeWindow.location.href = externalUrl;
    return of(externalUrl);
  }
}
