import { NgModule } from '@angular/core';

import { FileSizePipe } from './pipes/file-size/file-size.pipe';
import { HighLightPipe } from './pipes/highlight/highlight.pipe';
import { LocalizedDatePipe } from './pipes/localized-date/localized-date.pipe';
import { OrdinalPipe } from './pipes/ordinal/ordinal.pipe';
import { MoneyPipe } from './pipes/money/money.pipe';

@NgModule({
  declarations: [FileSizePipe, HighLightPipe, OrdinalPipe, LocalizedDatePipe, MoneyPipe],
  exports: [FileSizePipe, HighLightPipe, OrdinalPipe, LocalizedDatePipe, MoneyPipe]
})
export class PipesModule {}
