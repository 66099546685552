import { createAction, props } from '@ngrx/store';

import { Concession } from '../../models/concession/concession.interface';
import { Menu } from '../../models/menu/menu.interface';

export const getMenu = createAction('[MENU] GET_MENU', props<{ concession: Concession }>());
export const getMenuSuccess = createAction(
  '[MENU] GET_MENU_SUCCESS',
  props<{
    menuItems: Array<Menu>;
    selectedIds: Array<string>;
  }>()
);
export const getMenuFailure = createAction('[MENU] GET_MENU_FAILURE', props<{ error: string }>());
export const selectItem = createAction('[MENU] SELECT_ITEM', props<{ selectedItem: string }>());
export const selectSubMenu = createAction('[MENU] SELECT_SUBMENU_ITEM', props<{ selectedSubmenuItem: Array<string> }>());
export const toggleMenu = createAction('[MENU] TOGGLE_MENU', props<{ opened: boolean }>());
export const toggleMenuMode = createAction('[MENU] TOGGLE_MENU_MODE', props<{ mode: boolean }>());
