import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ALL_QUALIFICATIONS, CollaboratorQualifications, Concession } from '../../models';
import { User } from '../../models/user/user.interface';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import { PaginatedResult } from './../../models/api-response/api-response.interface';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorsService {
  concession: Concession;

  constructor(private apiService: ApiService, private authService: AuthService) {
    this.concession = this.authService.getConcession() || null;
  }

  /**
   * Returns a list of collaborators.
   * @param search string - search string.
   * @param qualifications Array<CollaboratorQualifications | string> - Collaborator's qualification filters.
   * @returns Observable - Returns the http request observable.
   */
  getCollaborators(
    search: Record<string, string>,
    filters: {
      qualifications?: Array<CollaboratorQualifications | string>;
      page?: number;
      numberPerPage?: number;
    } = { qualifications: ALL_QUALIFICATIONS, page: 1, numberPerPage: 20 }
  ): Observable<PaginatedResult<User>> {
    const params = {
      ...search,
      concession: this.concession.slug,
      'qualifications[]': filters.qualifications || ALL_QUALIFICATIONS,
      page: filters.page || 1,
      numberPerPage: filters.numberPerPage || 20
    };
    return this.apiService.get<PaginatedResult<User>>('wis', '/directory/directories/collaborator/search', { params });
  }

  /**
   * Update a collaborator from api.
   * @param collaborator - collaborator Object.
   */
  updateCollaborator(collaborator: User): Observable<User> {
    return this.apiService.put<User>('wis', `/directory/collaborators/${collaborator.id}/update`, collaborator);
  }
}
