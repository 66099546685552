import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  Notification,
  NotificationLabel,
  NotificationReadEvent
} from '../../../../models/notification/notification.interface';

@Component({
  selector: 'iad-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnChanges {
  NotificationLabel = NotificationLabel;
  @Input() notification: Notification;
  @Output() notifDeleted: EventEmitter<number>;
  @Output() notifRead: EventEmitter<NotificationReadEvent>;
  appIconConfig: { show: boolean; fontIcon: string; iconTitle: string };
  readIconConfig: { readIcon: string; trad: string };

  constructor() {
    this.notifDeleted = new EventEmitter<number>();
    this.notifRead = new EventEmitter<NotificationReadEvent>();
  }

  /**
   * Handle click on notification.
   * @param $event - host listener event.
   */
  @HostListener('click', ['$event'])
  onNotifClick($event: Event): void {
    this.readNotif($event, 'block');
  }

  /**
   * OnInit
   */
  ngOnInit(): void {
    // Static configuration
    this.appIconConfig = {
      show:
        this.notification.type.label === NotificationLabel.IMPORTANT ||
        this.notification.type.label === NotificationLabel.INFORMATIVE,
      fontIcon: this.notification.type.label === NotificationLabel.IMPORTANT ? 'fa-archive' : 'fa-trash-alt',
      iconTitle:
        this.notification.type.label === NotificationLabel.IMPORTANT
          ? _('iadNotification.buttons.archive.title') as string
          : _('iadNotification.buttons.delete.title') as string
    };
  }

  /**
   * OnChanges
   * @param changes - changes object
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notification) {
      // Dynamic configuration
      this.readIconConfig = {
        readIcon: this.notification.read ? 'fa-eye' : 'fa-eye-slash',
        trad: (
          (this.notification.read
            ? _('iadNotification.buttons.markAsNotRead.title')
            : _('iadNotification.buttons.markAsRead.title'))
        ) as string
      };
    }
  }

  /**
   * Handle notification toggle read/unread.
   * @param $event - click event
   * @param typeElement - Notif type.
   */
  readNotif($event: Event, typeElement: string): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.notifRead.emit({
      id: this.notification.notificationId,
      url: this.notification.url,
      read: this.notification.read,
      typeElement
    });
  }

  /**
   * Remove a notif.
   * @param $event - click event.
   */
  deleteNotif($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.notifDeleted.emit(this.notification.notificationId);
  }
}
