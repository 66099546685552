import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import { UtilService } from '../../services/util/util.service';
import { DownloadFile } from './../../models/download/download.interface';
import * as FileActions from './file.actions';

@Injectable()
export class FileEffects {
  downloadFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FileActions.downloadFile),
        map(action => action.payload),
        tap((payload: DownloadFile) => this.utilService.downloadFile(payload))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private utilService: UtilService) {}
}
