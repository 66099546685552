import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DownloadFile } from '../../models/download/download.interface';
import { UtilService } from './../../services/util/util.service';

@Directive({
  selector: '[iadDownload]'
})
export class DownloadDirective implements OnChanges {
  @Input() iadDownload: DownloadFile;

  constructor(private utilService: UtilService) {}

  /**
   * Life cycle OnChanges
   * @param changes - changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.iadDownload &&
      changes.iadDownload.currentValue &&
      typeof changes.iadDownload.currentValue === 'object'
    ) {
      this.downloadFile(changes.iadDownload.currentValue);
    }
  }

  /**
   * Launch file download.
   * @param file - downloadFile object
   */
  downloadFile(download: DownloadFile): void {
    this.utilService.downloadFile(download);
  }
}
