import { BreadCrumb } from '../../models/breadcrumb';

/**
 * Helper function to redirect on Cdi App.
 *
 * @param breadcrumbId - breadcrumb id.
 * @param breadcrumbLabel - breadcrumb label.
 */
export function redirectToCdi(breadcrumbId: string, breadcrumbLabel: string | Array<string>): Record<string, any> {
  return {
    breadcrumb: new BreadCrumb(breadcrumbId, breadcrumbLabel),
    redirect: {
      rootKeyUrl: 'cdi',
      url: ':concession',
      paramsId: ['concession']
    }
  };
}
