import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'iad-xeditable',
  templateUrl: './xeditable.component.html',
  styleUrls: ['./xeditable.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XeditableComponent),
      multi: true
    }
  ]
})
export class XeditableComponent implements ControlValueAccessor {
  @ViewChild('xEditInput', {static: false})
  set xEditInputRef(elementRef: ElementRef) {
    this.xEditInput = elementRef;
  }
  // label for xeditable in readonly mode.
  @Input() label: string;
  // input value for xeditable
  @Input() value: string;
  // callback after save button
  @Output() save: EventEmitter<string>;

  // get element input ref via ViewChild
  xEditInput: ElementRef;
  // property to handle readonly/editable mode
  isReadOnly: boolean;
  // property to indicate if a placeholder in the input should be shown
  showPlaceHolder: boolean;

  constructor() {
    this.isReadOnly = true;
    this.showPlaceHolder = true;
    this.save = new EventEmitter();
  }

  // higher order function to propagate changes
  propagateChange = (_: any): void => {};

  /**
   * Input on change method.
   * @param value - input value.
   */
  valueChange(value: string): void {
    // propagate value in higher components.
    this.propagateChange(value);
  }

  /**
   * Method to toggle display label / input.
   */
  toggleEditable(): void {
    this.isReadOnly = !this.isReadOnly;
    if (!this.isReadOnly) {
      setTimeout(() => {
        this.xEditInput.nativeElement.focus();
      });
    }
  }

  /**
   * Method to propagate input value with event Emitter.
   */
  onSave(): void {
    this.save.emit(this.value);
    this.toggleEditable();
  }

  /**
   * Method overriden from ControlValueAccessor.
   * @param value - the input value updated.
   */
  writeValue(value: string): void {
    if (value) {
      this.value = value;
      this.setLabelValue();
    }
  }

  /**
   * Convenient method to set label attribute and placeholder.
   */
  setLabelValue(): void {
    if (!this.label) {
      this.showPlaceHolder = false;
      this.label = this.value;
    }
  }

  /**
   * Get xeditable label
   */
  getLabel(): string {
    return this.value || this.label;
  }

  /**
   * Get input placeholder
   */
  getPlaceHolder(): string {
    return this.showPlaceHolder ? this.label : '';
  }

  /**
   * Method overriden from ControlValueAccessor.
   * @param fn - function propagateChange.
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Method overriden from ControlValueAccessor.
   * @param fn - function propagateChange.
   */
  registerOnTouched(fn: any): void {}
}
