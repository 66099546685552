import { Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Notifications } from '../../models/notification/notification.interface';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private apiService: ApiService) {}

  /**
   *
   * Method which returns notifications from server.
   *
   * @returns The http request observable.
   */
  getNotifications(): Observable<{} | Array<Notifications>> {
    return this.apiService
      .get<Array<Notifications>>('wis', '/directory/collaborator-notifications')
      .pipe(catchError((err: { code: number; message: string }) => throwError(this.handleError(err.code))));
  }

  /**
   * Get the notifications count observable that emits the next count value.
   *
   * @returns The notifications count observable.
   */
  getNotificationsCount(): Observable<number> {
    return this.getNotifications().pipe(
      map((notifications: Array<any>) => {
        return Object.keys(notifications)
          .map((key: string): Array<any> => notifications[key])
          .reduce((totalCount: number, note: any): number => {
            return totalCount + (note.list ? note.list.length : 0);
          }, 0);
      })
    );
  }

  /**
   *
   * Delete a notification(s).
   *
   * @param notificationIds - Array of notification ids.
   * @returns The http request observable.
   */
  deleteNotification(notificationIds: Array<number>): Observable<{}> {
    let data = '';
    notificationIds.forEach((id: number, index: number) => {
      data.length > 0 ? (data += '&') : (data += '');
      data += `notificationIds[${index}]=${id}`;
    });
    const config = {
      body: data,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' }
    };
    return this.apiService
      .delete('wis', '/directory/notifications', config)
      .pipe(catchError((err: { code: number; message: string }) => throwError(this.handleError(err.code))));
  }

  /**
   * Toggle Notification read/unread.
   *
   * @param id - The notification unique identifier.
   * @returns The http request observable.
   */
  toggleReadNotification(id: number): Observable<{}> {
    return this.apiService
      .patch('wis', `/directory/toggle-read-notification/${id}`, null)
      .pipe(catchError((err: { code: number; message: string }) => throwError(this.handleError(err.code))));
  }

  /**
   * Handle error from notification service.
   * @param code - The service code error.
   * @returns The translate key error message.
   */
  private handleError(code: number): string | Array<string> {
    switch (code) {
      default:
        return _('iadNotificationService.errors.unavailable');
    }
  }
}
