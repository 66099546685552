import { Injectable, Optional } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Locale } from '../../models/locality/locale.enum';
import { LocaleConstant } from './locale.constants';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  // application locale.
  locale: string;

  // application default locale
  defaultLocale: string;

  constructor(
    @Optional() private adapter: DateAdapter<any>,
    private translate: TranslateService
  ) {
    this.locale = this.defaultLocale = Locale.EN_GB as string;
  }

  /**
   * Method to change locale.
   * @param locale - locale to set.
   */
  setLocale(locale: string): Observable<any> {
    this.locale = locale;

    return this.translate.use(locale).pipe(
      tap(() => {
        if (this.adapter) {
          const customLocale = this.getCustomLocale('-');
          moment.locale(customLocale);
          this.adapter.setLocale(customLocale);
        }
      })
    );
  }

  /**
   * Method to set default locale value.
   * @param locale - The locale to set as default.
   */
  setDefaultLocale(locale: string): void {
    this.defaultLocale = locale;
    this.translate.setDefaultLang(this.defaultLocale);
  }

  /**
   * Get current locale.
   */
  getLocale(): string {
    return this.locale;
  }

  /**
   * Get locale by id.
   * @param id - locale id.
   */
  getSupportedLocale(id: string): any {
    return LocaleConstant.SUPPORTED_LOCALES.find((supportedLocale: any) => supportedLocale.id === id);
  }

  /**
   * Retrieve first locale which begins with iso2 country.
   * @param iso2 - iso2 country.
   * @returns Supported locale object.
   */
  getSupportedLocaleFromIso2(iso2: string): any {
    return LocaleConstant.SUPPORTED_LOCALES.find((supportedLocale: any) => supportedLocale.id.startsWith(iso2));
  }

  /**
   * is Locale supported.
   * @param id - locale id.
   */
  isSupported(id: string): boolean {
    return !!this.getSupportedLocale(id);
  }

  /**
   * Get country from locale.
   * @param locale - locale
   */
  getLocaleCountry(locale: string): string {
    return locale.substring(3).toLowerCase();
  }

  /**
   * Get locale label.
   * @param id - locale id.
   */
  getLocaleLabel(id: string): string {
    const language = this.getSupportedLocale(id);
    return language ? language.label : '';
  }

  /**
   * Get current locale formatted with a custom separator.
   * @param separator - custom separator.
   * @returns The formatted locale.
   */
  getCustomLocale(separator: string = null): string {
    return separator ? this.getLocale().replace('_', separator) : this.getLocale();
  }
}
