/**
 *  Define all collaborator statuses.
 */
export enum CollaboratorStatusEnum {
  ACTIVE = 'reference.collaborator_status.active',
  BLOCKED = 'reference.collaborator_status.blocked',
  WARNING = 'reference.collaborator_status.warning',
  PENDING_AGREMENT = 'reference.collaborator_status.new',
  ARCHIVED = 'reference.collaborator_status.archived'
}
