import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as introJsImported from 'intro.js';
import { Subject } from 'rxjs';

import { QuicktourStep } from '../../models/quicktour/quicktour-step.interface';
import { INTRO_DEFAULT_STEPS } from './default-config.constant';

const introJs = introJsImported;

@Injectable({
  providedIn: 'root'
})
export class QuicktourService {
  intro: any;
  steps: Array<QuicktourStep>;
  completed$: Subject<void>;

  constructor(private translate: TranslateService) {
    this.intro = introJs();
    this.steps = INTRO_DEFAULT_STEPS;
    this.completed$ = new Subject<void>();
    this.intro.onbeforeexit(() => this.completed$.next());
  }

  /**
   * Set different steps for the quick tour.
   * @param QuicktourStep[] steps - An array of quick tour step
   * @param boolean overwrite - if true, default steps will be overwrite. If false, new steps will be concat to the default steps.
   */
  setSteps(steps: Array<QuicktourStep>, overwrite: boolean = true): void {
    overwrite ? (this.steps = steps) : (this.steps = [...this.steps, ...steps]);
  }

  /**
   * Set quicktour's default configuration
   */
  setDefaultSteps(): void {
    this.setSteps(INTRO_DEFAULT_STEPS);
  }

  /**
   * Start Intro JS guided tour.
   */
  startIntroJS(): void {
    this.intro.setOptions({
      prevLabel: this.translate.instant('quicktourService.button.prevLabel'),
      nextLabel: this.translate.instant('quicktourService.button.nextLabel'),
      skipLabel: this.translate.instant('quicktourService.button.skipLabel'),
      doneLabel: this.translate.instant('quicktourService.button.doneLabel'),
      hideNext: true,
      hidePrev: true,
      showStepNumbers: false,
      showBullets: false,
      disableInteraction: true,
      overlayOpacity: 0.6,
      exitOnOverlayClick: false,
      steps: this.steps.map((step: QuicktourStep) => ({ ...step, intro: this.translate.instant(step.intro) }))
    });
    this.intro.start();
  }
}
