import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { WindowRef } from '../../services/window/window.service';
import * as RouterActions from './router.actions';

@Injectable()
export class RouterEffects {
  go$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.go),
    tap(({ path, extras }) => this.router.navigate(path, extras))
  ), { dispatch: false });

  back$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.back),
    tap(() => this.location.back())
  ), { dispatch: false });

  forward$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.forward),
    tap(() => this.location.forward())
  ), { dispatch: false });

  goOutside$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.goOutside),
    tap(({ url }) => this.windowRef.nativeWindow.location.href = url)
  ), { dispatch: false });

  openNewTab$ = createEffect(() => this.actions$.pipe(
    ofType(RouterActions.openNewTab),
    tap(({ url = '' }) => this.windowRef.nativeWindow.open(url, '_blank'))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private windowRef: WindowRef
  ) { }
}
