import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../../material.module';
import { CollaboratorsAutocompleteComponent } from './collaborators-autocomplete.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MaterialModule],
  declarations: [CollaboratorsAutocompleteComponent],
  exports: [CollaboratorsAutocompleteComponent]
})
export class CollaboratorsAutocompleteModule {}
