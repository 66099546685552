import { ConcessionSlug } from '../../models/concession/concession-slug.enum';
import { ISOCountry } from '../../models/locality/iso-country.enum';

export class ConcessionConstants {

  public static ISOCountryByConcessionSlug: Map<ConcessionSlug | string, ISOCountry> = new Map([
    [ConcessionSlug.FRANCE, ISOCountry.FR],
    [ConcessionSlug.SPAIN, ISOCountry.ES],
    [ConcessionSlug.PORTUGAL, ISOCountry.PT],
    [ConcessionSlug.ITALY, ISOCountry.IT]
  ]);
}
