import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { catchError, switchMapTo, tap } from 'rxjs/operators';

import { NotificationReadEvent, Notifications } from '../../../models/notification/notification.interface';
import { NotificationService } from '../../../services/notifications/notification.service';
import { UrlService } from '../../../services/url/url.service';
import { WindowRef } from '../../../services/window/window.service';

@Component({
  selector: 'iad-notifications-sidenav',
  templateUrl: './notifications-sidenav.component.html',
  styleUrls: ['./notifications-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsSidenavComponent implements OnInit {
  notifications$: Observable<{} | Array<Notifications>>;
  refreshNotif$: BehaviorSubject<string>;
  errorMessage: string;

  constructor(
    private notificationService: NotificationService,
    private snackbar: MatSnackBar,
    private windowRef: WindowRef,
    private urlService: UrlService,
    private translate: TranslateService
  ) {
    this.refreshNotif$ = new BehaviorSubject<string>('');
  }

  /**
   * Initialise the different properties needed for the component,
   * then call the 2 differents notifications webservice,
   */
  ngOnInit(): void {
    this.startNotificationsPolling();
  }

  /**
   * When the link to see all the notifications is clicked, the sidebar is closed
   */
  public onClickSeeEverything(): void {
    // Redirect into ng1 notification center page. When notification center page is ready on ng2 make a router.navigate()
    this.windowRef.nativeWindow.location.href = `${this.urlService.get('wis')}/wis/headquarter/profile/notification`;
  }

  /**
   * Get the notifications every 5 minutes
   */
  public startNotificationsPolling(): void {
    this.notifications$ = combineLatest([timer(0, 300000), this.refreshNotif$]).pipe(
      switchMapTo(this.getNotifications())
    );
  }

  /**
   * Check if new notification is insert.
   *
   * @param Object notifications - The notifications list.
   */
  public checkIfNewNotificationIsInsert(notifications: Array<Notifications>): void {
    if (notifications) {
      const currentDateTime = new Date();
      currentDateTime.setMinutes(currentDateTime.getMinutes() - 1);
      for (const notificationItem of notifications) {
        if (notificationItem && notificationItem.list) {
          for (const notification of notificationItem.list) {
            if (new Date(notification.dateTime) > currentDateTime) {
              this.playAudio();
              return;
            }
          }
        }
      }
    }
  }

  /**
   * Call the webservice to retrieve notifications.
   */
  public getNotifications(): Observable<{} | Array<Notifications>> {
    return this.notificationService.getNotifications().pipe(
      tap((notifs: Array<Notifications>) => this.checkIfNewNotificationIsInsert(notifs)),
      catchError((err: string) => (this.errorMessage = this.translate.instant(err)))
    );
  }

  /**
   * Play the sound.
   */
  public playAudio(): void {
    const audio = new Audio('./assets/notification/ping.mp3');
    audio.play();
  }

  /**
   * Method used for delete or archive notification.
   *
   * @param number notificationId - The notification id.
   */

  public deleteNotification(notificationId: number): void {
    this.notificationService.deleteNotification([notificationId]).subscribe((data: any) => {
      this.refreshNotif$.next('');
      this.snackbar.open(data.message, null, { duration: 3000 });
    }, (err: string) => (this.errorMessage = this.translate.instant(err)));
  }

  /**
   * Method used for mark notification as read/notRead.
   *
   * @param Object notifRead - The notification data selected to mark as read.
   */
  public markAsReadNotification(notifRead: NotificationReadEvent): void {
    if (notifRead.typeElement !== 'block' || !notifRead.read) {
      this.notificationService.toggleReadNotification(notifRead.id).subscribe((data: any) => {
        this.refreshNotif$.next('');
        this.snackbar.open(data.message, null, { duration: 3000 });
      }, (err: string) => (this.errorMessage = this.translate.instant(err)));
    }
    if (notifRead.typeElement === 'block' && notifRead.url) {
      this.windowRef.nativeWindow.open(notifRead.url, '_blank');
    }
  }

  /**
   * Method used to prevent unnecessary rendering (better perf).
   *
   * @param number index - The index item.
   */
  trackById(index: number): number {
    return index;
  }
}
