import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../../material.module';
import { PipesModule } from '../../../pipes.module';
import { CountryAutocompleteComponent } from './country-autocomplete.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, PipesModule],
  declarations: [CountryAutocompleteComponent],
  exports: [CountryAutocompleteComponent]
})
export class CountryAutocompleteModule {}
