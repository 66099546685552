import { InjectionToken } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { GeocodeInputConfiguration, GeocodeLocality } from '../../../models/geocode/geocode.interface';


/**
 * Injection tokens to override Geocode strategies configuration.
 * Child app has to provide the desired token in order to override the default configuration.
 */
export const GEOCODE_CONFIG_V1 = new InjectionToken<GeocodeInputConfiguration>('GEOCODE_CONFIG_V1');
export const GEOCODE_CONFIG_V2 = new InjectionToken<GeocodeInputConfiguration>('GEOCODE_CONFIG_V2');

export class InputGeocodeL10n {
  public static region1 = _('geocode.input.group.region1') as string;
  public static region2 = _('geocode.input.group.region2') as string;
  public static region3 = _('geocode.input.group.region3') as string;
  public static region4 = _('geocode.input.group.region4') as string;
  public static localities = _('geocode.input.group.localities') as string;
  public static country = _('geocode.input.group.countries') as string;
  public static streets = _('geocode.input.group.streets') as string;
}

/**
 * Extra function to modify italia locality
 * @param locality - Geocode locality
 */
export function suffixItCb(locality: GeocodeLocality): string {
  return `${locality.iso2.replace('IT-', '')}`;
}
export const inputGeocodeTokens: Array<string> = [
  'locality',
  'postCode',
  'street',
  'region1',
  'region2',
  'region3',
  'region4'
];

export class InputGeocodeConstants {
  static SHORT_LIMIT = 5;
  static LONG_LIMIT = 10;

  static CONFIG_V1: GeocodeInputConfiguration = {
    fr: [
      {
        fieldFormatter: {
          pattern: '%locality% (%postCode%)'
        }
      }
    ],
    pt: [
      {
        fieldFormatter: {
          pattern: '%street%, %region3%, %region2%, (%postCode%)'
        }
      }
    ],
    es: [
      {
        fieldFormatter: {
          pattern: '%locality% (%postCode%)'
        }
      }
    ],
    de: [
      {
        fieldFormatter: {
          pattern: '%locality% (%postCode%)'
        }
      }
    ],
    it: [
      {
        fieldFormatter: {
          pattern: '%locality% (%postCode%)'
        }
      }
    ],
    gb: [
      {
        fieldFormatter: {
          pattern: '%locality% (%postCode%)'
        }
      }
    ],
    fl: [
      {
        fieldFormatter: {
          pattern: '%locality% (%postCode%)'
        }
      }
    ]
  };

  static CONFIG_V2: GeocodeInputConfiguration = {
    fr: [
      {
        service: 'regions2',
        searchField: 'region2',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region2
      },
      {
        service: 'localities',
        searchField: 'locality',
        limit: InputGeocodeConstants.LONG_LIMIT,
        fieldFormatter: {
          suffix: '(%postCode%)'
        },
        groupName: InputGeocodeL10n.localities
      }
    ],
    pt: [
      {
        service: 'regions2',
        searchField: 'region2',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region2
      },
      {
        service: 'regions3',
        searchField: 'region3',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region3
      }
    ],
    es: [
      {
        service: 'regions2',
        searchField: 'region2',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region2
      },
      {
        service: 'regions3',
        searchField: 'region3',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region3
      },
      {
        service: 'regions4',
        searchField: 'region4',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region4
      },
      {
        service: 'localities',
        searchField: 'locality',
        limit: InputGeocodeConstants.LONG_LIMIT,
        fieldFormatter: {
          suffix: '(%postCode%)'
        },
        groupName: InputGeocodeL10n.localities
      }
    ],
    it: [
      {
        service: 'regions1',
        searchField: 'region1',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region1
      },
      {
        service: 'regions2',
        searchField: 'region2',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region2
      },
      {
        service: 'regions3',
        searchField: 'region3',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region3
      },
      {
        service: 'localities',
        searchField: 'locality',
        limit: InputGeocodeConstants.LONG_LIMIT,
        fieldFormatter: {
          suffix: '(%postCode%)'
        },
        groupName: InputGeocodeL10n.localities
      }
    ],
    de: [
      {
        service: 'regions1',
        searchField: 'region1',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region1
      },
      {
        service: 'regions2',
        searchField: 'region2',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region2
      },
      {
        service: 'regions3',
        searchField: 'region3',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region3
      },
      {
        service: 'localities',
        searchField: 'locality',
        limit: InputGeocodeConstants.LONG_LIMIT,
        fieldFormatter: {
          suffix: '(%postCode%)'
        },
        groupName: InputGeocodeL10n.localities
      }
    ],
    gb: [
      {
        service: 'regions1',
        searchField: 'region1',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region1
      },
      {
        service: 'regions2',
        searchField: 'region2',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region2
      },
      {
        service: 'regions3',
        searchField: 'region3',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region3
      },
      {
        service: 'localities',
        searchField: 'locality',
        limit: InputGeocodeConstants.LONG_LIMIT,
        fieldFormatter: {
          suffix: '(%postCode%)'
        },
        groupName: InputGeocodeL10n.localities
      }
    ],
    fl: [
      {
        service: 'regions1',
        searchField: 'region1',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region1
      },
      {
        service: 'regions2',
        searchField: 'region2',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region2
      },
      {
        service: 'regions3',
        searchField: 'region3',
        limit: InputGeocodeConstants.SHORT_LIMIT,
        groupName: InputGeocodeL10n.region3
      },
      {
        service: 'localities',
        searchField: 'locality',
        limit: InputGeocodeConstants.LONG_LIMIT,
        fieldFormatter: {
          suffix: '(%postCode%)'
        },
        groupName: InputGeocodeL10n.localities
      }
    ]
  };
}
