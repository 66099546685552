import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PDFSource } from 'pdfjs-dist';

import { FileMimeType } from '../../../models';

@Component({
  selector: 'iad-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentViewerComponent implements OnChanges {
  @Input() mimeType: FileMimeType;
  @Input() pdfSrc: PDFSource;
  @Input() imgUrl: SafeUrl | string;
  @Input() imgBase64: string;

  FileMimeType = FileMimeType;

  constructor(private sanitizer: DomSanitizer) { }

  /**
   * Life cycle onChanges
   * @param changes - changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imgBase64 && changes.imgBase64.currentValue) {
      this.imgUrl = this.sanitizer.bypassSecurityTrustUrl(changes.imgBase64.currentValue);
    }
  }
}
