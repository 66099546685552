import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Concession } from '../../models/concession/concession.interface';
import { ConcessionSlug } from '../../models/concession/concession-slug.enum';
import { ISOCountry } from '../../models/locality/iso-country.enum';
import { ConcessionConstants } from '../../constants/concession/concession.constants';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ConcessionService {
  constructor(private apiService: ApiService) {}

  /**
   * Retrieve all concessions.
   *
   * @returns Observable -  A list of concessions
   */
  getConcessions(): Observable<Array<Concession>> {
    return this.apiService.get<Array<Concession>>('wis', '/directory/concessions');
  }

  /**
   * Convert concession slug to ISO country.
   *
   * @param concessionSlug - The concession slug in the `concessionSlug` enum,
   * `concessionSlug.FRANCE` or `france` as string for example
   * @returns The ISO country of the concession slug
   */
  getISOCountryByConcessionSlug(concessionSlug: ConcessionSlug | string): ISOCountry {
    return ConcessionConstants.ISOCountryByConcessionSlug.get(concessionSlug);
  }
}
