import { Pipe, PipeTransform } from '@angular/core';

import { Locale } from '../../models/locality';

@Pipe({ name: 'ordinal' })
export class OrdinalPipe implements PipeTransform {
  ordinalSuffixes: Map<string, () => string>;

  constructor() {}

  /**
   * Main method.
   *
   * @param value - The default value
   * @param locale - The Locale ID, 'fr_FR' for example
   * @param isFemale - True if the ordinal number must be female. Default false.
   * @returns Ordinary numbers
   */
  transform(value: number, locale: string, isFemale: boolean = false): string {
    this.ordinalSuffixes = new Map([
      [Locale.FR_FR, () => this.getFrFRSuffix(value, isFemale)],
      [Locale.EN_GB, () => this.getEnGBSuffix(value)],
      [Locale.ES_ES, () => this.getEsESSuffix(isFemale)],
      [Locale.PT_PT, () => this.getPtPTSuffix(isFemale)],
      [Locale.IT_IT, () => this.getItITSuffix(isFemale)],
      [Locale.DE_DE, () => this.getDeDESuffix()],
    ]);

    return `${value}${this.convertToSup(this.ordinalSuffixes.get(locale)())}`;
}

  /**
   * Get french France ordinal.
   *
   * @param number - Numeral
   * @param isFemale - True if the ordinal number must be isFemale. Default false.
   * @returns French France ordinal
   */
  getFrFRSuffix(number: number, isFemale: boolean): string {
    return number === 1 ? isFemale ? 're' : 'er' : `e`;
  }

  /**
   * Get english United Kingdom ordinal.
   *
   * @param number - Numeral
   * @returns English United Kingdom ordinal
   */
  getEnGBSuffix(number: number): string {
    const ones = number % 10;
    const suffix = (~~ (number % 100 / 10) === 1) ? 'th' :
      (ones === 1) ? 'st' :
        (ones === 2) ? 'nd' :
          (ones === 3) ? 'rd' : 'th';
    return suffix;
  }

  /**
   * Get spanish Spain ordinal.
   *
   * @param number - Numeral
   * @returns Spanish Spain ordinal
   */
  getEsESSuffix(isFemale: boolean): string {
    return isFemale ? `a` : `o`;
  }

  /**
   * Get portuguese Portugal ordinal.
   *
   * @param number - Numeral
   * @param isFemale - True if the ordinal number must be isFemale. Default false.
   * @returns Portuguese Portugal ordinal
   */
  getPtPTSuffix(isFemale: boolean): string {
    return isFemale ? `a` : `o`;
  }

  /**
   * Get italian Italy ordinal.
   *
   * @param number - Numeral
   * @param isFemale - True if the ordinal number must be isFemale. Default false.
   * @returns Italian Italy ordinal
   */
  getItITSuffix(isFemale: boolean): string {
    return isFemale ? `a` : `o`;
  }

  /**
   * Get german (Germany) ordinal.
   *
   * @returns German (Germany) ordinal.
   */
  getDeDESuffix(): string {
    return '.';
  }

  /**
   * Add <sup></sup> outside the ordinal suffix.
   *
   * @param ordinalSuffix - string
   * @returns ordinalSuffix with <sup> outside
   */
  convertToSup(ordinalSuffix: string): string {
    return `<sup>${ordinalSuffix}</sup>`;
  }
}
