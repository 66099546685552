import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { LetContext } from '../../models/let-context/let-context.interface';

@Directive({
  selector: '[iadLet]'
})
export class LetDirective<T> {
  @Input()
  set iadLet(value: T) {
    this.context.iadLet = value;
  }
  private context: LetContext<T>;
  constructor(vcRef: ViewContainerRef, templateRef: TemplateRef<LetContext<T>>) {
    this.context = { iadLet: null };
    vcRef.createEmbeddedView(templateRef, this.context);
  }
}
