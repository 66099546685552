import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DirectivesModule } from './../../directives.module';
import { MaterialModule } from './../../material.module';
import { BottomSheetListComponent } from './bottom-sheet-list/bottom-sheet-list.component';
import { BottomSheetOverlayComponent } from './bottom-sheet-overlay/bottom-sheet-overlay.component';

const COMPONENTS = [BottomSheetListComponent, BottomSheetOverlayComponent];

@NgModule({
  imports: [CommonModule, MaterialModule, DirectivesModule],
  declarations: [COMPONENTS],
  entryComponents: COMPONENTS
})
export class BottomSheetsModule {}
