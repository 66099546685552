import { Directive, Inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { CORE_CONFIG, CoreConfig } from '../../models/core-config/core-config.interface';

/**
 * Insert a provided template into the DOM if the provided feature's name is enabled.
 * Feature's activation is set on environment file (featureFlags property).
 *
 * <div *iadFeatureFlip="'myFeatureOfTheDead'">
 *    <p>myFeatureOfTheDead is enabled !</p>
 * </div>
 *
 * // environment.xxxx.ts
 *
 * {
 *  //...
 *  featureFlags: {
 *     myFeatureOfTheDead: true
 *  }
 * }
 *
 */
@Directive({
  selector: '[iadFeatureFlip]'
})
export class FeatureFlipDirective implements OnInit {
  @Input() iadFeatureFlip: string;

  constructor(
    @Inject(CORE_CONFIG) private config: CoreConfig,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  /**
   * Life cycle OnInit
   */
  ngOnInit(): void {
    const featureEnabled = this.config.featureFlags[this.iadFeatureFlip];

    featureEnabled && this.viewContainer.createEmbeddedView(this.templateRef);
  }

}
