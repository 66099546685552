import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../../material.module';
import { ChipListComponent } from './chip-list.component';
import { ChipCheckDirective } from './chip-check.directive';

@NgModule({
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, TranslateModule],
  declarations: [ChipListComponent, ChipCheckDirective],
  exports: [ChipListComponent, ChipCheckDirective]
})
export class ChipListModule {}
