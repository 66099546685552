import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Constellation, PaginatedResult } from '../../models';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class GenealogyService {
  constructor(private apiService: ApiService) {}

  /**
   *
   * Fetch all constellations.
   *
   * @param filters - the query parameters.
   * @returns Observable - Observable of constellations.
   */
  getConstellations(
    filters?: {
      concession_id?: number;
      page?: number;
      number_per_page?: number;
  }): Observable<PaginatedResult<Constellation>> {
    const params = filters;

    return this.apiService.get<PaginatedResult<Constellation>>('wis', '/directory/genealogy/constellations', { params });
  }
}
