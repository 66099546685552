import { createAction, props } from '@ngrx/store';

import { ConcessionSlug, Locale, ReferencesSlugs } from '../../models';
import { References } from '../../models/references/references.interface';

export const getReferences = createAction(
  '[REFERENCES] GET_REFERENCES',
  props<{
    referencesSlugs: Array<ReferencesSlugs | string>;
    concessionSlug: ConcessionSlug;
    locale: Locale;
    filters?: {
      direction?: string;
      sort?: boolean;
    };
  }>()
);
export const getReferencesSuccess = createAction('[REFERENCES] GET_REFERENCES_SUCCESS', props<{ references: References }>());
export const getReferencesFailure = createAction('[REFERENCES] GET_REFERENCES_FAILURE', props<{ error: string }>());

export const getReferencesV2 = createAction(
  '[REFERENCES] GET_REFERENCE_V2',
  props<{
    referenceName: string;
    concessionSlug: ConcessionSlug;
  }>()
);
export const getReferencesV2Success = createAction('[REFERENCES] GET_REFERENCE_V2_SUCCESS', props<{ references: any }>());
export const getReferencesV2Failure = createAction('[REFERENCES] GET_REFERENCE_V2_FAILURE', props<{ error: string }>());
