import { NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { AuthInterceptorProvider } from './interceptors/auth.interceptor';
import { BottomSheetService } from './services/bottom-sheet/bottom-sheet.service';
import { CoreState } from './store';
import { AuthEffects } from './store/auth/auth.effects';
import { BottomSheetEffects } from './store/bottom-sheet/bottom-sheet.effects';
import { DialogEffects } from './store/dialog/dialog.effects';
import { FileEffects } from './store/file/file.effects';
import { MenuEffects } from './store/menu/menu.effects';
import { reducer as menuReducer } from './store/menu/menu.reducer';
import { reducer as notificationsReducer } from './store/notifications/notifications.reducer';
import { ReferencesEffects } from './store/references/references.effects';
import { reducer as referencesReducer } from './store/references/references.reducer';
import { CustomSerializer } from './store/router';
import { RouterEffects } from './store/router/router.effects';
import { SnackbarEffects } from './store/snackbar/snackbar.effects';
import { UserEffects } from './store/user/user.effects';
import { reducer as userReducer } from './store/user/user.reducer';

export const coreReducer: ActionReducerMap<CoreState> = {
  user: userReducer,
  router: routerReducer,
  references: referencesReducer,
  notifications: notificationsReducer,
  menu: menuReducer
};

@NgModule({
  imports: [
    StoreModule.forFeature('core', coreReducer),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
      serializer: CustomSerializer
    }),
    EffectsModule.forFeature([
      UserEffects,
      SnackbarEffects,
      DialogEffects,
      RouterEffects,
      ReferencesEffects,
      AuthEffects,
      FileEffects,
      MenuEffects,
      BottomSheetEffects
    ])
  ],
  providers: [AuthInterceptorProvider, BottomSheetService]
})
export class CoreStateModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreStateModule) {
    if (parentModule) {
      throw new Error('CoreStateModule is already loaded. Import it in the AppModule only');
    }
  }
}
