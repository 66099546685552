export class MockAgentService {
  getAgents = jasmine.createSpy('getAgents');
  getAgentsHistory = jasmine.createSpy('getAgentsHistory');
  isHeadQuarter = jasmine.createSpy('isHeadQuarter');
  getAgentsByStatus = jasmine.createSpy('getAgentsByStatus');
  archiveAgent = jasmine.createSpy('archiveAgent');
  blacklistAgent = jasmine.createSpy('blacklistAgent');
  changeAgentAdministrator = jasmine.createSpy('changeAgentAdministrator');
  addComment = jasmine.createSpy('addComment');
  getAgentComments = jasmine.createSpy('getAgentComments');
}
