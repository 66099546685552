export enum CollaboratorQualifications {
  AGENT = 'reference.qualification.agent',
  IT_SERVICE = 'reference.qualification.it_service',
  ADMINISTRATOR = 'reference.qualification.administrator',
  MANDATE_SERVICE = 'reference.qualification.mandate_service',
  MANDATE_SERVICE_MANAGER = 'reference.qualification.mandate_service_manager',
  DIRECTION = 'reference.qualification.direction',
  EXECUTIVE_ASSISTANT = 'reference.qualification.executive_assistant',
  NETWORK_MANAGER = 'reference.qualification.network_manager',
  COMMUNICATION_SERVICE = 'reference.qualification.communication_service',
  COMMUNICATION_SERVICE_MANAGER = 'reference.qualification.communication_service_manager',
  JURIST = 'reference.qualification.jurist',
  HUMAN_RESOURCES = 'reference.qualification.human_ressource',
  HUMAN_RESOURCES_MANAGER = 'reference.qualification.human_ressource_manager',
  BUSINESS_ADMIN_SERVICE = 'reference.qualification.business_admin_service',
  BUSINESS_ADMIN_SERVICE_MANAGER = 'reference.qualification.business_admin_service_manager',
  COMMERCIAL_SERVICE = 'reference.qualification.commercial_service',
  MARKETING_SERVICE = 'reference.qualification.marketing_service',
  PRESCRIBER = 'reference.qualification.prescriber',
}

export const ALL_QUALIFICATIONS: Array<string> = Object.keys(CollaboratorQualifications)
  .map((key: string) => CollaboratorQualifications[key]);
