import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from '@angular/router';
import { WindowRef } from "../../services";

declare global {
    interface Window {
      DD_RUM: any;
    }
  }

@Injectable()
export class DataDogHelper {

  constructor(private router: Router, private window: WindowRef) {}

  public setDataDogUser (): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const user = JSON.parse(sessionStorage.getItem('user'));

        this.window.nativeWindow.DD_RUM.onReady(function() {
            window.DD_RUM.setUser({
              id: user.idPeople,
              name: user.firstName + ' ' + user.lastName,
              concession: user.concession.slug,
              qualification: user.qualificationLevel,
              tokenIat: user.iat,
              tokenExp: user.exp,
            });
          })

      }
    });
  }
}
