import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { get } from 'lodash';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { concatMap, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';

import {
  TimepickerClock,
  TimepickerClockEvent,
  TimepickerClockName,
  TimepickerClockPoint,
  TimepickerClockState,
  TimepickerClockType,
} from './../../../models/timepicker';
import { TimeService } from './../../../services/time/time.service';
import { TimepickerDialogConstants } from './timepicker-dialog.constants';


@Component({
  selector: 'iad-timepicker-dialog',
  templateUrl: './timepicker-dialog.component.html',
  styleUrls: ['./timepicker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DecimalPipe]
})
export class TimepickerDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  /** The clock DOM element reference used to retrieve user mouse position. */
  @ViewChild('clockElement', { static: false }) clockElement: ElementRef<HTMLElement>;

  /** The `TimepickerClockType` alias used in template. */
  readonly clockType: typeof TimepickerClockType;

  /** The collection of clock objects. */
  clocks: Array<TimepickerClock>;

  /** The state subject as observable. */
  stateObservable$: Observable<TimepickerClockState>;

  /** Subject that emits time values. */
  private state$: BehaviorSubject<TimepickerClockState>;

  /** Observable that emits `mousedown` and `touchstart` events. */
  private startEvent$: Observable<MouseEvent | TouchEvent>;

  /** Observable that emits `mousemove` and `touchmove` events. */
  private moveEvent$: Observable<MouseEvent | TouchEvent>;

  /** Observable that emits `mouseup` and `touchend` events. */
  private endEvent$: Observable<MouseEvent | TouchEvent>;

  /** Subject that emits when the component has been destroyed. */
  private unsubscribe$: Subject<void>;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private decimalPipe: DecimalPipe,
    private sanitizer: DomSanitizer,
    private timeService: TimeService
  ) {
    this.clockType = TimepickerClockType;
    this.unsubscribe$ = new Subject();
  }

  /**
   * On component initialization, init the state and the clocks.
   */
  ngOnInit() {
    this.initState();
    this.initClocks();
  }

  /**
   * After the view initialization, init all mouse events based on the clock HTML element.
   */
  ngAfterViewInit(): void {
    this.initUIEvents();
    this.observeUIEvents();
  }

  /**
   * On component destruction, manage subscriptions.
   */
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Initializes the state according to the `MAT_DIALOG_DATA`.
   */
  private initState(): void {
    const initialState: TimepickerClockState = this.timeService.isTwentyFourHourTime(this.data)
      ? this.getStateValueFromString(this.data)
      : TimepickerDialogConstants.INITIAL_CLOCK_STATE;
    this.state$ = new BehaviorSubject(initialState);
    this.stateObservable$ = this.state$.asObservable();
  }

  /**
   * Initializes the clocks and their clock points.
   */
  private initClocks(): void {
    this.clocks = [
      {
        className: TimepickerClockName.HOURS,
        points: this.getHoursClockPoints(),
        type: this.clockType.HOURS,
      },
      {
        className: TimepickerClockName.AFTERNOON_HOURS,
        points: this.getHoursClockPoints(true),
        type: this.clockType.HOURS
      },
      {
        className: TimepickerClockName.MINUTES,
        points: this.getMinutesClockPoints(),
        type: this.clockType.MINUTES
      }
    ];
  }

  /**
   * Initializes mouse and touch UI events.
   * We need to pass `{ passive: false }` as `EventListenerOptions` to
   * call `preventDefault()` on `touchstart` and `touchmove` events.
   */
  private initUIEvents(): void {
    this.startEvent$ = merge(
      fromEvent<MouseEvent>(this.clockElement.nativeElement, 'mousedown'),
      fromEvent<TouchEvent>(this.clockElement.nativeElement, 'touchstart', { passive: false })
    );

    this.moveEvent$ = merge(
      fromEvent<MouseEvent>(window, 'mousemove'),
      fromEvent<TouchEvent>(window, 'touchmove', { passive: false })
    );

    this.endEvent$ = merge(
      fromEvent<MouseEvent>(window, 'mouseup'),
      fromEvent<TouchEvent>(window, 'touchend')
    );
  }

  /**
   * Observe UI events by subscribing to a composition of theses events
   * to update the state on user interactions.
   */
  private observeUIEvents(): void {
    this.startEvent$.pipe(
      // This logic is used to listen a drag-like event on the clock.
      // When the start event emits, emit this event and all following move events
      // until the end event emits.
      concatMap(startEvent => this.moveEvent$.pipe(
        startWith(startEvent),
        takeUntil(this.endEvent$.pipe(
          tap(() => this.setMinutesView())
        ))
      )),
      tap(event => event.preventDefault()),
      // Always ignore the touch list because we only need the first touch
      // to retrieve event coordinates.
      map(event => event instanceof TouchEvent ? event.changedTouches[0] : event),
      map(event => this.getClockEvent(event)),
      filter(clockEvent => !!clockEvent),
      map(clockEvent => ({ [clockEvent.clock.type]: this.getClockEventValue(clockEvent) })),
      takeUntil(this.unsubscribe$)
    ).subscribe((value: Partial<TimepickerClockState>) => this.updateState(value));
  }

  /**
   * Returns a timepicker state according to a given time string.
   * @param time - The time string formatted like '12:00'
   * @returns A timepicker state
   */
  private getStateValueFromString(time: string): TimepickerClockState {
    const [hours, minutes] = time.split(':');

    return !!hours && !!minutes
      ? {
          ...TimepickerDialogConstants.INITIAL_CLOCK_STATE,
          hours,
          minutes,
          time
        }
      : null;
  }

  /**
   * Sets a new state according to a given partial state value.
   * The property `time` is ignored and setted according to `minutes` and `hours` changes.
   * @param value - The partial state value
   */
  private updateState(value: Omit<Partial<TimepickerClockState>, 'time'>): void {
    if (!!value && (!!value.clock || !!value.hours || !!value.minutes)) {
      const currentState: TimepickerClockState = this.state$.getValue();
      const time: string = !!value.hours || !!value.minutes
        ? this.timeService.getTwentyFourHourTime(
            value.hours || currentState.hours,
            value.minutes || currentState.minutes
          )
        : currentState.time;

      this.state$.next({ ...currentState, ...value, time });
    }
  }

  /**
   * Returns a specific count of clock points.
   * @param count - The clock point count to generate
   * @param mapFn - A facultative mapping function used to custom clock values
   * @returns An array of clock points
   */
  private getClockPoints(count: number, mapFn?: (value: number) => number): Array<TimepickerClockPoint> {
    const angleInterval: number = (TimepickerDialogConstants.FULL_ANGLE / count);

    return Array.from(Array(count), (_, value) => {
      return {
        value: this.decimalPipe.transform(mapFn ? mapFn(value) : value, '2.0'),
        visible: this.isClockPointVisible(value, count),
        handTransformStyle: this.getTransformStyle(value, angleInterval),
        cursorTransformStyle: this.getTransformStyle(value, angleInterval, true)
      };
    });
  }

  /**
   * Returns hours clock points.
   * @param afternoon - Whether the clock has to shown afternoon values
   * @returns An array of hours clock points
   */
  private getHoursClockPoints(afternoon: boolean = false): Array<TimepickerClockPoint> {
    const mapFn = afternoon
      ? (value: number) => value ? value + TimepickerDialogConstants.HOURS_PER_CLOCK : value
      : (value: number) => value || TimepickerDialogConstants.HOURS_PER_CLOCK;

    return this.getClockPoints(TimepickerDialogConstants.HOURS_PER_CLOCK, mapFn);
  }

  /**
   * Returns minutes clock points.
   * @returns An array of minutes clock points
   */
  private getMinutesClockPoints(): Array<TimepickerClockPoint> {
    return this.getClockPoints(TimepickerDialogConstants.MINUTES_PER_CLOCK);
  }

  /**
   * Checks if a clock point has to be visible according to the total count
   * and the number of values ​​that can be displayed on the clock.
   * @param index - The clock point index
   * @param totalCount - The total points count
   * @returns True if the clock point has to be visible, otherwise false
   */
  private isClockPointVisible(index: number, totalCount: number): boolean {
    return !(index % (totalCount / TimepickerDialogConstants.CLOCK_VALUES_COUNT));
  }

  /**
   * Returns a CSS `transform` style value used to transform clock hands.
   * @param angleIndex - The angle index
   * @param angleInterval - The angle interval in degrees
   * @param isCursor - Whether the style will be used for clock hand cursor
   * @returns A safe style value
   */
  private getTransformStyle(angleIndex: number, angleInterval: number, isCursor: boolean = false): SafeStyle {
    const angle: number = angleIndex * angleInterval;

    return this.sanitizer.bypassSecurityTrustStyle(
      isCursor ? `rotate(-${angle}deg)` : `rotate(${angle}deg) translate3d(-50%, 0, 0)`
    );
  }

  /**
   * Returns a clock event according to a given mouse event.
   * @param event - The mouse envent
   * @returns A clock event or `null` if the clock targeted by the event is undefined
   */
  private getClockEvent(event: MouseEvent | Touch): TimepickerClockEvent | null {
    const target: HTMLElement = document.elementFromPoint(event.clientX, event.clientY) as HTMLElement;
    const clockIndex: number = +target.dataset.index;

    return Number.isNaN(clockIndex) ? null : { clock: this.clocks[clockIndex], ...this.getTrigonometricCoordinates(event) };
  }

  /**
   * Returns the clock DOM rectangle used to retrieve relative event coordinates.
   * @returns The `DOMRect` object.
   */
  private getClockDOMRect(): DOMRect {
    return this.clockElement.nativeElement.getBoundingClientRect() as DOMRect;
  }

  /**
   * Returns an object containing cartesian x and y coordinates.
   * Theses coordinates are numeric expressions included in the interval [-1, 1].
   * @param event - The mouse or touch event
   * @returns A trigonometric coordinates object
   */
  private getTrigonometricCoordinates(event: MouseEvent | Touch): { x: number; y: number } {
    const clockRect: DOMRect = this.getClockDOMRect();
    const radius: number = clockRect.width / 2;
    const coordinates: { x: number; y: number } = { x: null, y: null };

    return Object.keys(coordinates).reduce((coordinate, axis) => {
      // Multiple assignements according to the axis (x or y) :
      // - The relative event position on an axis
      // - The multiplicator, a numeric expression (-1 or 1) used to invert y axis value because browser Y axis is inverted
      const [relativePosition, multiplicator] = axis === 'x' ? [event.clientX - clockRect.x, 1] : [event.clientY - clockRect.y, -1];
      // Calculate a value included in the interval [-1, 1] where the radius of the clock equal 1
      const trigonometricValue: number = (Math.max(-radius, Math.min(relativePosition - radius, radius)) / radius);

      return { ...coordinate, [axis]: trigonometricValue * multiplicator };
    }, coordinates);
  }

  /**
   * Returns the clock event point value.
   * If no value retrieved, returns by default the first clock point value.
   * @param clockEvent - The clock event
   * @returns The value of the clock event point
   */
  private getClockEventValue(clockEvent: TimepickerClockEvent): string {
    const index: number = this.getClockPointIndex(clockEvent);

    return get(
      clockEvent.clock.points,
      [index, 'value'],
      clockEvent.clock.points[0].value
    );
  }

  /**
   * Returns a clock event point index.
   * To calculate the right index, we need to retrieve the angle of the clock event
   * relative to the y axis. Afterwise, this angle is divided by the angle interval (an angle
   * in degrees corresponding to the space allowed for each point on the clock) to
   * retrieve the right index.
   * @param clockEvent - The clock event
   * @returns The clock point index
   */
  private getClockPointIndex(clockEvent: TimepickerClockEvent): number {
    const angle: number = this.getClockPointAngle(clockEvent);
    const angleInterval: number = (TimepickerDialogConstants.FULL_ANGLE / clockEvent.clock.points.length);

    return Math.round(angle / angleInterval);
  }

  /**
   * Returns a degree angle from the Y clock axis.
   * For example, a perfect click on noon should return a zero degree angle.
   * @param clockEvent - The clock click event
   * @returns A degree angle
   */
  private getClockPointAngle(clockEvent: TimepickerClockEvent): number {
    const angleInRadians: number = Math.atan2(clockEvent.x, clockEvent.y);
    const angleInDegrees: number = this.radiansToDegrees(angleInRadians);

    return (angleInDegrees + TimepickerDialogConstants.FULL_ANGLE) % TimepickerDialogConstants.FULL_ANGLE;
  }

  /**
   * Converts radians to degrees.
   * @param radians - The radian value
   * @returns An angle in degrees
   */
  private radiansToDegrees(radians: number): number {
    return (radians * TimepickerDialogConstants.HALF_ANGLE) / Math.PI;
  }

  /**
   * Set the hours view.
   */
  setHoursView() {
    if (this.state$.getValue().clock !== this.clockType.HOURS) {
      this.updateState({ clock: this.clockType.HOURS });
    }
  }

  /**
   * Set the minutes view.
   */
  setMinutesView() {
    if (this.state$.getValue().clock !== this.clockType.MINUTES) {
      this.updateState({ clock: this.clockType.MINUTES });
    }
  }
}
