import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../../material.module';
import { PdfViewerNavComponent } from './pdf-viewer-nav.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild(),
    ReactiveFormsModule
  ],
  declarations: [PdfViewerNavComponent],
  exports: [PdfViewerNavComponent]
})
export class PdfViewerNavModule { }
