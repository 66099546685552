import { Action, createReducer, on } from '@ngrx/store';

import * as NotificationActions from './notifications.actions';

export interface NotificationsState {
  opened: boolean;
}

const initialState: NotificationsState = {
  opened: false
};

const notificationReducer = createReducer(
  initialState,
  on(NotificationActions.toggleNotification, (state, { opened }) => ({ ...state, opened }))
);

/**
 * Notification reducer
 * @param state - notfication state
 * @param action - notfication action
 */
export function reducer(state: NotificationsState | undefined, action: Action) {
  return notificationReducer(state, action);
}


