import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import {
  Notification,
  NotificationLabel,
  NotificationReadEvent
} from '../../../../models/notification/notification.interface';

@Component({
  selector: 'iad-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent {
  NotificationLabel = NotificationLabel;
  @Input() type: NotificationLabel;
  @Input() notifications: Array<Notification>;
  @Output() notifRead: EventEmitter<NotificationReadEvent>;
  @Output() notifDeleted: EventEmitter<number>;
  notifItemConfig: { [label: string]: { notifClass: string; iconFontSet: string; iconFont: string } };

  constructor() {
    this.notifRead = new EventEmitter<NotificationReadEvent>();
    this.notifDeleted = new EventEmitter<number>();
    this.notifItemConfig = {
      'reference.notification_type.BLOCKING': {
        notifClass: 'iad-notification-message-blocking',
        iconFontSet: 'fas',
        iconFont: 'fa-exclamation-circle'
      },
      'reference.notification_type.IMPORTANT': {
        notifClass: 'iad-notification-message-important',
        iconFontSet: 'fas',
        iconFont: 'fa-exclamation-triangle'
      },
      'reference.notification_type.INFORMATIVE': {
        notifClass: 'iad-notification-message-informative',
        iconFontSet: 'if',
        iconFont: 'if-information'
      }
    };
  }

  /**
   * Emit when a notif is read
   * @param notifRead - Notification read event.
   */
  readNotif(notifRead: NotificationReadEvent): void {
    this.notifRead.emit(notifRead);
  }

  /**
   * Delete a notification
   * @param notifId - Notification id
   */
  deleteNotif(notifId: number): void {
    this.notifDeleted.emit(notifId);
  }

  /**
   * Track item by id
   * @param index - item index
   */
  trackById(index: number): number {
    return index;
  }
}
