import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../../material.module';
import { PipesModule } from '../../../pipes.module';
import { UploaderFileComponent } from './uploader-file/uploader-file.component';
import { UploaderComponent } from './uploader.component';

@NgModule({
  imports: [CommonModule, MaterialModule, PipesModule, TranslateModule.forChild()],
  declarations: [UploaderComponent, UploaderFileComponent],
  exports: [UploaderComponent]
})
export class UploaderModule {}
