import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { Concession } from '../../models';
import { ApiService } from '../api/api.service';
import { Menu } from './../../models/menu/menu.interface';

@Injectable()
export class NavigationService {
  constructor(private apiService: ApiService) {}

  /**
   * Get menu from cdi
   * @param concession - concession slug
   */
  getMenu(concession: Concession): Observable<Array<Menu>> {
    return this.apiService.get('d8', `/${concession.slug}/iad_menu`).pipe(
      retry(1),
      map((navItems: object) => this.formatNavItems(navItems))
    );
  }

  /**
   * Format navItem key value object in array.
   * @param navItems - NavItems object (key value)
   */
  private formatNavItems(navItems: object): Array<Menu> {
    const menuFields = ['label', 'url', 'class'];
    // transform object keys in array to iterate over
    const navItemsArr: Array<Menu> = [];
    Object.keys(navItems).forEach((key: string) => {
      const navItem = navItems[key];
      if (navItems[key][key]) {
        navItem.subMenu = this.formatNavItems(navItems[key][key]);
        delete navItem[key][key];
      } else {
        navItem.subMenu = [];
      }
      if (!menuFields.some((fields: string) => fields === key)) {
        navItem.id = key;
      }
      navItemsArr.push(navItem);
    });
    return navItemsArr;
  }
}
