import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  DoCheck,
  ElementRef,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Type,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroupDirective,
  NgControl,
  NgForm,
  NG_VALIDATORS,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  skipUntil,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { Concession } from '../../../models/concession/concession.interface';
import { GeocodeVersion } from '../../../models/geocode/geocode.enum';
import {
  GeocodeCountry,
  GeocodeGroup,
  GeocodeInputLocality,
  GeocodeLocality
} from '../../../models/geocode/geocode.interface';
import { GeocodeService } from '../../../services/geocode/geocode.service';
import { LocaleService } from '../../../services/locale/locale.service';
import { GeocodeStrategy } from './strategy/geocode-strategy.interface';
import { GeocodeV1Strategy } from './strategy/geocode-v1-strategy.class';
import { GeocodeV2Strategy } from './strategy/geocode-v2-strategy.class';

const COMPONENT_SELECTOR = 'iad-input-geocode';

/**
 * Boilerplate for applying mixins to InputGeocodeComponent.
 */
export class InputGeocodeComponentBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl
  ) {}
}

/**
 * We are using low-level mixinErrorState to handle errorState properly.
 */
export const _InputGeocodeComponentBase = mixinErrorState(InputGeocodeComponentBase);

/**
 * The input geocode component displays an autocomplete containing localities results.
 * There is 2 strategies (strategy pattern). The strategy hold a specific configuration for each country.
 * The GeocodeV1 strategy is used when a user type a numeric value.
 * The GeocodeV2 strategy is used when a user type an alpha value.
 *
 * You can override the default geocode configuration by providing into your module or your component the
 * following injection tokens (providers section) :
 * GEOCODE_CONFIG_V1
 * GEOCODE_CONFIG_V2
 *
 * Ex: (check the factory functions in input-geocode-module.ts) :
 * { provide: GEOCODE_CONFIG_V1, useFactory: geocodeConfigV1Fn },
 * { provide: GEOCODE_CONFIG_V2, useValue: geocodeConfigV2Fn }
 */
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './input-geocode.component.html',
  styleUrls: ['./input-geocode.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MatFormFieldControl, useExisting: InputGeocodeComponent },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputGeocodeComponent),
      multi: true
    }
  ]
})
export class InputGeocodeComponent extends _InputGeocodeComponentBase
  implements OnInit, DoCheck, OnDestroy, MatFormFieldControl<GeocodeInputLocality>, ControlValueAccessor, Validator {
  static nextId = 0;
  GeocodeVersion = GeocodeVersion;

  @Input() concession: Concession;
  @Input() forceV1: boolean;
  @Input() excludedIds: Array<number>;
  @ViewChild('geocodeInput', { static: true })
  geocodeInput: ElementRef;

  stateChanges: Subject<void>;
  inputValue$: Subject<string>;
  geocodeLocalities$: Observable<Array<GeocodeGroup | GeocodeLocality>>;
  countriesAndConcessionsFetched$: BehaviorSubject<boolean>;
  valueFromParent$: Subject<GeocodeInputLocality>;
  unsubscribe$: Subject<void>;
  strategy: GeocodeStrategy;
  version: GeocodeVersion;
  localitySelected: GeocodeInputLocality;
  countries: Array<GeocodeCountry>;
  concessions: Array<Concession>;
  loading: boolean;
  termSearched: string;
  defaultInputValue: string;
  nameAutoFillDisable: string;

  onChange: (value: GeocodeInputLocality) => void;
  onTouched: () => void;

  /**
   * This property allows someone to set or get the value of our control. Its type should be the same type we used for
   * the type parameter when we implemented MatFormFieldControl. We are using the rawInput property of the geocodeInput
   */
  @Input()
  get value(): GeocodeInputLocality | null {
    return this.localitySelected;
  }
  set value(value: GeocodeInputLocality | null) {
    this.localitySelected = value;
    this.onChange(this.value);
    this.stateChanges.next();
  }

  /**
   * This property should return the ID of an element in the component's template that we want the <mat-form-field> to
   * associate all of its labels and hints with. In this case, we'll use the host element and just generate a unique
   * ID for it.
   */
  @HostBinding() id = `${COMPONENT_SELECTOR}-${InputGeocodeComponent.nextId++}`;

  /**
   * This property allows us to tell the <mat-form-field> what to use as a placeholder. In this example, we'll do the
   * same thing as matInput and <mat-select> and allow the user to specify it via an @Input(). Since the value of the
   * placeholder may change over time, we need to make sure to trigger change detection in the parent form field by
   * emitting on the stateChanges stream when the placeholder changes.
   */
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  _placeholder: string;

  /**
   * This property indicates whether or not the form field control should be considered to be in a focused state.
   * When it is in a focused state, the form field is displayed with a solid color underline.
   */
  focused: boolean;

  /**
   * This property indicates whether the form field control is empty. For our control, we'll consider it empty if the
   * value is undefined.
   */
  get empty() {
    return isEmpty(this.value);
  }

  /**
   * This property is used to indicate whether the label should be in the floating position. Because we are using a
   * select and input combo we need to always float.
   */
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty || !!this.termSearched;
  }

  /**
   * This property is used to indicate whether the input is required. <mat-form-field> uses this information to add a
   * required indicator to the placeholder. Again, we'll want to make sure we run change detection if the required
   * state changes.
   */
  @Input()
  get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }
  private _required: boolean;

  /**
   * This property tells the form field when it should be in the disabled state. In addition to reporting the right
   * state to the form field, we need to set the disabled state on the geocode input that make up our component.
   */
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  private _disabled: boolean;

  /**
   * This property indicates whether the associated NgControl is in an error state.
   */
  get errorState(): boolean {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }
  set errorState(value: boolean) {
    this._errorState = value;
  }
  _errorState: boolean;

  /**
   * This property allows us to specify a unique string for the type of control in form field. The <mat-form-field> will
   * add an additional class based on this type that can be used to easily apply special styles to a <mat-form-field>
   * that contains a specific type of control.
   */
  controlType: string;

  /**
   * Apply those IDs to our host element.
   */
  @HostBinding('attr.aria-describedby') describedBy: string;

  constructor(
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private fb: FormBuilder,
    private injector: Injector,
    private geocodeService: GeocodeService,
    private localeService: LocaleService,
    private geocodeV1Strategy: GeocodeV1Strategy,
    private geocodeV2Strategy: GeocodeV2Strategy
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, null);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.stateChanges = new Subject<void>();
    this.inputValue$ = new Subject();
    this.countriesAndConcessionsFetched$ = new BehaviorSubject(false);
    this.valueFromParent$ = new Subject();
    this.unsubscribe$ = new Subject();
    this._required = false;
    this._disabled = false;
    this.focused = false;
    this.errorState = false;
    this.controlType = COMPONENT_SELECTOR;
    this.describedBy = '';
    this.onChange = (value: GeocodeInputLocality): void => {};
    this.onTouched = (): void => {};
    this.defaultInputValue = '';
    this.termSearched = null;
    this.nameAutoFillDisable = new Date().getTime().toString();
    this.forceV1 = false;
  }

  /**
   * OnInit Life cycle.
   *
   * @description The Input Geocode component don't use Ngrx store due to the geocode strategy complexity.
   * The client implementation would be too constraining.
   */
  ngOnInit(): void {
    // Retrieve NgControl manually to avoid circular dependency injection.
    this.ngControl = this.injector.get<NgControl>(NgControl as Type<NgControl>, null);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.fm.monitor(this.elRef.nativeElement, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(origin => {
        this.focused = !!origin && !this._disabled;
        if (!this.focused && !this._disabled) {
          this.onTouched();
        }
        this.stateChanges.next();
      });

    this.fetchCountriesAndConcessions();
    this.setValueFromParent();

    this.geocodeLocalities$ = this.inputValue$.pipe(
      map((term: string) => term.trim()),
      filter((term: string) => term.length >= 1),
      debounceTime(600),
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
      tap(() => (this.loading = true)),
      switchMap((term: string): Observable<Array<GeocodeGroup | GeocodeLocality>> =>
        this.getLocalities(term).pipe(finalize(() => (this.loading = false)))
      )
    );
  }

  /**
   * We need to re-evaluate this on every change detection cycle, because there are some error triggers that we can't
   * subscribe to (e.g. parent form submissions). This means that whatever logic is in here has to be super lean or we
   * risk destroying the performance.
   */
  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  /**
   * OnDestroy Life cycle
   */
  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * This method is used by the <mat-form-field> to specify the IDs that should be used for the aria-describedby
   * attribute of your component. The method has one parameter, the list of IDs, we just need to apply the given IDs
   * to our host element.
   */
  setDescribedByIds(ids: Array<string>) {
    this.describedBy = ids.join(' ');
  }

  /**
   * Allows Angular to update the model (geocodeInput).
   *
   * @param value - The new model value.
   */
  writeValue(value: GeocodeInputLocality): void {
    value && this.valueFromParent$.next(value);
  }

  /**
   * Allows Angular to register a function to call when the model (geocodeInput) changes. Save the function as a
   * property to call later here.
   *
   * @param fn - The onChange callback to register.
   */
  registerOnChange(fn: (value: GeocodeInputLocality) => void): void {
    this.onChange = fn;
  }

  /**
   * Allows Angular to register a function to call when the input has been touched. Save the function as a
   * property to call later here.
   *
   * @param fn - The onTouched callback to register.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Allows Angular to disable the input.
   *
   * @param disabled - The current form control state value.
   */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  /**
   * This method is the one required by the Validator interface. It is used by ngControl to validate the matFormField
   * control.
   *
   * @param control - The control to validate.
   * @return The ValidationErrors or null if the control is valid.
   */
  validate = (control: AbstractControl): ValidationErrors | null => {
    return this.geocodeInput.nativeElement.value && !this.value
      ? { inputGeocode: 'no geocode locality selected' }
      : null;
  }

  /**
   * This method will be called when the form field is clicked on. It allows your component to hook in and handle that
   * click however it wants. The method has one parameter, the MouseEvent for the click. In our case we'll just focus
   * the first <input> if the user isn't about to click an <input> anyways.
   */
  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  /**
   * OnChange event from geocode input.
   * @param value - geocode input value
   */
  onInputChange(value: string): void {
    // reset value on key press
    this.value = null;
    this.termSearched = value;
    this.inputValue$.next(value);
  }

  /**
   * Retrieve geocode localities according chosen strategy.
   * @param term - the search term seized.
   */
  getLocalities(term: string): Observable<Array<GeocodeGroup | GeocodeInputLocality>> {
    const country = this.getGeocodeCountry();
    const isNumeric = !isNaN(+term);
    // strategy evolves if user types digits or alpha characters.
    if (isNumeric || this.forceV1) {
      this.strategy = this.geocodeV1Strategy;
      this.version = GeocodeVersion.V1;
    } else {
      this.strategy = this.geocodeV2Strategy;
      this.version = GeocodeVersion.V2;
    }
    return this.strategy.getLocalities(country, term, this.excludedIds || []);
  }

  /**
   * Retrieve geocode country from concession.
   */
  getGeocodeCountry(): GeocodeCountry {
    let countryFound = this.countries.find(
      (gCountry: GeocodeCountry) => gCountry.iso.toLowerCase() === this.concession.isoCode.toLowerCase()
    );

    if (!countryFound) {
      const { isoCode } = this.concessions.find(
        (concession: Concession) => concession.secondsIsoCode.indexOf(this.concession.isoCode) !== -1
      );
      countryFound = this.countries.find(
        (gCountry: GeocodeCountry) => gCountry.iso.toLowerCase() === isoCode.toLowerCase()
      );
    }

    return countryFound;
  }

  /**
   * Format a geocode locality.
   * @param locality - geocode locality.
   * @param group - geocode group.
   */
  formatLocality(locality: GeocodeInputLocality, group: GeocodeGroup = null): string {
    return this.strategy.formatLocality(this.getGeocodeCountry(), locality, group);
  }

  /**
   * Set Geocode locality selected.
   * @param locality - geocode locality.
   */
  onGeocodeSelected(locality: GeocodeInputLocality, group: GeocodeGroup = null): void {
    this.value = { ...locality, formattedValue: this.formatLocality(locality, group) };
  }

  /**
   * Fetch available countries and subsidiary concessions.
   */
  private fetchCountriesAndConcessions(): void {
    this.geocodeService
      .getCountriesAndConcessions(this.localeService.getLocale())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([countries, concessions]: [Array<GeocodeCountry>, Array<Concession>]) => {
        this.countries = countries;
        this.concessions = concessions;
        this.countriesAndConcessionsFetched$.next(true);
      });
  }

  /**
   * Set component internal value when parent control has a value.
   */
  private setValueFromParent() {
    combineLatest(this.valueFromParent$, this.countriesAndConcessionsFetched$)
      .pipe(
        filter(([_, isFetched]) => isFetched),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([locality]) => {
        this.defaultInputValue =
          locality.formattedValue || locality[locality.searchLevel] || locality[this.getGeocodeCountry().searchField];
        this.value = locality;
        this.stateChanges.next();
      });
  }
}
