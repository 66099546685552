import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs/operators';

import { Profile, TopbarItem, User } from '../../../models';
import { AuthService } from '../../../services/auth/auth.service';
import { QuicktourService } from '../../../services/quicktour/quicktour.service';
import { TopBarService } from '../../../services/topbar/topbar.service';
import { CoreState } from '../../../store';
import * as MenuActions from '../../../store/menu/menu.actions';
import * as NotificationActions from '../../../store/notifications/notifications.actions';
import * as RouterActions from '../../../store/router/router.actions';
import { Concession } from './../../../models/concession/concession.interface';
import { TopbarEnum } from './topbar.enum';

@Component({
  selector: 'iad-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  // SideNav input bindings.
  @Input() sideNav: MatSidenav;
  // notificationSidenav input bindings
  @Input() notificationSidenav: MatSidenav;
  // Input binding for profile.
  @Input() profile: Profile;
  @Input() user: User;
  // Output event for logo click.
  @Output() homeClick: EventEmitter<MouseEvent>;
  // Concession Object
  concession: Concession;
  searchBarUnfold: boolean;
  // Dummy topbar item used for the burger button.
  burgerItem: TopbarItem;
  items: Array<TopbarItem>;
  cdiUrl: string;

  /**
   *
   * @param AuthService authService
   * @param QuicktourService quicktourService
   * @param TopBarService topBarService
   */
  constructor(
    private store: Store<CoreState>,
    private authService: AuthService,
    private quicktourService: QuicktourService,
    private topBarService: TopBarService
  ) {
    this.searchBarUnfold = false;
    this.cdiUrl = this.authService.getCdiUrl();
    this.burgerItem = {
      id: TopbarEnum.BURGER,
      name: TopbarEnum.BURGER,
      icon: 'fas fa-bars'
    };
    this.homeClick = new EventEmitter<MouseEvent>();
  }

  /**
   * During the OnInit hook, we need to fetch the topbar items.
   */
  ngOnInit(): void {
    this.concession = this.user.concession;
    this.topBarService
      .getItems(this.profile, this.concession.slug, this.user)
      .pipe(
        // Side effect used to early set items on topbar to display them as fast as possible.
        tap((items: Array<TopbarItem>) => (this.items = items)),
        // Switch to counter loading later.
        switchMap((items: Array<TopbarItem>) => this.topBarService.getItemCounters(this.user, items))
      )
      .subscribe();
  }

  /**
   * This method change the window location according to the given item url attribute.
   *
   * @param TopbarItem item - The item requesting the window location change.
   */
  handleNavigation(item: TopbarItem): void {
    if (!item || !item.url) {
      return;
    }
    this.navigateByURL(item.url);
  }

  /**
   * A method that does the redirection based on the given url in params.
   *
   * @param url string - The redirect url.
   */
  navigateByURL(url: string = this.cdiUrl): void {
    this.store.dispatch(RouterActions.openNewTab({ url }));
  }

  /**
   * Callback invoke when a topbar item is clicked.
   *
   * @param TopbarItem item - The item that trigger the click event.
   */
  onItemClick(item: TopbarItem): void {
    switch (item.name) {
      case TopbarEnum.BURGER:
        this.store.dispatch(MenuActions.toggleMenu({ opened: !this.sideNav.opened }));
        break;
      case TopbarEnum.RCP:
        if (this.authService.hasPlusSimpleAccount()) {
          this.handleNavigation(item);
        } else {
          this.handleNavigation({ ...item, url: item.ng1Url });
        }
        break;

      case TopbarEnum.QUICKTOUR:
        this.quicktourService.startIntroJS();
        break;

      case TopbarEnum.NOTIFICATIONS:
        this.store.dispatch(NotificationActions.toggleNotification({ opened: !this.notificationSidenav.opened }));
        break;

      case TopbarEnum.LOGOUT:
        this.authService.logout();
        this.handleNavigation(item);
        break;

      default:
        this.handleNavigation(item);
        break;
    }
  }
}
