import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../../material.module';
import { CurrencyInputComponent } from './currency-input.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  declarations: [CurrencyInputComponent],
  exports: [CurrencyInputComponent],
  providers: [CurrencyPipe]
})
export class CurrencyInputModule {}
