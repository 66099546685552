import { Constructor } from '../models';
import { Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';

/**
 * Mixin used to benefit from a destroy subject coupled with OnDestroy life cycle.
 * @param Base - the base class to extends
 */
export function WithDestroySubject<TBase extends Constructor>(Base: TBase = class {} as any) {
  return class extends Base implements OnDestroy {
    destroy$: Subject<void>;

    constructor(...args: any[]) {
      super();
      this.destroy$ = new Subject<void>();
    }

    /**
     * Complete the destroy$ subject here to clean up internal observable subscription.
     */
    ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }
  };
}
