import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';

import { BottomSheetListItem } from '../../../models/bottom-sheet/bottom-sheet-list-item.interface';

@Component({
  selector: 'iad-bottom-sheet-list',
  templateUrl: './bottom-sheet-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomSheetListComponent {
  items: Array<BottomSheetListItem>;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetListComponent, BottomSheetListItem['afterDismissedActions']>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.items = this.data.items;
  }

  /**
   * Triggers the dismiss `MatBottomSheetRef` method.
   * @param actions - The actions collection to dispatch after the bottom sheet dismissed.
   */
  dismiss(actions: BottomSheetListItem['afterDismissedActions']): void {
    this.bottomSheetRef.dismiss(actions);
  }
}
