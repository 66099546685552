import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';

import { MaterialModule } from '../../../material.module';
import { PipesModule } from '../../../pipes.module';
import { TimepickerDialogComponent } from '../../dialogs/timepicker-dialog/timepicker-dialog.component';
import { TimepickerInputComponent } from './timepicker-input.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    PipesModule,
    TextMaskModule,
  ],
  declarations: [TimepickerInputComponent, TimepickerDialogComponent],
  exports: [TimepickerInputComponent],
  entryComponents: [TimepickerDialogComponent]
})
export class TimepickerInputModule {}
