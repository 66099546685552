import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { BehaviorSubject, combineLatest, concat, iif, merge, Observable, Subject } from 'rxjs';
import { concatMap, filter, finalize, map, mergeMap, share, skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DEFAULT_SIZE_MAX, FILE_MIME_TYPE_MAP, READY_TO_UPLOAD_STATE, UPLOADED_STATE } from '../../../constants';
import { FileMimeType, UploaderConfig, UploaderFile, UploaderFileState, UploadMode, UploadStatus } from '../../../models';
import { UploadService } from '../../../services/upload/upload.service';
import { UrlService } from '../../../services/url/url.service';
import { WindowRef } from '../../../services/window/window.service';
import { DialogConfirmComponent } from '../../dialogs/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'iad-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploaderComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('dropZone', { static: false })
  dropZone: ElementRef;
  @ViewChild('uploadButton', { static: false })
  uploadButton: ElementRef;
  @Input() disabled: boolean;
  @Input()
  set config(value: UploaderConfig) {
    this._config = this.mergeDefaultConfig(value);
  }
  @Input() uploadMode: UploadMode;
  @Input() autoUpload: boolean;
  @Input() files: Array<UploaderFile>;
  @Output() removed: EventEmitter<{ id: number; name: string }>;
  @Output() uploaded: EventEmitter<{ id: number; name: string }>;
  @Output() uploadError: EventEmitter<string>;

  _config: UploaderConfig;
  mode: BehaviorSubject<UploadMode>;
  auto: BehaviorSubject<boolean>;
  filesStateByName: Map<string, Subject<UploaderFileState>>;
  filesToUpload: Array<UploaderFile>;
  file: Subject<UploaderFile>;
  dragHover: boolean;
  mobile$: Observable<boolean>;
  uploading: boolean;
  manualStart: Subject<void>;
  cancel: Subject<void>;
  unsubscribe$: Subject<void>;
  currentTotalSize: number;
  extensionsAllowedList: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private uploadService: UploadService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
    private translate: TranslateService,
    private urlService: UrlService,
    private windowRef: WindowRef
  ) {
    this.dragHover = false;
    this.disabled = false;
    this.uploading = false;
    this.files = [];
    this.filesToUpload = [];
    this.currentTotalSize = 0;
    this.removed = new EventEmitter<{ id: number; name: string }>();
    this.uploaded = new EventEmitter<{ id: number; name: string }>();
    this.uploadError = new EventEmitter<string>();
    this.file = new Subject<UploaderFile>();
    this.mode = new BehaviorSubject<UploadMode>(UploadMode.INCONSECUTIVE);
    this.auto = new BehaviorSubject<boolean>(true);
    this.cancel = new Subject<void>();
    this.filesStateByName = new Map<string, Subject<UploaderFileState>>();
    this.unsubscribe$ = new Subject<void>();
    this.manualStart = new Subject<void>();
  }

  /**
   * Life cycle onInit
   */
  ngOnInit(): void {
    this.observeHandset();
    this.buildUploadStream();
    if (this.files.length) {
      this.onFilesSelected(this.files);
    }
    this.constructExtensionAllowedList();
  }

  /**
   * Life cycle onChanges
   * @param changes - changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uploadMode) {
      this.mode.next(changes.uploadMode.currentValue);
    }
    if (changes.autoUpload) {
      this.auto.next(changes.autoUpload.currentValue);
    }
  }

  /**
   * Life cycle onDestroy
   */
  ngOnDestroy(): void {
    this.clearAll();
    this.manualStart.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Method called when the user hover the drop zone
   * @param event DragEvent - The DragEvent object
   */
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.dragHover = true;
  }

  /**
   * Method called when a file is drop
   * @param event DragEvent - The DragEvent object
   */
  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.dragHover = false;
    if (event.dataTransfer.files.length) {
      this.onFilesSelected(event.dataTransfer.files);
    }
  }

  /**
   * Method called when user leave the drop zone
   * @param event DragEvent - The DragEvent object
   */
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    if (!this.dropZone.nativeElement.contains(event.relatedTarget as Node)) {
      this.dragHover = false;
    }
  }

  /**
   * Method called when user add new files to upload
   * @param files FileList|Array<UploaderFile> - user's files to upload
   */
  onFilesSelected(files: FileList | Array<UploaderFile>): void {
    if (this.disabled) {
      return;
    }

    if (files instanceof FileList) {
      files = Array.from(files).map(
        file => ({ id: null, data: file, uploaded: false, uploading: false } as UploaderFile)
      );
    }

    files.forEach((file: UploaderFile) => {
      if (!file.uploaded && !this.canBeUploaded(file)) {
        return;
      }
      const initialState = file.uploaded ? { ...UPLOADED_STATE, loaded: file.data.size } : READY_TO_UPLOAD_STATE;
      file.previewUrl = this.getPreviewUrl(file);
      this.currentTotalSize += file.uploaded ? 0 : file.data.size;
      this.filesStateByName.set(file.data.name, new BehaviorSubject<UploaderFileState>(initialState));
      this.filesToUpload.push(file);
      if (!file.uploaded) {
        this.file.next(file);
      }
    });
  }

  /**
   * Open a new tab and display file preview
   * @param url string - file's preview url
   */
  previewFile(url: string): void {
    this.windowRef.nativeWindow.open(url, '_blank');
  }

  /**
   * Remove a local or uploaded file
   * @param index number - element's index to remove
   */
  removeFile(index: number): void {
    const file = this.filesToUpload[index];
    const deleteSteps = () => {
      this.filesStateByName.get(file.data.name).complete();
      this.filesStateByName.delete(file.data.name);
      this.currentTotalSize -= file.data.size;
      this.filesToUpload.splice(index, 1);
      this.removed.emit({ id: file.id, name: file.data.name });
    };

    if (file.uploaded) {
      file.removing = true;

      const data = {
        content: this.translate.instant('iadUploader.remove', { file: file.data.name })
      };
      const remove$ = this.uploadService.remove(this._config.api, `${this._config.url}/${file.id}`);
      const confirmToRemove$ = this.dialog
        .open(DialogConfirmComponent, { data })
        .afterClosed()
        .pipe(
          filter((res: boolean) => res),
          switchMap(() => remove$)
        );

      iif(() => this._config.confirmDeletion, confirmToRemove$, remove$)
        .pipe(
          finalize(() => {
            file.removing = false;
            this.changeDetector.markForCheck();
          })
        )
        .subscribe(() => deleteSteps());
    } else {
      deleteSteps();
    }
  }

  /**
   * Remove all files from array.
   */
  clearAll(): void {
    this.filesToUpload.length = 0;
    this.currentTotalSize = 0;
    this.filesStateByName.clear();
  }

  /**
   * Return the preview URL.
   * @param file - The file.
   */
  getPreviewUrl(file: UploaderFile): string {
    return file.uploaded
      ? `${this.urlService.get(this._config.api)}${this._config.url}/${file.id}${this.getURLParams()}`
      : '';
  }

  /**
   * If there are getParameters in config, return them formated for the URL.
   */
  getURLParams(): string {
    const params = new HttpParams({ fromObject: this._config.getParameters });
    return this._config.getParameters ? `?${params.toString()}` : '';
  }

  /**
   * Construct the extensions allowed list to display in the hint.
   */
  constructExtensionAllowedList(): void {
    this.extensionsAllowedList = this._config
      ? this._config.extensionsAllowed.reduce((previous: string, current: string) => {
          return previous
            ? `${previous}, ${FILE_MIME_TYPE_MAP.get(current as FileMimeType)}`
            : FILE_MIME_TYPE_MAP.get(current as FileMimeType);
        }, null)
      : null;
  }

  /**
   * Check if file passes rules.
   * Rules : extension, size, duplication
   * @param fileToUpload File - file to upload
   */
  private canBeUploaded(fileToUpload: UploaderFile): boolean {
    const allExtensions = '["*"]';
    const fileNotInList = !this.filesToUpload.some((file: UploaderFile) => file.data.name === fileToUpload.data.name);
    const extensionAllowed =
      JSON.stringify(this._config.extensionsAllowed) === allExtensions ||
      this._config.extensionsAllowed.indexOf(fileToUpload.data.type) > -1;
    const maxSizeNotExceeded = this.currentTotalSize + fileToUpload.data.size <= this._config.maxSize;

    if (!maxSizeNotExceeded) {
      this.uploadError.emit(this._config.labels.sizeError);
    }

    if (!extensionAllowed) {
      this.uploadError.emit(this._config.labels.extensionError);
    }

    return maxSizeNotExceeded && fileNotInList && extensionAllowed;
  }

  /**
   * Returns a configuration by merging the default with the input one.
   * @param config - The uploader configuration
   */
  private mergeDefaultConfig(config: UploaderConfig): UploaderConfig {
    const labels = {
      extensionError: 'Extension file error',
      sizeError: 'Max size exceeded',
      sizeHint: 'Max size :',
      extensionHint: 'Allowed file types :',
      dragAndDrop: 'Drag and drop here or',
      browse: 'Browse',
      clear: 'Clear',
      cancel: 'Cancel',
      upload: 'Start',
      ...(config.labels || {})
    };

    return {
      ...config,
      allowMultipleFiles: config.allowMultipleFiles == null ? true : config.allowMultipleFiles,
      extensionsAllowed: config.extensionsAllowed || ['*'],
      maxSize: config.maxSize || DEFAULT_SIZE_MAX, // 10 MB
      confirmDeletion: config.confirmDeletion == null ? true : config.confirmDeletion,
      filePreview: config.filePreview == null ? true : config.filePreview,
      labels
    };
  }

  /**
   * Upload file and send its progress state with the help of subject
   * @param file UploaderFile - The file to upload.
   * @returns Upload file observable
   */
  private uploadFile(file: UploaderFile): Observable<UploaderFileState> {
    const updateFileStatus = tap((fileState: UploaderFileState) => {
      this.filesStateByName.get(file.data.name).next(fileState);
      if (fileState.status === UploadStatus.PROGRESSING) {
        file.uploading = true;
      } else if (fileState.status === UploadStatus.SUCCESS) {
        file.uploaded = true;
        file.uploading = false;
        file.id = get(fileState.data, 'id');
        file.previewUrl = this.getPreviewUrl(file);
        this.filesStateByName.get(file.data.name).complete();
        this.uploaded.emit({ id: file.id, name: file.data.name });
      }
    });

    return this.uploadService.upload(file.data, this._config.api, this._config.url, this._config.postParameters).pipe(
      filter((fileState: UploaderFileState) => !!fileState),
      updateFileStatus
    );
  }

  /**
   * Get the cancel stream which emit when user cancel manual upload. Reset non uploading files.
   */
  private getCancelStream(): Observable<void> {
    return this.cancel.asObservable().pipe(
      tap(() =>
        this.filesToUpload.forEach((file: UploaderFile) => {
          if (file.uploading) {
            file.uploading = false;
            this.filesStateByName.get(file.data.name).next(READY_TO_UPLOAD_STATE);
          }
        })
      )
    );
  }

  /**
   * Get the auto upload stream
   * @param mode UploadMode - The selected upload mode
   * @returns The manual upload's consecutive or inconsecutive stream
   */
  private getManualUploadStream(mode: UploadMode): Observable<UploaderFile | UploaderFileState> {
    const manualStart$ = this.manualStart.asObservable();

    const getFilesToUpload = () => this.filesToUpload.filter(file => !file.uploaded).map(file => this.uploadFile(file));
    const consecutive$ = () => concat(...getFilesToUpload());
    const inconsecutive$ = () => merge(...getFilesToUpload());

    return manualStart$.pipe(
      tap(() => (this.uploading = true)),
      switchMap(() =>
        iif(() => mode === UploadMode.CONSECUTIVE, consecutive$(), inconsecutive$()).pipe(
          takeUntil(this.getCancelStream()),
          finalize(() => (this.uploading = false))
        )
      )
    );
  }

  /**
   * Get the manual upload stream
   * @param mode UploadMode - The selected upload mode
   * @returns The auto upload's consecutive or inconsecutive stream
   */
  private getAutoUploadStream(mode: UploadMode): Observable<UploaderFileState> {
    const file$ = this.file.asObservable();

    const consecutive$ = file$.pipe(concatMap((file: UploaderFile) => this.uploadFile(file)));
    const inconsecutive$ = file$.pipe(mergeMap((file: UploaderFile) => this.uploadFile(file)));

    return iif(() => mode === UploadMode.CONSECUTIVE, consecutive$, inconsecutive$).pipe(
      skipWhile(() => this.filesToUpload.some((file: UploaderFile) => !file.uploaded))
    );
  }

  /**
   * Build the main upload component's stream
   */
  private buildUploadStream(): void {
    const auto$ = this.auto.asObservable();
    const mode$ = this.mode.asObservable();

    combineLatest([mode$, auto$])
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.clearAll()),
        switchMap(([mode, auto]: [UploadMode, boolean]) =>
          iif(() => auto, this.getAutoUploadStream(mode), this.getManualUploadStream(mode))
        )
      )
      .subscribe();
  }

  /**
   * Create an observable that emit when we are on handset screen. Used to hide the drop zone.
   */
  private observeHandset(): void {
    this.mobile$ = this.breakpointObserver.observe([Breakpoints.Handset]).pipe(
      takeUntil(this.unsubscribe$),
      map((state: BreakpointState) => state.matches),
      share()
    );
  }
}
