import { GridConfig } from '../../models';

export class AddressConstants {
  public static DEFAULT_GRID: GridConfig = {
    areas: 'country country | address1 address2 | postalCode city | state state',
    rows: 'auto auto auto',
    areasLtMd: 'country | address1 | address2 | postalCode | city | state',
    rowsLtMd: 'auto auto auto auto auto'
  };
}
