import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { isObject } from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { Preference, Qualification } from '../../models';
import { JwtToken } from '../../models/api-response/api-response.interface';
import { User } from '../../models/user/user.interface';
import { UserState } from '../../store/user/user.reducer';
import * as fromUserSelectors from '../../store/user/user.selectors';
import { ApiService } from '../api/api.service';
import { LocaleService } from '../locale/locale.service';
import { UrlService } from '../url/url.service';
import { Concession } from './../../models/concession/concession.interface';
import { AuthConstant } from './auth.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // user object
  user: User;
  // usefulls urls
  authHost: string;
  ngHost: string;
  cdiUrl: string;
  wisHost: string;
  monolithHost: string;

  constructor(
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private store: Store<UserState>,
    private apiService: ApiService,
    private urlService: UrlService,
    private location: Location,
    private localeService: LocaleService,
    private jwtService: JwtHelperService
  ) {
    this.setUrls();
    this.subscribeUser();
  }

  /**
   *
   * Function used to return the concession of current user.
   *
   * @returns The concession
   */
  getConcession(): Concession | null {
    return this.user ? this.user.concession : null;
  }

  /**
   * Return currently user logged on WIS with his preferences and his qualification level.
   * @returns The connected user.
   */
  getUser(): Observable<User> {
    if (this.isAuthenticated()) {
      return of(this.storage.get(AuthConstant.USER_KEY));
    } else {
      return this.apiService.get('wis', '/directory/peoples/me').pipe(
        map((user: User) => this.formatUserResponse(user)),
        catchError((error: any) => throwError(new Error(error)))
      );
    }
  }

  /**
   *
   * Retrieve the JWT token and map the response with a User object contained in the jwt payload.
   * @returns User - True or false
   */
  getAuthToken(): Observable<User> {
    if (this.isAuthenticated() && !this.isTokenExpired()) {
      return of(this.storage.get(AuthConstant.USER_KEY));
    } else {
      return this.apiService.get<JwtToken>('monolith', '/security/auth/token').pipe(
        tap((res: JwtToken) => this.storage.set(AuthConstant.JWT_TOKEN_KEY, res.token)),
        map((res: JwtToken) => this.formatUserResponse(this.jwtService.decodeToken(res.token)))
      );
    }
  }

  /**
   * Get Auth login Url.
   */
  getAuthLoginUrl(): string {
    return `${this.monolithHost}/security/auth/login?redirectTo=${encodeURIComponent(this.getCurrentPathUrl())}`;
  }

  /**
   * Get current path in app.
   */
  getCurrentPathUrl(): string {
    return `${this.ngHost}${this.location.path()}`;
  }

  /**
   * Function responsible of call the WebService in charge of user's preferences.
   * Return the current user.
   * @param label - Label of the preference
   * @param value - Value of the preference
   * @returns The connected user
   */
  savePreference(label: string, value: string): Observable<User> {
    return this.apiService.post('wis', `/directory/peoples/me/preferences/${label}`, { value }).pipe(
      switchMap(() => this.getUser()),
      switchMap((user: User) => this.localeService.setLocale(this.computeUserLocale()).pipe(mapTo(user)))
    );
  }

  /**
   * Return user's locale if locale preference is set, or if not, the concession's locale.
   * @params user User - the user to extract local preference
   * @returns The user's locale or the concession's first locale.
   */
  computeUserLocale(): string {
    let localePreference = this.getPreference('locale');
    if (!localePreference) {
      localePreference = this.user ? this.user.concession.languages[0] : null;
    }
    return localePreference as string;
  }

  /**
   * Return user's preference.
   * @param label - Label of the preference
   * @returns The preference or null if the preference doesn't exist
   */
  getPreference(label: string): string | object | null {
    if (this.user && this.user.preferences.length) {
      const preference = this.user.preferences.find((pref: Preference) => pref.label === label);
      return preference ? preference.value : this.user.locale;
    } else {
      return this.user.locale;
    }
  }

  /**
   * Method to check if a user is authenticated.
   */
  isAuthenticated(): boolean {
    return this.storage.get(AuthConstant.USER_KEY) != null;
  }

  /**
   * Method to logout.
   */
  logout(): void {
    this.user = null;
    AuthConstant.GET_ALL_KEYS().forEach((key: any) => this.storage.remove(key));
  }

  /**
   *
   * Get the concession slug of the current user.
   *
   * @returns The concession slug
   */

  getConcessionSlug(): string {
    return this.user && this.user.concession && this.user.concession.slug;
  }

  /**
   *
   * Check if user has plusSimple account.
   *
   * @returns True or false
   */

  hasPlusSimpleAccount(): boolean {
    return this.user && this.user.plusSimpleAccount;
  }

  /**
   *
   * Check if user has a cityscan account.
   *
   * @returns True or false
   */

  hasCityScanAccount(): boolean {
    return this.user && !!this.user.idCityObs;
  }

  /**
   * Helper method to return Cdi url
   */
  getCdiUrl(): string {
    const concession: string =
      this.user && this.user.concession && this.user.concession.slug ? this.user.concession.slug : 'france';
    return `${this.cdiUrl}/${concession}`;
  }

  /**
   *
   * Check if user has a 360learning account.
   *
   * @returns True or false
   */

  has360learningAccount(): boolean {
    return this.user && !!this.user.universityId;
  }

  /**
   * Method to save user in storage, and update locale.
   * @param user - User Object
   */
  setUser(user: User): void {
    this.user = user;
    // store user in session storage
    this.storage.set(AuthConstant.USER_KEY, user);
  }

  /**
   *
   * Check the token expiration date.
   *
   * @returns Boolean - True or false
   */
  isTokenExpired(): boolean {
    const token: string = this.storage.get(AuthConstant.JWT_TOKEN_KEY);
    if (token) {
      return this.jwtService.isTokenExpired(token);
    } else {
      return false;
    }
  }

  /**
   * Set convenient urls
   */
  private setUrls(): void {
    this.authHost = this.urlService.get('cas');
    this.ngHost = this.urlService.get('ng');
    this.cdiUrl = this.urlService.get('cdi');
    this.wisHost = this.urlService.get('wis');
    this.monolithHost = this.urlService.get('monolith')
  }

  /**
   * Retrieve user from userStore
   */
  private subscribeUser(): void {
    // subscribe to user
    this.store
      .pipe(
        select(fromUserSelectors.selectUser),
        take(1)
      )
      .subscribe((user: User) => (this.user = user));
  }

  /**
   * Format user preferences and qualifications.
   * @param user - User Object
   */
  private formatUserResponse(user: User): User {
    // fix preference table to {label, value} object
    user.preferences = (user.preferences || []).map((preference: any) => ({
      label: preference.preference.label,
      value: preference.value
    }));
    const qualification = isObject(user.qualification)
      ? (user.qualification as Qualification).level
      : user.qualification as string;
    // define user qualification level with fallback to root qualification
    user.qualificationLevel = user.qualificationLevelReference ? user.qualificationLevelReference.label : qualification;
    return user;
  }
}
