import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { get } from 'lodash';

import { RouterStateUrl } from '../../models/router';

export * from './router.actions';
export * from './router.effects';

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  /**
   * Serialize router state after each navigation. This method is called by router-store.
   * @param routerState - Router state after navigation.
   * @returns A custom router state object which will put in Store
   */
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url }: { url: string } = routerState;
    const { queryParams }: { queryParams: Params } = routerState.root;
    let state: ActivatedRouteSnapshot = routerState.root;
    let path = get(state, 'routeConfig.path', '');
    let parentPath = get(state, 'parent.routeConfig.path', '');
    let data = get(state, 'parent.routeConfig.data', {});

    while (state.firstChild) {
      state = state.firstChild;
      if (get(state, 'routeConfig.path')) {
        parentPath = path;
        path = !get(state, 'routeConfig.path') ? path : get(state, 'routeConfig.path');
      }
      data = { ...data, ...get(state, 'routeConfig.data', {}) };
    }

    const { params }: { params: Params } = state;
    return { url, path, parentPath, queryParams, params, data };
  }
}
