import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { QuicktourStep } from '../../models/quicktour/quicktour-step.interface';

export const INTRO_DEFAULT_STEPS: Array<QuicktourStep> = [
  {
    element: '.iad-topbar-home',
    intro: _('quicktourService.steps.home') as string
  },
  {
    element: '.iad-menu-profile',
    intro: _('quicktourService.steps.profile') as string
  },
  {
    element: '#iad-menu',
    intro: _('quicktourService.steps.menu') as string
  },
  {
    element: '#iad-menu-icons',
    intro: _('quicktourService.steps.icons') as string
  },
  {
    element: '#iad-menu-icon-search',
    intro: _('quicktourService.steps.search') as string
  },
  {
    element: '#iad-menu-icon-share',
    intro: _('quicktourService.steps.share') as string
  },
  {
    element: '#iad-menu-icon-th',
    intro: _('quicktourService.steps.apps') as string
  },
  {
    element: '#iad-menu-icon-help',
    intro: _('quicktourService.steps.help') as string
  },
  {
    element: '#iad-menu-icon-bell',
    intro: _('quicktourService.steps.bell') as string
  },
  {
    element: '#iad-menu-icon-off',
    intro: _('quicktourService.steps.logout') as string
  }
];
