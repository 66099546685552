import { ConcessionSlug } from '../../models/concession/concession-slug.enum';

export class LegalAgeConstants {
  public static legalAge: Record<ConcessionSlug, number> = {
    france: 18,
    italia: 18,
    portugal: 18,
    espana: 18,
    deutschland: 18,
    'united-kingdom': 18,
    florida: 18
  };
}
