import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { merge } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreConfig } from '../../models/core-config/core-config.interface';

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    public resources: Array<TranslateRessource> = [
      {
        prefix: '/assets/i18n/',
        suffix: '.json'
      }
    ]
  ) {}

  /**
   * Gets the translations from assets directory and merge them in one file.
   * @param lang - string code language
   * @returns Observable<object>
   */
  public getTranslation(lang: string): Observable<object> {
    return forkJoin(
      this.resources.map((config: TranslateRessource) => {
        return this.http.get(`${config.prefix}${lang}${config.suffix}`);
      })
    ).pipe(
      map((response: Array<object>) => {
        return response.reduce((a: object, b: object) => {
          return merge(a, b);
        });
      })
    );
  }
}

interface TranslateRessource {
  prefix: string;
  suffix: string;
}

export const translateLoader = (config: CoreConfig, http: HttpClient): MultiTranslateHttpLoader => {
  const resources: Array<TranslateRessource> = config.locale.directories.map((directory: string) => ({
    prefix: `./assets/l10n/${directory}/`,
    suffix: '.json'
  }));
  return new MultiTranslateHttpLoader(http, resources);
};
