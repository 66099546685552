import { ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { OperatorFunction, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { RouterStateUrl } from '../models/router';

/**
 * Listen ROUTER_NAVIGATION action and compare the given parentPath and currentPath to the current router state path and parentPath.
 * Use this operator in effects, when you want to dispatch actions based on the url state
 * @param string parentPath - path to compare to the current router state parentPath.
 * @param string currentPath - path to compare to the current router state path.
 * @returns A custom rxjs operator that emit only if path is equal to current router state path
 */
export const ofRoute = (parentPath: string, currentPath: string): OperatorFunction<Action, RouterStateUrl> => {
  return pipe(
    ofType(ROUTER_NAVIGATION),
    map((action: RouterNavigationAction<RouterStateUrl>) => action.payload.routerState),
    filter((payload: RouterStateUrl) => {
      const asterisk = currentPath === '*';
      return payload.parentPath === parentPath && (asterisk || payload.path === currentPath);
    })
  );
};
