import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Host,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatTooltip } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { toString } from 'lodash';
import { Subject, timer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { CopyToClipboardStatus } from './copy-to-clipboard-status.enum';

/**
 * Provides `MatTooltip` features to the copy-to-clipboard selector.
 */
@Directive({
  selector: '[iadCopyToClipboard]'
})
export class CopyToClipboardTooltipDirective extends MatTooltip {}

/**
 * Provides behavior for an element that when clicked copies value into
 * user's clipboard.
 */
@Directive({
  selector: '[iadCopyToClipboard]'
})
export class CopyToClipboardDirective implements AfterViewInit, OnDestroy {

  /** The value to be copied to the clipboard. */
  @Input('iadCopyToClipboard')
  get value(): string { return this._value; }
  set value(value: string) {
    this._value = toString(value);

    if (this._textarea) {
      this._textarea.value = this._value;
    }
  }
  private _value = '';

  /** The message to be displayed in the tooltip. */
  @Input('iadCopyToClipboardTooltip')
  get message(): string { return this._message; }
  set message(value: string) {
    const message = value
      ? toString(value)
      : this._translateService.instant('copyToClipboard.copy');

    this._message = this._tooltip.message = message;
  }
  private _message: string;

  /** Emits when the copy status has changed. */
  @Output() iadCopyToClipboardStatus = new EventEmitter<CopyToClipboardStatus>();

  /** The cursor style binding. */
  @HostBinding('style.cursor') cursor = 'pointer';

  /** A stream of copy events. */
  private _copy$ = new Subject<void>();

  /** The hidden textarea created to select the copyable text. */
  private _textarea: HTMLTextAreaElement;

  /** The copy status (default or copied). */
  private _status = CopyToClipboardStatus.DEFAULT;

  constructor(
    @Inject(DOCUMENT) private readonly _document: any, // Avoid AOT metadata issue
    @Host() private _tooltip: CopyToClipboardTooltipDirective,
    private _translateService: TranslateService
  ) {
    this.message = this._translateService.instant('copyToClipboard.copy');
    this._tooltip.position = 'above';
  }

  /**
   * Create the hidden textarea and subscribe to copy events to update
   * the tooltip message and emit status events.
   *
   * A timer is used to rollback the status to the default state after
   * the user has copied the text.
   */
  ngAfterViewInit(): void {
    this.createTextarea();

    this._copy$
      .pipe(
        switchMap(
          () => timer(0, 3000).pipe<CopyToClipboardStatus>(take(2))
        )
      )
      .subscribe(status => {
        this._status = status;
        this._tooltip.message = status === CopyToClipboardStatus.COPIED
          ? this._translateService.instant('copyToClipboard.copied')
          : this.message;
        this.iadCopyToClipboardStatus.emit(status);
      });
  }

  /**
   * Remove the textarea and complete the copy subject.
   */
  ngOnDestroy(): void {
    this.removeTextarea();
    this._copy$.complete();
  }

  /**
   * Cancel the user's click event to avoid conflicts with the `MatTooltip`
   * and copy the text.
   * @param event - The user's click event.
   */
  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this._status === CopyToClipboardStatus.DEFAULT) {
      this.copy();
    }
  }

  /**
   * Creates the textarea that handle the text to copy.
   * The textarea element is hidden for display and accessibility.
   */
  private createTextarea(): void {
    if (!this._textarea) {
      this._textarea = this._document.createElement('textarea');
      this._textarea.style.position = 'fixed';
      this._textarea.style.top = this._textarea.style.opacity = '0';
      this._textarea.style.left = '-999em';
      this._textarea.setAttribute('aria-hidden', 'true');
      this._textarea.value = this.value;
      this._document.body.appendChild(this._textarea);
    }
  }

  /**
   * Copy the text into user's clipboard.
   * We need to restore the user focus because the textarea is selected
   * (and focused) programmatically to do the copy.
   */
  private copy(): void {
    if (this._textarea) {
      const currentFocus = this._document.activeElement as HTMLElement | null;

      this._textarea.select();
      this._textarea.setSelectionRange(0, this._textarea.value.length);
      this._document.execCommand('copy');

      if (currentFocus) {
        currentFocus.focus();
      }

      this._copy$.next();
    }
  }

  /**
   * Remove the textarea element.
   */
  private removeTextarea(): void {
    if (this._textarea) {
      if (this._textarea.parentNode) {
        this._textarea.parentNode.removeChild(this._textarea);
      }

      this._textarea = undefined;
    }
  }
}
