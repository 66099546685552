import { User } from './../../../../models/user/user.interface';

export class MockAuthService {
  cdiUrl: string;
  user: User;
  authHost: string;
  getUser = jasmine.createSpy('getUser');
  setUser = jasmine.createSpy('setUser');
  getAuthToken = jasmine.createSpy('getAuthToken');
  getConcession = jasmine.createSpy('getConcession');
  getAuthLoginUrl = jasmine.createSpy('getAuthLoginUrl');
  getCurrentPathUrl = jasmine.createSpy('getCurrentPathUrl');
  getCdiUrl = jasmine.createSpy('getCdiUrl');
  savePreference = jasmine.createSpy('savePreference');
  computeUserLocale = jasmine.createSpy('computeUserLocale');
  getPreference = jasmine.createSpy('getPreference');
  isAuthenticated = jasmine.createSpy('isAuthenticated');
  hasCityScanAccount = jasmine.createSpy('hasCityScanAccount');
  has360learningAccount = jasmine.createSpy('has360learningAccount');
  hasPlusSimpleAccount = jasmine.createSpy('hasPlusSimpleAccount');
  logout = jasmine.createSpy('logout');
  isTokenExpired = jasmine.createSpy('isTokenExpired');
}
