export enum TopbarEnvironment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  STAGINGAWS = 'stagingAws',
  PRODAWS = 'productionAws',
  DEMO = 'demo',
  TESTING = 'testing',
  TESTINGAWS = 'testingAws',
  DEV = 'dev',
  DEVAWS = 'devAws',
  DEVFINANCEAWS = 'devFinanceAws',
  DEVDISCOVERAWS = 'devDiscoverAws',
  DEVSTEERAWS = 'devSteerAws',
  DEVADVERTISEAWS = 'devAdvertiseAws',
  DEVCONVINCEAWS = 'devConvinceAws',
  DEVMATCHAWS = 'devMatchAws',
  DEVGROWAWS = 'devGrowAws',
  LOCAL = 'local',
  P1 = 'p1',
  P2 = 'p2',
  P3 = 'p3',
  P4 = 'p4',
  P5 = 'p5',
  P6 = 'p6',
  P7 = 'p7',
  P8 = 'p8',
  P9 = 'p9',
  P10 = 'p10',
  P11 = 'p11',
  P12 = 'p12',
  P13 = 'p13',
  P14 = 'p14',
  P15 = 'p15'
}
