import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../../material.module';
import { NotificationComponent } from './notification/notification.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { NotificationsSidenavComponent } from './notifications-sidenav.component';

@NgModule({
  imports: [CommonModule, TranslateModule.forChild(), MaterialModule],
  declarations: [NotificationsSidenavComponent, NotificationComponent, NotificationsListComponent],
  exports: [NotificationsSidenavComponent]
})
export class NotificationsSidenavModule {}
