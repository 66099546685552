import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Reference } from '../../models/references/references.interface';
import * as ReferencesActions from './references.actions';

export const adapter = createEntityAdapter<Reference>();

export interface ReferencesState {
  references: { [slug: string]: EntityState<Reference> };
  loading: boolean;
  error: string;
}

const initialState: ReferencesState = {
  references: {},
  loading: false,
  error: ''
};

const referencesReducer = createReducer(
  initialState,
  on(ReferencesActions.getReferences, state => ({ ...state, loading: true, error: '' })),
  on(ReferencesActions.getReferencesSuccess, (state, { references }) => ({
    ...state,
    loading: false,
    references: Object.keys(references).reduce(
      (newReferences: { [key: string]: EntityState<Reference> }, refKey: string) => {
        newReferences[refKey] = adapter.addAll(references[refKey], adapter.getInitialState());
        return newReferences;
      },
      { ...state.references }
    )
  })),
  on(ReferencesActions.getReferencesFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ReferencesActions.getReferencesFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ReferencesActions.getReferencesV2, state => ({ ...state, loading: true, error: '' })),
  on(ReferencesActions.getReferencesV2Success, (state, { references }) => ({
    ...state,
    loading: false,
    references: Object.keys(references).reduce(
      (newReferences: { [key: string]: EntityState<Reference> }, refKey: string) => {
        newReferences[refKey] = adapter.addAll(references[refKey], adapter.getInitialState());
        return newReferences;
      },
      { ...state.references }
    )
  })),
  on(ReferencesActions.getReferencesFailure, (state, { error }) => ({ ...state, loading: false, error })),
);

/**
 * Reference reducer
 * @param state - reference state
 * @param action - reference action
 */
export function reducer(state: ReferencesState | undefined, action: Action) {
  return referencesReducer(state, action);
}
