import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { RatingConstants } from './rating.constants';

export enum StarEnum {
  FILLED,
  HALF,
  EMPTY
}

@Component({
  selector: 'iad-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RatingComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingComponent implements OnInit, OnChanges, ControlValueAccessor {
  StarEnum = StarEnum;

  @Input() max: number;
  @Input() color: string;
  @Input() fontSize: number;

  _value: number;
  stars: Array<StarEnum>;
  readonly: boolean;

  onChange: (value: number) => void;
  onTouched: () => void;

  get value(): number {
    return this._value;
  }

  @Input('value')
  set value(value: number) {
    this._value = value;
    this.onChange(value);
    this.onTouched();
    this.fillStars();
    this.cd.markForCheck();
  }

  constructor(private readonly cd: ChangeDetectorRef) {
    this.onChange = () => {};
    this.onTouched = () => {};
  }

  /**
   * Life cycle OnChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.max && changes.max.previousValue !== changes.max.currentValue) {
      this.fillStars();
    }
  }

  /**
   * Life cycle OnInit
   */
  ngOnInit(): void {
    this.max = this.max || RatingConstants.DEFAULT_MAX_STARS;
    this.fontSize = this.fontSize || RatingConstants.DEFAULT_FONT_SIZE;
    this.fillStars();
  }

  /**
   * Method to fill stars.
   */
  fillStars(): void {
    this.stars = new Array(this.max).fill(StarEnum.EMPTY).map((_, i) => this.getStarEnumWithinIndex(i));
  }

  /**
   * Method that handles the click event on star.
   * @param index - The index of star.
   */
  onClick(index: number): void {
    if (!this.readonly) {
      this.value = ++index;
    }
  }

  /**
   * We implement this method to keep a reference to the onChange callback function passed by the forms API
   */
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  /**
   * We implement this method to keep a reference to the onTouched callback function passed by the forms API
   * @param fn - OnTouched function
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * This is a basic setter that the forms API is going to use
   * @param value - value to write (nb stars selected)
   */
  writeValue(value): void {
    if (value) {
      this.value = value;
    }
  }

  /**
   * Set stars in readonly mode.
   * @param isDisabled - form control disabled state
   */
  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.cd.markForCheck();
  }

  /**
   * Return a StarEnum within index and local value.
   * @param index - star index
   */
  private getStarEnumWithinIndex(index: number): StarEnum {
    if (index < this.value && index + 1 > this.value) {
      return StarEnum.HALF;
    } else if (index < this.value) {
      return StarEnum.FILLED;
    } else {
      return StarEnum.EMPTY;
    }
  }
}
