import { CommonModule } from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../material.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ContentComponent } from './content/content.component';
import { Error403Component } from './errors/error403/error403.component';
import { Error403BlockedAgentComponent } from './errors/error403BlockedAgent/error403-blocked-agent.component';
import { Error404Component } from './errors/error404/error404.component';
import { MenuModule } from './menu/menu.module';
import { NotificationsSidenavModule } from './notifications-sidenav/notifications-sidenav.module';
import { RedirectComponent } from './redirect/redirect.component';
import { SystemContentComponent } from './system-content/system-content.component';
import { TopbarModule } from './topbar/topbar.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TopbarModule,
    MenuModule,
    NotificationsSidenavModule,
    RouterModule,
    TranslateModule.forChild()
  ],
  exports: [
    TopbarModule,
    MenuModule,
    NotificationsSidenavModule,
    ContentComponent,
    SystemContentComponent,
    Error403Component,
    Error404Component,
    Error403BlockedAgentComponent,
    RedirectComponent,
    BreadcrumbComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  entryComponents: [ContentComponent, SystemContentComponent, Error403Component, Error403BlockedAgentComponent,Error404Component, RedirectComponent, BreadcrumbComponent],
  declarations: [ContentComponent, SystemContentComponent, Error403Component, Error403BlockedAgentComponent,Error404Component, RedirectComponent, BreadcrumbComponent]
})
export class LayoutsModule {}
