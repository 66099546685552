export class MockApiService {
  get = jasmine.createSpy('get');
  post = jasmine.createSpy('post');
  put = jasmine.createSpy('put');
  patch = jasmine.createSpy('patch');
  delete = jasmine.createSpy('delete');
  getApi = jasmine.createSpy('getApi');
  prepareQuery = jasmine.createSpy('prepareQuery');
  prepareOptions = jasmine.createSpy('prepareOptions');
}
