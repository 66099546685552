import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';

/**
 * This component can be used to create an overlay layout in a component
 * opened into a `MatBottomSheet` for example.
 */
@Component({
  selector: 'iad-overlay-layout',
  templateUrl: './overlay-layout.component.html',
  styleUrls: ['./overlay-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'iad-overlay-layout' }
})
export class OverlayLayoutComponent implements OnInit {

  /** Emits when the user click on the cross button. */
  @Output() dismiss: EventEmitter<void>;

  /** The `#body` template reference where some content is projected. */
  @ViewChild('body', { static: true }) bodyElement: ElementRef<HTMLDivElement>;

  /** Whether the user has scrolled into the `#body` div element. */
  hasScrolled$: Observable<boolean>;

  constructor() {
    this.dismiss = new EventEmitter();
  }

  /**
   * On initialization, define the `hasScrolled$` observable
   * used to trigger the divider display.
   */
  ngOnInit() {
    this.hasScrolled$ = this.getBodyScrollEvent().pipe(
      pluck('target'),
      map((target: HTMLDivElement) => target.scrollTop > 0),
      distinctUntilChanged()
    );
  }

  /**
   * Returns an observable that emits when the user scroll on body.
   */
  getBodyScrollEvent(): Observable<Event> {
    return fromEvent(this.bodyElement.nativeElement, 'scroll');
  }
}
