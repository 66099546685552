import { FormGroup } from '@angular/forms';
import { createSelector } from '@ngrx/store';

import * as fromCashingReducer from '../reducers/cashing.reducer';
import { selectCashingState } from '..';
import { PaginatedResult } from '../../../../../shared/models/paginated-result.interface';
import { Cashing, PaymentTransactionActions } from '../../../models/cashing.interface';

/**
 * payment Requests selectors
 */
export const selectCashingList = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): PaginatedResult => state.cashingList
);

export const selectCashingListError = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): any => state.errorGetCashing
);

export const selectCashingListLoading = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.loadingCashing
);

export const selectCashingNeedReload = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.needReload
);

export const selectPatchCashingLoading = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.loadingPatch
);

export const selectCashingInvoices = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): PaginatedResult => state.cashingInvoices
);

export const selectCashingInvoicesError = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): any => state.errorGetCI
);

export const selectCashingInvoicesLoading = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.loadingCI
);

export const selectCashingScores = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): PaginatedResult => state.cashingInvoicesWithScores
);

export const selectCashingScoresError = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): any => state.errorGetCIS
);

export const selectCashingScoresLoading = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.loadingCIS
);

export const selectCashingById = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): Cashing => state.cashing
);

export const selectCashingByIdError = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): any => state.errorGetCashingById
);

export const selectCashingByIdLoading = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.loadingCashingById
);

export const selectInvoiceLinked = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): Array<number> => state.invoicesLinked
);

export const selectInvoiceLinkedError = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): any => state.errorLinking
);

export const selectCashingForm = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): Record<string, any> => state.cashingForm
);

export const selectCashing = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): Cashing => state.cashing
);

export const selectLoadingAddCashing = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.loadingAddCashing
);

export const selectLoadingUpdateCashing = createSelector(
    selectCashingState,
    (state: fromCashingReducer.State): boolean => state.loadingUpdateCashing
  );

export const selectLoadingDeleteCashing = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.loadingDeleteCashing
);

export const selectDeleteCashing = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.deleteCashing
);

export const selectCashings = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): Array<Cashing> => state.cashings
);

export const selectLoadingValidateCashing = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.loadingValidateCashing
);

export const selectLoadingDeleteCheck = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.loadingDeleteCheck
);

export const selectErrorDeleteCheck = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.errorDeleteCheck
);

export const isLinking = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.linking
);

export const getLoadingDeleteLinkingInvoiceBank = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.loadingDeleteLinkingInvoiceBank
);

export const getErroDeleteLinkingInvoiceBank = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.errorDeleteLinkingInvoiceBank
);

export const getPaymentTransactionActionSelector = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): PaymentTransactionActions => state.cashingActions
);

export const getPaymentTransactionActionLoadingSelector = createSelector(
  selectCashingState,
  (state: fromCashingReducer.State): boolean => state.cashingActionsLoading
);
