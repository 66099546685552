<iad-overlay-layout (dismiss)="confirmValidationOnCloseOverlay()">
  <h1 iad-overlay-layout-title>
    {{ 'approximation.createCashingOverlay.toptitle' | translate }}
  </h1>
  <div fxLayout="column" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutGap="10px">
      <iad-create-cashing *ngFor="let i of cashingsForm.controls;let index = index"
                          [deletable]="cashingsForm.length > 1"
                          [index]="index"
                          [cashingForm]="cashingsForm.at(index)"
                          [disableDeleteCashing]="deleteCashingLoading$ | async"
                          (delete)="deleteCashing($event)"
                          (save)="saveCashing($event, index)"
                          (update)="updateCashing($event)">
      </iad-create-cashing>
    </div>

    <div fxLayout fxLayoutGap="20px">
      <button mat-button (click)="addCashing()" color="primary" fxLayoutAlign="start">
        {{ 'approximation.createCashingOverlay.addCashingButton' | translate }}
      </button>
      <button mat-button (click)="validateCashings()" [disabled]="loadingValidateCashing$ | async" color="primary" fxLayoutAlign="start">
        {{ 'approximation.createCashingOverlay.saveLeaveCashingButton' | translate }}
      </button>
    </div>
  </div>
</iad-overlay-layout>
