import { ComponentType } from '@angular/cdk/portal';
import { MatDialogConfig } from '@angular/material/dialog';
import { Action } from '@ngrx/store';

import { FULLSCREEN_DIALOG } from './../../components/dialogs/config/fullscreen';
import { MatSpinnerMode } from '../material';

export const OverlayDialogConfig = {
  id: 'iadOverlayDialog',
  ...FULLSCREEN_DIALOG,
  panelClass: 'iad-overlay',
  autoFocus: false
};

export type ActionFn = (result: string | boolean | undefined) => Action;

export interface OpenDialogPayload<T> {
  component: ComponentType<T>;
  config?: MatDialogConfig;
}

export interface OpenClassicDialogPayload {
  title?: string;
  content?: string;
  afterCloseAction: Action | null;
}

export interface CloseDialogPayload {
  dialogId: string;
  afterCloseAction: Action | null;
}

export interface CloseDialogResponse {
  dialogId: string;
  dialogResult: string | boolean | undefined;
}

export interface AlertDialogPayload {
  title?: string;
  content?: string;
  confirmBtn?: string;
}

export interface LoadingDialogPayload {
  content: string;
  mode?: MatSpinnerMode;
  loadingTime?: number;
}
