import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { User } from '../models/user/user.interface';
import * as RouterActions from '../store/router/router.actions';
import { UserState } from '../store/user';
import * as fromUserSelectors from '../store/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanLoad {
  constructor(private store: Store<UserState>) {}

  /**
   * Can activate method. Used to check if a route can be crossed.
   * @param next - next route handler
   */
  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkRoles(next.data.roles);
  }

  /**
   * Can load method. Used to check if a module can be loaded.
   * @param route - Route object
   */
  canLoad(route: Route): Observable<boolean> {
    return this.checkRoles(route.data.roles);
  }

  /**
   * Check if roles provided from router data match with user roles.
   * @param requiredRoles - Array of roles
   */
  private checkRoles(requiredRoles: Array<string>): Observable<boolean> {
    return this.store.pipe(
      select(fromUserSelectors.selectUser),
      filter((user: User) => !!user),
      map((user: User) => (requiredRoles || []).every((role: string) => (user.roles || []).includes(role))),
      tap((accessGranted: boolean) => !accessGranted && this.store.dispatch(RouterActions.go({ path: ['/403'] }))),
      take(1)
    );
  }
}
