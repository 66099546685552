import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { MatSpinnerMode, MatThemePalette } from './../../../models/material/material.enum';
import { LoaderComponentConstants } from './loader.component.constants';

@Component({
  selector: 'iad-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnChanges {
  @Input() color: MatThemePalette;
  @Input() mode: MatSpinnerMode;
  @Input() value: number;
  @Input() diameter: number;
  @Input() strokeWidth: number;
  @Input() background: boolean;
  @Input() counterClockwise: boolean;
  @Input() label: string;
  @Input() labelSize: number;
  labelSubject: BehaviorSubject<string>;
  label$: Observable<string>;

  constructor() {
    this.color = this.color || MatThemePalette.PRIMARY;
    this.mode = this.mode || MatSpinnerMode.INDETERMINATE;
    this.value = this.value || 0;
    this.diameter = this.diameter || LoaderComponentConstants.DEFAULT_DIAMETER;
    this.background = this.background || false;
    this.counterClockwise = this.counterClockwise || false;
    this.labelSubject = new BehaviorSubject('');
    this.label$ = this.labelSubject.asObservable();
  }

  /**
   * Life cycle onChanges
   * @param changes - changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.label || changes.value) {
      this.labelSubject.next(this.getLabel());
    }
    if (changes.strokeWidth) {
      this.strokeWidth = this.getStrokeWidth();
    }
  }

  /**
   * Returns the label with a specified percentage value (relative to the `value` binding)
   * if the string contains the template value `%percentage%`.
   *
   * @returns The label string.
   */
  getLabel(): string {
    if (!this.label) { return null; }
    return !isNaN(this.value) && this.label.includes(LoaderComponentConstants.PERCENTAGE_TEMPLATE_VALUE)
      ? this.label.replace(LoaderComponentConstants.PERCENTAGE_TEMPLATE_VALUE, `${this.value}%`)
      : this.label;
  }

  /**
   * Get a stroke width value even if the stroke width is incorrect or has not been defined.
   *
   * @returns The stroke width value in px.
   */
  getStrokeWidth(): number {
    return this.strokeWidth || LoaderComponentConstants.DEFAULT_STROKE_WIDTH;
  }

}
