import { Component, OnInit, OnDestroy, HostListener, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material';
import { Store, select } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { skipUntil, takeUntil, filter } from 'rxjs/operators';
import { get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DialogService } from '@iad-digital/iad-ngx-core';

import * as fromCashingReducers from '../../shared/store/cashing/reducers';
import { Cashing } from '../../shared/models/cashing.interface';
import {
  AddCashingAction,
  UpdateCashingAction,
  DeleteCashingAction,
  ValidateCashingAction
} from '../../shared/store/cashing/actions/cashing.actions';
import {
  selectCashing,
  selectLoadingAddCashing,
  selectDeleteCashing,
  selectLoadingDeleteCashing,
  selectLoadingValidateCashing,
  selectLoadingUpdateCashing
} from '../../shared/store/cashing/selectors/cashing.selectors';
import { CustomValidatorsService } from '../../../shared/services/custom-validators.service';

@Component({
  selector: 'iad-create-cashings-overlay',
  templateUrl: './create-cashings-overlay.component.html'
})
export class CreateCashingsOverlayComponent implements OnInit, OnDestroy {
  cashingsForm: FormArray;
  selectedIndexForm: number;
  unsubscribe$: Subject<void>;
  loadingAddCashing$: Observable<boolean>;
  deleteCashingLoading$: Observable<boolean>;
  loadingValidateCashing$: Observable<boolean>;
  shouldTriggerValidateAction: boolean;
  lastFormIsUnsavedOnAdd: boolean;
  loadingUpdateCashing$: Observable<boolean>;
  loadingCashing$: Observable<boolean>;
  isUpdatedCashingAction: boolean;

  constructor(
    private bottomSheet: MatBottomSheetRef,
    private store: Store<fromCashingReducers.State>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private customValidatorsService: CustomValidatorsService,
    private dialogService: DialogService,
    private elementRef: ElementRef
  ) {
    this.unsubscribe$ = new Subject<void>();
    this.shouldTriggerValidateAction = false;
    this.lastFormIsUnsavedOnAdd = false;
    this.isUpdatedCashingAction = false;
  }

  /**
   * OnInit hook.
   */
  ngOnInit(): void {
    this.initForm();
    this.loadingAddCashing$ = this.store.pipe(select(selectLoadingAddCashing));
    this.loadingUpdateCashing$ = this.store.pipe(select(selectLoadingUpdateCashing));
    this.loadingCashing$ = this.loadingAddCashing$;
    this.disableSavedCashingForm();
    this.deleteCashingLoading$ = this.store.pipe(select(selectLoadingDeleteCashing));
    this.removeFormOnDeleteCashing();
    this.loadingValidateCashing$ = this.store.pipe(select(selectLoadingValidateCashing));
  }

  /**
   * Watch `beforeunload` browser event.
   * Show the customized iad confirmation dialog after native browser confirmation dialog in leaving the page
   * @param event - Event of unloaded document.
   */
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: Event): void {
    this.confirmValidationOnCloseOverlay();
    event.preventDefault();
    event.returnValue = false;
  }

  /**
   * Watch `click` document event.
   * Shows the customized iad confirmation dialog when changing the page
   * @param event - Event of click on document.
   */
  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)
        && get(event, 'srcElement.className').includes('cdk-overlay-backdrop')) {
      this.confirmValidationOnCloseOverlay();
    }
  }

  /**
   * Confirm validation on close overlay.
   */
  confirmValidationOnCloseOverlay() {
    this.dialogService
      .openConfirmDialog(
        this.translate.instant('approximation.createCashingOverlay.closeCashings.dialog.title'),
        this.translate.instant('approximation.createCashingOverlay.closeCashings.dialog.content'),
        this.translate.instant('approximation.createCashingOverlay.closeCashings.dialog.yes'),
        this.translate.instant('approximation.createCashingOverlay.closeCashings.dialog.no')
      )
      .subscribe(({ dialogResult }) => {
        dialogResult && this.saveAndLeave();
      });
  }

  /**
   * Create cashing form.
   */
  createCashingForm(): FormGroup {
    return this.fb.group(
      {
        id: null,
        srcName: ['', Validators.maxLength(255)],
        checkDate: null,
        srcBankName: ['', Validators.maxLength(255)],
        checkNumber: [null, Validators.pattern('^[0-9]*$')],
        amount: [null, Validators.pattern('^[0-9]{1,10}(\\,[0-9]{1,2})?$')],
        comment: ['', Validators.maxLength(255)],
        isEdit: false
      },
      {
        validators: [this.customValidatorsService.customAmountValidation('amount')]
      }
    );
  }

  /**
   * Initialize the form.
   */
  initForm(): void {
    this.cashingsForm = this.fb.array([this.createCashingForm()]);
  }

  /**
   * Format data from the form before sending it to the server.
   */
  formatDataBeforeValidateForm(cashingForm: FormGroup): Cashing {
    const { checkDate, ...formValue } = cashingForm.value;
    const amount = parseFloat(formValue.amount.replace(',', '.').replace(' ', ''));
    return {
      ...formValue,
      amount,
      receivedAt: moment(new Date()).format('YYYY-MM-DD'),
      checkedAt: moment(checkDate).format('YYYY-MM-DD'),
      transactionType: 0
    };
  }

  /**
   * Add another form when last form is valid.
   */
  addFormOnAddCashing() {
    !this.isUpdatedCashingAction && this.lastFormIsUnsavedOnAdd && this.cashingsForm.push(this.createCashingForm());
    this.lastFormIsUnsavedOnAdd = false;
  }

  /**
   * Add cashing card.
   */
  addCashing(): void {
    const invalidForms = this.cashingsForm.controls
      .filter(form => !form.valid && !get(form, 'value.id'))
      .map(form => form);
    const lastFormCashing = this.cashingsForm.at(this.cashingsForm.length - 1);
    if (invalidForms.length === 0) {
      this.lastFormIsUnsavedOnAdd = lastFormCashing.valid && !get(lastFormCashing, 'value.id');
      !this.lastFormIsUnsavedOnAdd && !this.isUpdatedCashingAction && this.cashingsForm.push(this.createCashingForm());
    }
    if (lastFormCashing.valid && (!get(lastFormCashing, 'value.id') || !get(lastFormCashing, 'value.isEdit')) ) {
      this.saveCashing(lastFormCashing as FormGroup, this.cashingsForm.length - 1);
      this.lastFormIsUnsavedOnAdd = true;
    }
  }

  /**
   * Remove form on delete cashing.
   */
  removeFormOnDeleteCashing(): void {
    this.store
      .pipe(
        select(selectDeleteCashing),
        skipUntil(this.deleteCashingLoading$.pipe(filter((loading: boolean) => !loading))),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((isDeletedCashing: boolean) => {
        if (isDeletedCashing) {
          this.cashingsForm.removeAt(this.selectedIndexForm);
        }
      });
  }

  /**
   * Delete cashing from the listed ones in overlay.
   *
   * @param cashingFormValue - Cashing form value.
   */
  deleteCashing(cashingFormValue: { cashing: Cashing, index: number }): void {
    this.selectedIndexForm = cashingFormValue.index;
    const cashingId = get(cashingFormValue, 'cashing.id');
    if (cashingId) {
      this.store.dispatch(new DeleteCashingAction(cashingId));
    } else {
      this.cashingsForm.removeAt(this.selectedIndexForm);
    }
  }

  /**
   * Update a cashing.
   *
   * @param index - index form group
   */
  updateCashing(index: number): void {
    const cashingForm = this.cashingsForm.at(index);
    cashingForm.enable();
    cashingForm.patchValue({ isEdit: false });
    this.isUpdatedCashingAction = true;
  }

  /**
   * Disable saved cashing form.
   */
  disableSavedCashingForm(): void {
    this.store
      .pipe(
        select(selectCashing),
        skipUntil(this.loadingCashing$.pipe(filter((loading: boolean) => !loading))),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((cashing: Cashing) => {
        if (cashing && cashing.id) {
          const cashingForm = this.cashingsForm.at(this.selectedIndexForm);
          cashingForm.patchValue({ id: cashing.id, isEdit: true });
          cashingForm.disable();
          this.isUpdatedCashingAction = false;
          if (this.shouldTriggerValidateAction) {
            this.validateCashings();
            this.shouldTriggerValidateAction = false;
          } else {
            this.addFormOnAddCashing();
          }
        }
      });
  }

  /**
   * Submit form to create cashing.
   *
   * @param cashingForm - cashing form.
   * @param index - index form group
   */
  saveCashing(cashingForm: FormGroup, index: number): void {
    if (cashingForm.valid) {
      this.selectedIndexForm = index;
      if (get(cashingForm, 'value.id') && !get(cashingForm, 'value.isEdit')) {
        this.loadingCashing$ = this.loadingUpdateCashing$;
        this.store.dispatch(new UpdateCashingAction(this.formatDataBeforeValidateForm(cashingForm)));
      } else {
        this.loadingCashing$ = this.loadingAddCashing$;
        this.store.dispatch(new AddCashingAction(this.formatDataBeforeValidateForm(cashingForm)));
      }
    }
  }

  /**
   * Get unsaved cashing forms.
   *
   * @returns list of unsaved forms.
   */

  getUnsavedCashingForms(): Array<FormGroup> {
    return this.cashingsForm.controls.filter(form => form.valid && !get(form, 'value.id')).map(form => form) as Array<
      FormGroup
    >;
  }

  /**
   * Validate the current cashings
   */
  validateCashings(): void {
    const unsavedCashing = this.getUnsavedCashingForms();
    if (unsavedCashing.length && !this.shouldTriggerValidateAction) {
      this.saveCashing(unsavedCashing[0], this.cashingsForm.length - 1);
      this.shouldTriggerValidateAction = true;
    } else if (this.cashingsForm.at(0).value.id) {
      const validatedCashings = this.cashingsForm.controls.filter(({ value }) => value.id);
      const totalCashingsAmount = validatedCashings.reduce(
        (total, { value }) => {
          const formattedAmount = value.amount
            ? value.amount.replace(',', '.')
            : null;
          return parseFloat((total + parseFloat(formattedAmount)).toFixed(2));
        },
        0
      );
      this.dialogService
        .openConfirmDialog(
          this.translate.instant('approximation.createCashingOverlay.validateCashings.dialog.title'),
          this.translate.instant('approximation.createCashingOverlay.validateCashings.dialog.content', {
            totalCashingsAmount,
            nbrCashings: validatedCashings.length
          }),
          this.translate.instant('approximation.createCashingOverlay.validateCashings.dialog.yes'),
          this.translate.instant('approximation.createCashingOverlay.validateCashings.dialog.no')
        )
        .subscribe(({ dialogResult }) => {
          dialogResult && this.saveAndLeave();
        });
    }
  }

  /**
   * Save cashing, launch scorings and leave overlay.
   */
  saveAndLeave(): void {
    const cashingsIds: Array<number> = this.cashingsForm.controls
      .filter(({ value }) => value.id)
      .map(({ value }) => value.id);
    if (cashingsIds.length) {
      this.store.dispatch(new ValidateCashingAction(cashingsIds));
    } else {
      this.bottomSheet.dismiss();
    }
  }

  /**
   * Method call when component will be destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
