import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';

import { MaterialModule } from '../../../material.module';
import { SpeedDialComponent } from './speed-dial.component';

@NgModule({
  imports: [
    CommonModule,
    EcoFabSpeedDialModule,
    MaterialModule
  ],
  declarations: [SpeedDialComponent],
  exports: [SpeedDialComponent]
})
export class SpeedDialModule {}
