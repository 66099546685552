import { Injectable } from '@angular/core';
import { replace } from 'lodash';
import { forkJoin, Observable } from 'rxjs';

import { Concession } from '../../models';
import { GeocodePagination } from '../../models/api-response/api-response.interface';
import {
  GeocodeCountry,
  GeocodeInputConfigurationRow,
  GeocodeLocality,
  GeocodeStreet
} from '../../models/geocode/geocode.interface';
import { ISOCountry } from '../../models/locality/iso-country.enum';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class GeocodeService {
  constructor(private apiService: ApiService) {}

  /**
   * Fetch geocode countries and concessions.
   *
   * @returns Observable - An Observable of geocode countries and concessions.
   */
  getCountriesAndConcessions(locale: string = null): Observable<[Array<GeocodeCountry>, Array<Concession>]> {
    const params = {
      _locale: locale
    };

    return forkJoin([
      this.apiService.get<Array<GeocodeCountry>>('wis', '/common/geocode/v2/countries', { params }),
      this.apiService.get<Array<Concession>>('wis', '/directory/concessions')
    ]);
  }

  /**
   * Fetch localities of a specific country.
   *
   * @param ISOCountry countryISOCode - An ISO country code
   * @param object searchFilters - The currently searched params
   * @returns Observable - An Observable of country localities paginated.
   */
  getCountryLocalities(countryISOCode: ISOCountry, searchFilters = {}): Observable<GeocodePagination<GeocodeLocality>> {
    const params = {
      ...searchFilters
    };

    return this.apiService.get<GeocodePagination<GeocodeLocality>>(
      'wis',
      `/common/geocode/v2/countries/${countryISOCode}/localities`,
      { params }
    );
  }

  /**
   * Fetch the region1 of a specific country from a search value.
   *
   * @param ISOCountry countryISOCode - An ISO country code
   * @param object searchFilters - The currently searched params
   * @returns Observable - An Observable of country localities paginated.
   */
  getCountryRegions1(countryISOCode: ISOCountry, searchFilters = {}): Observable<GeocodePagination<GeocodeLocality>> {
    const params = {
      ...searchFilters
    };

    return this.apiService.get<GeocodePagination<GeocodeLocality>>(
      'wis',
      `/common/geocode/v2/countries/${countryISOCode}/region1s`,
      { params }
    );
  }

  /**
   * Fetch the region2 of a specific country from a search value.
   *
   * @param ISOCountry countryISOCode - An ISO country code
   * @param object searchFilters - The currently searched params
   * @returns Observable - An Observable of country localities paginated.
   */
  getCountryRegions2(countryISOCode: ISOCountry, searchFilters = {}): Observable<GeocodePagination<GeocodeLocality>> {
    const params = {
      ...searchFilters
    };

    return this.apiService.get<GeocodePagination<GeocodeLocality>>(
      'wis',
      `/common/geocode/v2/countries/${countryISOCode}/region2s`,
      { params }
    );
  }

  /**
   * Fetch the region3 of a specific country from a search value.
   *
   * @param ISOCountry countryISOCode - An ISO country code
   * @param object searchFilters - The currently searched params
   * @returns Observable - An Observable of country localities paginated.
   */
  getCountryRegions3(countryISOCode: ISOCountry, searchFilters = {}): Observable<GeocodePagination<GeocodeLocality>> {
    const params = {
      ...searchFilters
    };

    return this.apiService.get<GeocodePagination<GeocodeLocality>>(
      'wis',
      `/common/geocode/v2/countries/${countryISOCode}/region3s`,
      { params }
    );
  }

  /**
   * Fetch the region4 of a specific country from a search value.
   *
   * @param ISOCountry countryISOCode - An ISO country code
   * @param object searchFilters - The currently searched params
   * @returns Observable - An Observable of a paginated country localities.
   */
  getCountryRegions4(countryISOCode: ISOCountry, searchFilters = {}): Observable<GeocodePagination<GeocodeLocality>> {
    const params = {
      ...searchFilters
    };

    return this.apiService.get<GeocodePagination<GeocodeLocality>>(
      'wis',
      `/common/geocode/v2/countries/${countryISOCode}/region4s`,
      { params }
    );
  }

  /**
   * Fetch streets of a specific country from a search value.
   *
   * @param ISOCountry countryISOCode - An ISO country code
   * @param object searchFilters - The currently searched params
   * @returns Observable - An Observable of a paginated country streets.
   */
  getCountryStreets(
    countryISOCode: ISOCountry,
    searchFilters: Record<string, string> = {}
  ): Observable<GeocodePagination<GeocodeStreet>> {
    const { name: streetName, ...rest } = searchFilters;
    const params = {
      ...rest,
      ...(streetName ? { streetName } : {})
    };

    return this.apiService.get<GeocodePagination<GeocodeStreet>>(
      'wis',
      `/common/geocode/v2/countries/${countryISOCode}/streets`,
      { params }
    );
  }

  /**
   * Fetch a street of a specific country from a search value and a street ID.
   *
   * @param ISOCountry countryISOCode - An ISO country code
   * @param number streetId - A street ID
   * @param object searchFilters - The currently searched params
   * @returns Observable - An Observable of a paginated country streets.
   */
  getCountryStreet(
    countryISOCode: ISOCountry,
    streetId: number,
    searchFilters = {}
  ): Observable<GeocodePagination<GeocodeStreet>> {
    const params = {
      ...searchFilters
    };

    return this.apiService.get<GeocodePagination<GeocodeStreet>>(
      'wis',
      `/common/geocode/v2/countries/${countryISOCode}/streets/${streetId}`,
      { params }
    );
  }

  /**
   * Allow to format a geocode pattern string within a geocode locality. ex: '%postCode%, %region2'
   * @param locality - geocode locality object.
   * @param config - geocode configuration row
   * @param tokens - geocode tokens used to parse a pattern.
   */
  formatGeocodePattern(locality: GeocodeLocality, config: GeocodeInputConfigurationRow, tokens: Array<string>): string {
    let format = '';
    if (config.fieldFormatter && config.fieldFormatter.pattern) {
      format = config.fieldFormatter.pattern;
    } else {
      format = locality[config.searchField];
    }
    format = this.replaceGeocodeToken(format, tokens, locality);
    if (config.fieldFormatter && config.fieldFormatter.suffix) {
      format = `${format} ${this.replaceGeocodeToken(config.fieldFormatter.suffix, tokens, locality)}`;
    }
    // execute extra callback to add dynamic suffix
    if (config.fieldFormatter && config.fieldFormatter.suffixCb) {
      format = `${format} ${config.fieldFormatter.suffixCb(locality)}`;
    }
    return format;
  }

  /**
   * Replace geocode token in a string pattern.
   * @param input - the input pattern string.
   * @param tokens - geocode tokens used to parse a pattern.
   * @param locality - geocode locality object.
   */
  private replaceGeocodeToken(input: string, tokens: Array<string>, locality: GeocodeLocality): string {
    let format = input;
    tokens.forEach((token: string) => {
      format = replace(format, new RegExp(`%${token}%`, 'g'), locality[token]);
    });
    return format;
  }
}
