import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { SidenavMode } from '../../models';
import { Menu } from '../../models/menu/menu.interface';
import * as MenuActions from './menu.actions';

export interface MenuState extends EntityState<Menu> {
  loading: boolean;
  error: any;
  selectedItem: string;
  selectedSubmenuItem: string;
  opened: boolean;
  mode: SidenavMode;
}

export const adapter: EntityAdapter<Menu> = createEntityAdapter<Menu>();

const initialState: MenuState = adapter.getInitialState({
  loading: false,
  error: '',
  selectedItem: null,
  selectedSubmenuItem: null,
  opened: true,
  mode: SidenavMode.SIDE
});

const menuReducer = createReducer(
  initialState,
  on(MenuActions.getMenu, state => ({ ...state, loading: true })),
  on(MenuActions.getMenuSuccess, (state, { menuItems, selectedIds }) =>
    adapter.addAll(menuItems, {
      ...state,
      loading: false,
      ...(selectedIds && {
        selectedItem: selectedIds[0],
        selectedSubmenuItem: selectedIds[1]
      })
    })
  ),
  on(MenuActions.getMenuFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(MenuActions.selectItem, (state, { selectedItem }) => ({ ...state, selectedItem })),
  on(MenuActions.selectSubMenu, (state, { selectedSubmenuItem }) => ({
    ...state,
    selectedItem: selectedSubmenuItem[0],
    selectedSubmenuItem: selectedSubmenuItem[1]
  })),
  on(MenuActions.toggleMenu, (state, { opened }) => ({ ...state, opened })),
  on(MenuActions.toggleMenuMode, (state, { mode }) => ({ ...state, mode: mode ? SidenavMode.SIDE : SidenavMode.OVER }))
);

/**
 * User reducer
 * @param state - user state
 * @param action - user action
 */
export function reducer(state: MenuState | undefined, action: Action) {
  return menuReducer(state, action);
}
