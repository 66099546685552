import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';

import { RedirectParams, User } from '../models';
import { UrlService } from '../services/url/url.service';
import { CoreState } from '../store';
import * as RouterActions from '../store/router/router.actions';
import * as fromUserSelectors from '../store/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(private store: Store<CoreState>, private urlService: UrlService) {}

  /**
   * CanActivate method used to check if we can redirect to an external url.
   *
   * @param next - The next activated route snapshot.
   * @param state - The current router state snapshot.
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!next.data || !next.data.redirect) {
      return of(false);
    }
    return this.getRedirectURL(next.data.redirect, next.params).pipe(
      tap((url: string): void => this.store.dispatch(RouterActions.goOutside({ url }))),
      mapTo(false)
    );
  }

  /**
   * This method return the fresh formatted url to redirect. This the heart of the beast.
   *
   * @param rootKeyUrl - The api key use to retrieve base path url with url service.
   * @param url - The url endpoint.
   * @param paramsId - The collection of key params to replace.
   * @param routeParams - The params values from current activated route snapshot.
   */
  getRedirectURL({ rootKeyUrl, url, paramsId }: RedirectParams, routeParams: Params): Observable<string> {
    return iif(
      () => !rootKeyUrl && !paramsId,
      of(url),
      this.getParamsValues(paramsId, routeParams).pipe(
        map((values: Record<string, any>): string =>
          this.getRawURL(rootKeyUrl, url).replace(
            this.getParamsPattern(paramsId),
            this.getParamsReplacer(paramsId, values)
          )
        )
      )
    );
  }

  /**
   * Extract values from the activated route snapshot. In case we have the concession flag
   * to replace, we need to retrieve the value from the store.
   *
   * @param redirectParams - The collection of key params to replace.
   * @param routeParams - The params values from current activated route snapshot.
   */
  getParamsValues(redirectParams: Array<string>, routeParams: Params): Observable<Record<string, any>> {
    return iif(
      (): boolean => redirectParams && redirectParams.indexOf('concession') !== -1,
      this.store.select(fromUserSelectors.selectUser).pipe(
        map((user: User): Record<string, any> => ({ ...routeParams, concession: user.concession.slug }))
      ),
      of({ ...routeParams })
    );
  }

  /**
   * Get the raw external url. If the api parameter is specified, we need to use the urlService to retrieve
   * the appropriate url.
   *
   * @param api - The api key use to retrieve base path url with url service.
   * @param url - The url endpoint.
   */
  getRawURL(api: string, url: string): string {
    return api ? `${this.urlService.get(api)}/${url}` : `${url}`;
  }

  /**
   * Build the pattern used to replace the raw external url param flags.
   *
   * @param params - The collection of key params to replace.
   */
  getParamsPattern(params: Array<string>): RegExp {
    return new RegExp((params || []).map((param: string): string => `:(${param})`).join('|'), 'g');
  }

  /**
   * Replacer method factory used to replace the raw external url param flags.
   *
   * @param keys - The collection of key params to replace.
   * @param values - The params values.
   */
  getParamsReplacer(keys: Array<string>, values: Record<string, any>): (...args: Array<string>) => string {
    return (...args: Array<string>): string => {
      const captures: Array<string> = args.slice(1, keys ? keys.length + 1 : 0);
      const keyIndex = captures.findIndex((value: string): boolean => value !== undefined);
      return keyIndex !== -1 ? `${values[keys[keyIndex]]}` : ``;
    };
  }
}
