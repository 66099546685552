export enum TopbarEnum {
  BURGER = 'burger',
  SEARCH = 'search',
  SOCIAL_NETWORK = 'share',
  APP = 'app',
  RCP = 'rcp',
  CITYSCAN = 'cityscan',
  ELEARNING = '360learning',
  QUICKTOUR = 'quickTour',
  NOTIFICATIONS = 'notifications',
  WEBMAIL = 'webmail',
  LOGOUT = 'powerOff'
}
