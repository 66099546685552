import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ReferenceService } from '../../services/reference/reference.service';
import * as ReferencesActions from './references.actions';

@Injectable()
export class ReferencesEffects {
  getReferences$ = createEffect(() => this.actions$.pipe(
    ofType(ReferencesActions.getReferences),
    switchMap(({ referencesSlugs, concessionSlug, locale, filters }) =>
      this.referencesService.getReferencesByConcessionSlug(referencesSlugs, concessionSlug, locale, filters).pipe(
        map(references => ReferencesActions.getReferencesSuccess({ references })),
        catchError(error => of(ReferencesActions.getReferencesFailure({ error })))
      ))
  ));

  getReferencesV2$ = createEffect(() => this.actions$.pipe(
    ofType(ReferencesActions.getReferencesV2),
    switchMap(({ referenceName, concessionSlug }) =>
      this.referencesService.getReferenceByNameV2(referenceName, concessionSlug).pipe(
        map(references => ReferencesActions.getReferencesV2Success({ references })),
        catchError(error => of(ReferencesActions.getReferencesV2Failure({ error })))
      ))
  ));

  constructor(private actions$: Actions, private referencesService: ReferenceService) {}
}
