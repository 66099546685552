import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'iad-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {
  message: string;
  confirmLabel: string;
  cancelLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<PromptDialogComponent>) {
    this.message = '';
    this.confirmLabel = data.confirmBtn || 'common.labels.dialog.confirm';
    this.cancelLabel = data.cancelBtn || 'common.labels.dialog.cancel';
  }

  /**
   * Method to close dialog
   * @param isConfirm - isConfirm boolean
   */
  closeDialog(isConfirm: boolean): void {
    this.dialogRef.close(isConfirm ? this.message : false);
  }
}
