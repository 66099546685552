export enum NotificationLabel {
  BLOCKING = 'reference.notification_type.BLOCKING',
  IMPORTANT = 'reference.notification_type.IMPORTANT',
  INFORMATIVE = 'reference.notification_type.INFORMATIVE'
}

export interface Notifications {
  labelKey: NotificationLabel;
  label: string;
  list: Array<Notification>;
}

export interface Notification {
  concession_name: string;
  read: boolean;
  notificationId: number;
  archived: boolean;
  statusLabel: string;
  type: NotificationType;
  url: string;
  content: string;
  tag: string;
  application: string;
  dateTime: string;
}

export interface NotificationType {
  label: NotificationLabel;
  id: number;
  labelKey: string;
  enabled: boolean;
  code: string;
}

export interface NotificationReadEvent {
  id: number;
  url: string;
  read: boolean;
  typeElement: string;
}
