import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../../material.module';
import { PlaceInputComponent } from './place-input.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MaterialModule],
  declarations: [PlaceInputComponent],
  exports: [PlaceInputComponent]
})
export class PlaceInputModule {}
