import { Injectable } from '@angular/core';
import { MatTable } from '@angular/material';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { kebabCase } from 'lodash';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ExcelData, ExcelTableConfig, FileMimeType } from './../../models';

/**
 * Service that provides methods to create and download an Excel sheet.
 * If you want to download data as an Excel sheet, you have to format
 * this data into an `ExcelData` object containing defined columns and rows.
 *
 * For example, if we want to export an Excel sheet containing only user
 * names and ids, we have to do this way :
 *
 * ```
 * this.excelService.exportData('My custom sheet', {
 *   columns: [
 *     { header: '#', key: 'id' },
 *     { header: 'Name', key: 'name' }
 *   ],
 *   rows: [
 *     { id: 1, name: 'John Doe', ... },
 *     { id: 2, name: 'Jane Doe', ... },
 *     { id: 3, name: 'Jeff Bez', ... }
 *   ]
 * }).subscribe();
 * ```
 *
 * The Excel output would be like this :
 * ```
 * +------+----------------+
 * | #    | Name           |
 * +------+----------------+
 * |    1 | John Doe       |
 * +------+----------------+
 * |    2 | Jane Doe       |
 * +------+----------------+
 * |    3 | Jeff Bez       |
 * +------+----------------+
 * ```
 *
 * You can also directly export a MatTable with the `exportTable` method.
 */
@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  /**
   * Returns a kebab cased string containing the sheet name concatenated
   * with the current date and the xlsx extension.
   * @param name - The sheet name.
   * @returns The filename.
   */
  getSheetFilename(name: string): string {
    const date = new Date().toLocaleDateString();

    return `${kebabCase(name + date)}.xlsx`;
  }

  /**
   * Create an ExcelJS `Workbook` instance containing a sheet with given rows
   * and columns.
   * @param name - The sheet name.
   * @param data - The data to display in the sheet.
   * @returns The `Workbook` instance.
   */
  createSheet<T>(name: string, data: ExcelData<T>): Workbook {
    const workbook = new Workbook();

    const worksheet = workbook.addWorksheet(name);
    if (data.columns) {
      worksheet.columns = data.columns;
    }
    worksheet.addRows(data.rows);

    return workbook;
  }

  /**
   * Download an Excel sheet.
   * @param name - The sheet name.
   * @param workbook - The data to display in the sheet.
   * @returns An observable of Blob.
   */
  downloadSheet(name: string, workbook: Workbook): Observable<Blob> {
    return from(workbook.xlsx.writeBuffer()).pipe(
      map(buffer => new Blob([buffer], { type: FileMimeType.XLSX })),
      tap(blob => saveAs(blob, this.getSheetFilename(name)))
    );
  }

  /**
   * Export and download data as an Excel sheet.
   * @param name - The sheet name.
   * @param data - The data to display in the sheet.
   * @returns An observable of Blob.
   */
  exportData<T>(name: string, data: ExcelData<T>): Observable<Blob> {
    const workbook = this.createSheet(name, data);

    return this.downloadSheet(name, workbook);
  }

  /**
   * Export and download a MatTable as an Excel sheet.
   * @param name - The sheet name.
   * @param table - The MatTable to export.
   * @param config - A configuration object to add extra parameters.
   * @returns An observable of Blob.
   */
  exportTable<T>(name: string, table: MatTable<T>, config?: ExcelTableConfig): Observable<Blob> {
    const workbook = this.createSheet(name, {
      rows: this.getTableRows(table, config)
    });

    return this.downloadSheet(name, workbook);
  }

  /**
   * Returns all rendered rows from a MatTable.
   * @param table - The MatTable to export.
   * @param config - A configuration object to add extra parameters.
   * @returns A collection of string arrays.
   */
  getTableRows<T>(table: MatTable<T>, config: ExcelTableConfig = {}): Array<Array<string>> {
    return table
      ._getRenderedRows(table._headerRowOutlet)
      .concat(table._getRenderedRows(table._rowOutlet))
      .map(row =>
        Array
          .from(row.children)
          .slice(config.sliceStart, config.sliceEnd)
          .map((cell: HTMLElement) => cell.innerText)
      );
  }
}
