import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {
  /**
   * Convert amount in cents into decimal amount.
   * @param amount - the amount in cents
   * @param args - no args
   */
  transform(amount: number, args?: any): number {
    return amount / 100;
  }
}
