import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { defer, iif, Observable, of } from 'rxjs';
import { mapTo, switchMap, take } from 'rxjs/operators';
import { get, intersection } from 'lodash';

import { LocaleService } from '../services/locale/locale.service';
import { UserState } from '../store/user/user.reducer';
import { User } from '../models/user/user.interface';
import { CORE_CONFIG, CoreConfig } from '../models/core-config/core-config.interface';
import { Locale } from '../models/locality/locale.enum';
import { selectUser } from '../store/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class SystemLocaleGuard implements CanActivate {
  /**
   * Accessor to get the collection of available locale values from the CORE_CONFIG. In case of localeKeys wasn't well
   * provided, we fallback to a simple Array which contains the default locale value.
   * @returns Array<string> - The collection of available locale values.
   */
  get localeKeys(): Array<string> {
    return get(this.config, 'locale.localeKeys', [this.defaultLocale]);
  }

  /**
   * Accessor to get the user's browser locale value. We need to replace '-' by '_' because the getBrowserCultureLang()
   * method returns something like this xx-XX and actually we are working with locale formatted like this xx_XX.
   * @returns string - The user's browser locale value.
   */
  get browserLocale(): string {
    return this.translateService.getBrowserCultureLang().replace('-', '_');
  }

  /**
   * Accessor to get the default locale values from the CORE_CONFIG. In case of locale.default wasn't well provided,
   * we fallback to Locale.EN_GB as default locale value.
   * @returns string - The default locale value.
   */
  get defaultLocale(): string {
    return get(this.config, 'locale.default', Locale.EN_GB as string);
  }

  constructor(
    private translateService: TranslateService,
    private localeService: LocaleService,
    @Inject(CORE_CONFIG) private config: CoreConfig,
    private store: Store<UserState>
  ) {}

  /**
   * Here we are, the heart of the beast. We need to prevent the user to access the system pages without loading
   * translation files. In case of the user is already logged in, we do not need to set the locale value. The locale
   * value should be passed to the page through query param `locale=fr_FR`.
   * @param route - The current activated route snapshot.
   * @returns Observable<boolean> - The truthy boolean observable.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(selectUser),
      switchMap((user: User) =>
        iif(() => !!user, of(true), defer(() => this.setLocale(route.queryParams.locale)))
      ),
      take(1)
    );
  }

  /**
   * Shorthand method to set the current locale value through localeService. We are looking for locale from query param,
   * from the user's browser and finally the default value in this priority order. We take the first value found in the
   * localKeys collection.
   * @param queryLocale - The locale query param value.
   * @returns Observable<boolean> - The truthy boolean observable.
   */
  setLocale(queryLocale: string): Observable<boolean> {
    const locale = intersection([queryLocale, this.browserLocale, this.defaultLocale], this.localeKeys).shift();
    return this.localeService.setLocale(locale)
      .pipe(mapTo(true));
  }
}
