<div
  class="iad-overlay-layout-header"
  fxLayout
  fxLayoutAlign="space-between center"
>
  <ng-content select="[iad-overlay-layout-title], [iadOverlayLayoutTitle]"></ng-content>

  <button
    mat-icon-button
    class="iad-overlay-layout-header-button mr-16"
    (click)="dismiss.emit()"
  >
    <mat-icon
      fontSet="fas"
      fontIcon="fa-times"
    >
    </mat-icon>
  </button>
</div>

<mat-divider [fxShow]="hasScrolled$ | async"></mat-divider>

<div class="iad-overlay-layout-body" #body>
  <ng-content></ng-content>
</div>

<ng-content select="[iad-overlay-layout-footer], [iadOverlayLayoutFooter]"></ng-content>
