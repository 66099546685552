import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { FormArray } from '@angular/forms';
import { camelCase, clone, isArray, isObject, isString, map, mapKeys, mapValues } from 'lodash';
import { Observable } from 'rxjs';

import { LegalAgeConstants } from '../../constants/legal-age';
import { Concession, Country } from '../../models';
import { DownloadFile } from '../../models/download/download.interface';
import { WindowRef } from '../window/window.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  renderer: Renderer2;

  constructor(private http: HttpClient, private windowRef: WindowRef, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  /**
   *
   * Helper method which transform snake-case object into camel-case one.
   *
   * @param object obj - The litteral object in snake case.
   * @returns object
   */

  toCamelCase(obj) {
    if (isArray(obj)) {
      return map(obj, v => this.toCamelCase(v));
    } else if (isObject(obj)) {
      const cloneObj = mapKeys(clone(obj), (v, k) => camelCase(k));
      return mapValues(cloneObj, v => this.toCamelCase(v));
    } else {
      return obj;
    }
  }

  /**
   * Make an object deep copy.
   *
   * @param obj - object to copy
   * @return A new copied object
   */
  deepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   *
   * Helper method which retrieve files content.
   *
   * @param filePath - File path.
   * @returns the content.
   */

  getFileContent(filePath: string): Observable<string> {
    return this.http.get<string>(filePath, {
      responseType: 'text' as 'json'
    });
  }

  /**
   * Helper method to escape regexp in a string.
   *
   * @param str - string to escape regexp
   */
  escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  /**
   * Helper method to download a file.
   *
   * @param download - The download file object.
   */
  downloadFile(download: DownloadFile) {
    const url = this.windowRef.nativeWindow.URL.createObjectURL(download.file);
    const linkElement = this.renderer.createElement('a');
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', download.filename);
    const clickEvent = new MouseEvent('click', {
      view: this.windowRef.nativeWindow.window,
      bubbles: true,
      cancelable: false
    });
    linkElement.dispatchEvent(clickEvent);
    this.windowRef.nativeWindow.URL.revokeObjectURL(url);
  }

  /**
   * Helper method to retrieve the filename into content disposition string.
   *
   * @param contentDisposition - The content disposition string that contains filename.
   * @param defaultValue - The default value if no filename retrieved.
   * @returns The filename.
   */
  getFilename(contentDisposition: string, defaultValue: string = 'undefined'): string {
    const regex = /filename="([^"\\]*(?:\\.[^"\\]*)*)"/i;
    return (regex.exec(contentDisposition) || [])[1] || defaultValue;
  }

  /**
   * Helper method to return a Blob object from a base64 string.
   *
   * @param b64Data - The base64 string.
   * @param contentType - The content-type header.
   * @param sliceSize - The slice size.
   * @returns The blob.
   */
  getBlobFromBase64(b64Data: string, contentType: string = '', sliceSize: number = 512): Blob {
    // strip extra header added by the back-end
    const byteCharacters: string = atob(b64Data.replace(/data:.*;base64, /, ''));
    const byteArrays: Array<Uint8Array> = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice: string = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers: Array<number> = Array.from({ length: slice.length }, (_, k) => k).map(i =>
        slice.charCodeAt(i)
      );
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Helper method to extract iso2 from Country object or country string format.
   */
  getIsoCodeFromCountry(country: string | Country | Concession): string {
    return country && isString(country) ? country : (country as Country).isoCode;
  }

  /**
   * Helper method to clear a form Array in keeping control references.
   * @param formArray - Reactive Form array
   * @todo Remove this method when we migrate to Angular 8 (formArray.clear was added)
   */
  clearFormArray(formArray: FormArray): void {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  /**
   * Return minimum birth date
   */
  getDefaultBirthDate(concession: Concession): Date {
    const birthDate = new Date();
    birthDate.setFullYear(birthDate.getFullYear() - LegalAgeConstants.legalAge[concession.slug]);

    return birthDate;
  }
}
