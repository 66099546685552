<mat-card>
  <div fxLayoutAlign="space-between center">
    <h3 translate [translateParams]="{index: index + 1}">approximation.createcashing.toptitle</h3>
    <button class="mb-16" mat-icon-button [disabled]="disableDeleteCashing" *ngIf="deletable" (click)="deleteCashing()">
      <mat-icon fontSet="fas" fontIcon="fa-trash"></mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

    <form [formGroup]="cashingForm">
      <div class="iad-container-content-form" fxLayoutAlign="center" fxFlex="100" fxLayout="row" fxLayout.lt-lg="column"
           fxLayoutGap="20px">
        <div fxFlex="70" fxFlex.lt-lg="100" fxLayout="column" fxLayoutGap="20px" fxLayoutGap.lt-lg="50px">
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutGap.lt-md="40px">

            <mat-form-field fxFlex="24">
              <input disabled
                     matInput
                     placeholder="{{'approximation.createcashing.input.placeholder.datereceiptcheck'| translate}}" />{{receiptDate}}
            </mat-form-field>

            <mat-form-field fxFlex="24">
              <input formControlName="srcName" required matInput
                     placeholder="{{'approximation.createcashing.input.placeholder.transmitter'| translate}}"/>
            </mat-form-field>

            <mat-form-field fxFlex="24">
              <input matInput
                     [max]="maxDate"
                     formControlName="checkDate"
                     required
                     [matDatepicker]="pickerCheck"
                     placeholder="{{'approximation.createcashing.input.placeholder.checkdate'| translate}}"/>
              <mat-datepicker-toggle matSuffix [for]="pickerCheck"></mat-datepicker-toggle>
              <mat-datepicker #pickerCheck></mat-datepicker>
              <mat-error *ngIf="!cashingForm.get('checkDate').pristine && cashingForm.get('checkDate').errors">
                {{'approximation.error.msg36' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="24">
              <input matInput
                     formControlName="srcBankName"
                     required
                     placeholder="{{'approximation.createcashing.input.placeholder.bank'| translate}}" />
            </mat-form-field>

            <mat-form-field fxFlex="24">
              <input matInput
                     formControlName="checkNumber"
                     required
                     placeholder="{{'approximation.createcashing.input.placeholder.checknumber'| translate}}" />
            </mat-form-field>

            <mat-form-field fxFlex="24">
              <input  matInput
                      formControlName="amount"
                      required
                      placeholder="{{'approximation.createcashing.input.placeholder.amount'| translate}}" />
              <mat-error
                *ngIf="!cashingForm.get('amount').pristine && cashingForm.get('amount').hasError('pattern')">
                {{'approximation.error.msg37' | translate}}
              </mat-error>
              <mat-error *ngIf="!cashingForm.get('amount').pristine && cashingForm.get('amount').hasError('moreThenTreeDigits')">
                {{'approximation.error.msg39' | translate}}
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="100" fxLayout="column">
              <textarea matInput
                        formControlName="comment"
                        placeholder="{{'approximation.createcashing.input.placeholder.observation'| translate}}">
              </textarea>
            </mat-form-field>

          </div>
        </div>
      </div>
    </form>

  <mat-dialog-actions>
    <ng-container *ngIf="!cashingForm.value.isEdit; else updateButton">
      <button color="primary" [disabled]="!cashingForm.valid" mat-button (click)="submit()">
        {{'approximation.createcashing.button.save'| translate}}
      </button>
      <button color="primary" mat-button (click)="cashingForm.reset()">
        {{'approximation.createcashing.button.cancel'| translate}}
      </button>
    </ng-container>
    <ng-template #updateButton>
      <button color="primary" mat-button (click)="updateCashing()">
        {{'approximation.createcashing.button.update'| translate}}
      </button>
    </ng-template>
  </mat-dialog-actions>

</mat-card>
