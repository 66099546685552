import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';

import { Cashing } from '../../models/cashing.interface';
import { State as CashingState } from '../../store/cashing/reducers/cashing.reducer';
import * as cashingActions from './../../store/cashing/actions/cashing.actions';

@Component({
  selector: 'iad-cashing-archiving-dialog',
  templateUrl: './cashing-archiving-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CashingArchivingDialogComponent {
  /** The form group object containing the archiving reason. */
  readonly form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public cashingItem: Cashing,
    private dialogRef: MatDialogRef<CashingArchivingDialogComponent>,
    private store: Store<CashingState>,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({ archivingReason: '' });
  }

  /**
   * Submit the form to archive the cashing.
   */
  submit(): void {
    this.store.dispatch(
      new cashingActions.PatchCashingAction({
        id: this.cashingItem.id,
        archivingReason: this.form.get('archivingReason').value
      })
    );
    this.cancel();
  }

  /**
   * Cancel the current action by closing the dialog.
   */
  cancel(): void {
    this.dialogRef.close();
  }
}
