import { Action, createReducer, on } from '@ngrx/store';

import { User } from '../../models/user/user.interface';
import * as AuthActions from '../auth/auth.actions';
import * as UserActions from './user.actions';

export interface UserState {
  user: User;
  error: string;
  loading: boolean;
  locale: string; // user Locale
}

const initialState: UserState = {
  user: null,
  error: null,
  loading: false,
  locale: 'fr_FR'
};

/**
 * Find user locale
 * @param user - user Object
 */
function findUserLocale(user: User): string {
  const localPref = user.preferences ? user.preferences.find((pref: any) => pref.locale != null) : null;
  return localPref ? localPref.value as string : initialState.locale;
}

const userReducer = createReducer(
  initialState,
  on(UserActions.getUser, state => ({ ...state, loading: true, error: null })),
  on(
    UserActions.getUserSuccess,
    AuthActions.loginSuccess,
    (state, { user }) => ({
      ...state,
      user,
      loading: false,
      locale: findUserLocale(user)
    })
  ),
  on(UserActions.getUserFailure, (state, { error }) => ({ ...state, loading: false, error })),
);

/**
 * Create the user reducer
 * @param state UserState - The user state
 * @param action Action - ngrx actions
 */
export function reducer(state: UserState, action: Action) {
  return userReducer(state, action);
}
