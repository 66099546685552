<div class="iad-timepicker-dialog" *ngIf="stateObservable$ | async as state">
  <div class="iad-timepicker-dialog-header p-12" fxLayout="row nowrap" fxLayoutAlign="start center">
    <button
      mat-mini-fab
      color="primary"
      class="iad-timepicker-dialog-header-button mat-elevation-z0"
      [class.inactive]="state.clock !== clockType.HOURS"
      (click)="setHoursView()">
      {{ state.hours }}
    </button>
    <span class="px-4">:</span>
    <button
      mat-mini-fab
      color="primary"
      class="iad-timepicker-dialog-header-button mat-elevation-z0"
      [class.inactive]="state.clock !== clockType.MINUTES"
      (click)="setMinutesView()">
      {{ state.minutes }}
    </button>
  </div>

  <mat-divider class="mb-12"></mat-divider>

  <div #clockElement class="iad-timepicker-dialog-clock mx-auto">
    <div class="iad-timepicker-dialog-clock-middle"></div>
    <div
      class="iad-timepicker-dialog-clock-item"
      *ngFor="let clock of clocks; let i = index"
      [ngClass]="clock.className"
      [hidden]="state.clock !== clock.type"
      [attr.data-index]="i"
    >
      <div
        class="iad-timepicker-dialog-clock-item-hand"
        *ngFor="let point of clock.points"
        [class.active]="point.value === state[clock.type]"
        [style.transform]="point.handTransformStyle"
      >
        <div
          class="iad-timepicker-dialog-clock-item-hand-cursor"
          [style.transform]="point.cursorTransformStyle"
        >
          <ng-container *ngIf="point.visible">{{ point.value }}</ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="iad-timepicker-dialog-actions p-12" fxLayoutAlign="end start">
    <button mat-button color="primary" [mat-dialog-close]="null">
      {{ 'common.labels.dialog.cancel' | translate }}
    </button>
    <button mat-button color="primary" class="ml-8" [mat-dialog-close]="state.time">
      {{ 'common.labels.dialog.validate' | translate }}
    </button>
  </div>
</div>
