import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PoleService {
  constructor(private apiService: ApiService) {}

  /**
   * Retrieve all poles by concession slug.
   * @param concessionSlug - The concession slug.
   */
  getAllPoles(concessionSlug: string): Observable<Array<any>> {
    return this.apiService.get<Array<any>>('wis', `/directory/poles/${concessionSlug}`);
  }
}
