import { Injectable } from '@angular/core';
import * as moment_ from 'moment-timezone';

import { IadDateConstants } from './../../constants/date/iad-date.constants';

const moment = moment_;

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  /**
   * Return a formated date ignoring the timezone.
   *
   * @param date - Date to format.
   * @param format - A Moment date format (Default DD/MM/YYYY)
   *
   * @returns Formated date.
   */

  getDateNotimeZone(date: Date, format: string = IadDateConstants.EURO_SHORT()): string {
    return moment.parseZone(date).format(format);
  }
}
