import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import moment from 'moment';

import { Cashing } from '../../shared/models/cashing.interface';

@Component({
  selector: 'iad-create-cashing',
  templateUrl: './create-cashing.component.html'
})
export class CreateCashingComponent {
  @Input() index: number;
  @Input() deletable: boolean;
  @Input() cashingForm: FormGroup;
  @Input() disableDeleteCashing: boolean;
  @Output() delete: EventEmitter<{ cashing: Cashing, index: number }>;
  /*
   * This output emits cashing as a FormGroup.
  */
  @Output() save: EventEmitter<FormGroup>;
  @Output() update: EventEmitter<number>;
  receiptDate: string;
  maxDate: Date;

  constructor() {
    this.delete = new EventEmitter();
    this.save = new EventEmitter<FormGroup>();
    this.update = new EventEmitter<number>();
    this.receiptDate = moment(new Date()).format('DD/MM/YYYY');
    this.maxDate = new Date();
  }

  /**
   * Delete cashing.
   */
  deleteCashing(): void {
    this.delete.emit({ cashing: this.cashingForm.value, index: this.index });
  }

  /**
   * Update cashing.
   */
  updateCashing(): void {
    this.update.emit(this.index);
  }

  /**
   * Submit form to create cashing.
   */
  submit(): void {
    this.save.emit(this.cashingForm);
  }
}
