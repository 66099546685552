import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../../material.module';
import { PipesModule } from '../../../pipes.module';
import { UploadInputComponent } from './upload-input.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, TranslateModule.forChild(), PipesModule],
  declarations: [UploadInputComponent],
  exports: [UploadInputComponent]
})
export class UploadInputModule {}
