import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TableCellDirective } from '../../../directives/table-cell/table-cell.directive';
import { MaterialModule } from '../../../material.module';
import { ActionMenuModule } from './../action-menu/action-menu.module';
import { TableComponent } from './table.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CdkTableModule,
    ActionMenuModule,
    TranslateModule
  ],
  declarations: [TableComponent, TableCellDirective],
  exports: [TableComponent, TableCellDirective],
})
export class TableModule {}
