import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { BreadcrumbEditDynLabel } from '../../models/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  breadcrumbEdit$: Subject<BreadcrumbEditDynLabel>;

  constructor() {
    this.breadcrumbEdit$ = new Subject();
  }

  /**
   * Edit dynamic breadcrumb label
   * @param breadcrumbId - breadcrumb id
   * @param value - breadcrumb value
   */
  editLabelDyn(breadcrumbId: string, value: string): void {
    this.breadcrumbEdit$.next({ breadcrumbId, value });
  }
}
