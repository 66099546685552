import { Action, ActionReducer, ActionReducerMap, combineReducers, compose, createSelector } from '@ngrx/store';

import * as fromCashingReducer from './reducers/cashing.reducer';

import { selectFeatureCashingState } from '..';

export interface State {
  cashing: fromCashingReducer.State;
}

const reducers: ActionReducerMap<State, Action> = {
  cashing: fromCashingReducer.reducer
};

export const reducersCompose: ActionReducer<{}, Action> = compose(combineReducers)(reducers);

/**
 * reducer export
 * @param state - store state
 * @param action - actions
 */
export function reducer(state: State, action: Action): State {
  return reducersCompose(state, action) as State;
}

export const selectCashingState = createSelector(selectFeatureCashingState, (state: State) => state.cashing);
