import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter, MatDateFormats } from '@angular/material/core';

import { DatepickerInputComponent } from './components/ui/datepicker-input/datepicker-input.component';
import { DatepickerInputModule } from './components/ui/datepicker-input/datepicker-input.module';
import { DatepickerConstants } from './constants/date/datepicker.constants';

@NgModule({
  imports: [
    DatepickerInputModule
  ],
  exports: [
    DatepickerInputComponent
  ]
})
export class DatepickerModule {

  /**
   * forRoot method used to put providers in root injector.
   * Must be only called in your root module (mostly called AppModule).
   */
  static forRoot(locale: string = 'en', dateFormat: MatDateFormats = DatepickerConstants.CUSTOM_FORMAT): ModuleWithProviders {
    return {
      ngModule: DatepickerModule,
      providers: [
        { provide: MAT_DATE_LOCALE, useValue: locale },
        { provide: MAT_DATE_FORMATS, useValue: dateFormat },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }
      ]
    };
  }

  /**
   * forChild method (empty for now)
   */
  static forChild(): ModuleWithProviders {
    return {
      ngModule: DatepickerModule
    };
  }
}
