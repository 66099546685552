export interface Deactivation {
  deactivationId: DeactivationStatusId;
  createdAt: string;
  label: string;
  statusId: number;
}

export enum DeactivationStatusId {
  PENDING = 1,
  PROGRAMMED = 2,
  FINISHED = 3,
  CANCELED = 4
}
