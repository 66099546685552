import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, tap } from 'rxjs/operators';

import * as SnackbarActions from './snackbar.actions';

@Injectable()
export class SnackbarEffects {
  show$ = createEffect(() => this.actions$.pipe(
    ofType(SnackbarActions.show),
    concatMap(({ message, messageParams, action, duration, displayType, data, fireAfterCloseAction }) => this.snackbar.open(
      this.translate.instant(message, messageParams),
      action,
      { duration, panelClass: `snackbar-${displayType.toString()}` }
    ).afterDismissed().pipe(
        concatMap((res: MatSnackBarDismiss) => res.dismissedByAction || fireAfterCloseAction
          ? [data.rxAction, SnackbarActions.dismiss()]
          : [SnackbarActions.dismiss()]
        )
      ))
    )
  );

  dismiss$ = createEffect(() => this.actions$.pipe(
    ofType(SnackbarActions.dismiss),
    tap(() => this.snackbar.dismiss())
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private translate: TranslateService
  ) {}
}
