import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, pluck, skipWhile, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { CoreState, getRouterState } from '..';
import { Menu } from '../../models/menu/menu.interface';
import { NavigationService } from '../../services/menu/navigation.service';
import * as MenuActions from './menu.actions';

@Injectable()
export class MenuEffects {
  getMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.getMenu),
      take(1),
      switchMap(({ concession }) =>
        combineLatest([
          this.navigationService.getMenu(concession),
          this.store.pipe(
            select(getRouterState),
            filter(routerState => !!routerState),
            pluck('state', 'data', 'menu')
          )
        ]).pipe(
          map(([menuItems, selectedIds]: [Array<Menu>, Array<string>]) =>
            MenuActions.getMenuSuccess({ menuItems, selectedIds })
          ),
          catchError((error: any) => of(MenuActions.getMenuFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private navigationService: NavigationService,
    private store: Store<CoreState>
  ) {}
}
