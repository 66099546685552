export class MockElementRef {
  nativeElement = {
    classList: {
      add: jasmine.createSpy('add'),
      remove: jasmine.createSpy('remove'),
    },
    querySelector: jasmine.createSpy('querySelector').and.returnValue({
      focus: jasmine.createSpy('focus')
    })
  };
}
