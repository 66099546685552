import { ChangeDetectorRef, DoCheck, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, ValidatorFn } from '@angular/forms';

/**
 * This class provides some of the shared behavior that all custom control fields have, like
 * implementing `ControlValueAccessor`, merging validators, or handling error and touched statuses.
 * It also defines the basic input properties like `errors`, `hint`, `label`, and `required`.
 * Each class that extends it have to implements `writeValue` and `setDisabledState` methods
 * from `ControlValueAccessor` interface.
 * It shouldn't be instantiated directly.
 *
 * @template T - The value to push in parent control
 */
export abstract class IadExtendedCVAField<T> implements OnInit, DoCheck, ControlValueAccessor {
  /** The errors map containing labels to display according to specific error key. */
  @Input() errors: Record<string, string>;

  /** The string used as `mat-hint`. */
  @Input() hint: string;

  /** The string used as `mat-label`. */
  @Input() label: string;

  /** The string used as input placeholder. */
  @Input() placeholder: string;

  /** Whether the input is required and has an asterisk. */
  @Input() required: boolean;

  /** The registered callback function called when the control value changes. */
  onChange: (value: T) => void;

  /** The registered callback function called when the control is touched. */
  onTouched: () => void;

  /** The string used as `mat-error`. */
  error: string;

  constructor(
    public formControl: FormControl,
    protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef
  ) {
    this.onChange = (_: T) => {};
    this.onTouched = () => {};
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  /**
   * On component initialization, call the `mergeControlsValidators` method
   * if the control directive instance is defined and update error.
   */
  ngOnInit(): void {
    if (this.ngControl && this.ngControl.control) {
      this.mergeControlsValidators();
    }
    this.updateError();
  }

  /**
   * On every change, try to update touched status.
   * Whatever logic is in here has to be super lean or we risk destroying the performance.
   */
  ngDoCheck(): void {
    if (this.ngControl && this.ngControl.control) {
      // We need to re-evaluate this on every change detection cycle,
      // because we can't subscribe to a kind of "touched change event".
      this.updateTouchedStatus();
    }
  }

  /**
   * Allows Angular to update the model.
   * Must be implemented in derived classes.
   * @param value - The value changes.
   */
  abstract writeValue(value: T): void;

  /**
   * Allows Angular to disable the input.
   * Can be overridden in class which extend IadExtendedCVAField
   * @param disabled - Whether the input have to be disabled.
   */
  setDisabledState(disabled: boolean): void {
    disabled ? this.formControl.disable() : this.formControl.enable();
  }

  /**
   * Merges validator functions into a single array and calls `updateValidators` method
   * on controls by passing this array in argument to apply the same validation behavior.
   */
  protected mergeControlsValidators(): void {
    const validators: ValidatorFn[] = [this.ngControl.control.validator, this.formControl.validator].filter(Boolean);
    this.updateValidators(this.ngControl.control, validators);
    this.updateValidators(this.formControl, validators);
  }

  /**
   * Sets new validators and updates control value and validity.
   * @param control - The form control to update
   * @param validators - The validators to set
   */
  protected updateValidators(control: AbstractControl, validators: Array<ValidatorFn>): void {
    control.setValidators([...validators]);
    control.updateValueAndValidity({ emitEvent: false });
  }

  /**
   * Updates the error based on the current form control errors and
   * the binded input `errors` object that contains labels.
   * Afterwise, marks the view as changed.
   * The `errors` key ordering is important because the first error match is used.
   */
  protected updateError(): void {
    const controlErrorKeys: Array<string> = Object.keys(this.formControl.errors || {});
    const errorLabelKey: string = Object.keys(this.errors || {}).find(errorKey => controlErrorKeys.includes(errorKey));

    this.error = errorLabelKey ? this.errors[errorLabelKey] : null;
    this.cdr.markForCheck();
  }

  /**
   * Updates touched status according to parent control status.
   * For example, if the parent control has been touched programmatically,
   * we can apply the same status on our control.
   */
  protected updateTouchedStatus(): void {
    if (this.ngControl.control.touched && !this.formControl.touched) {
      this.formControl.markAsTouched();
      this.cdr.markForCheck();
    }
  }

  /**
   * Allows Angular to register a function to call when the model changes.
   * Save the function as a property to call later here.
   * @param fn - The function to register.
   */
  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  /**
   * Allows Angular to register a function to call when the input has been touched.
   * Save the function as a property to call later here.
   * @param fn - The function to register.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
