import { FormGroup } from '@angular/forms';

import { Cashing, PaymentTransactionActions } from '../../../models/cashing.interface';
import { PaginatedResult } from '../../../../../shared/models/paginated-result.interface';
import { CashingActions, CashingActionTypes } from '../actions';

export interface State {
  cashingList: PaginatedResult;
  loadingCashing: boolean;
  errorGetCashing: string;
  needReload: boolean;
  loadingPatch: boolean;
  errorPatch: any;
  cashingInvoices: PaginatedResult;
  loadingCI: boolean;
  errorGetCI: string;
  cashingInvoicesWithScores: PaginatedResult;
  loadingCIS: boolean;
  errorGetCIS: string;
  errorAddCashing: boolean;
  loadingAddCashing: boolean;
  errorUpdateCashing: string;
  loadingUpdateCashing: boolean;
  loadingDeleteCashing: boolean;
  deleteCashing: boolean;
  errorDeleteCashing: boolean;
  cashing: Cashing;
  loadingCashingById: boolean;
  errorGetCashingById: string;
  cashingActions: PaymentTransactionActions;
  cashingActionsLoading: boolean;
  cashingActionsError: any;
  invoicesLinked: Array<number>;
  linking: boolean;
  errorLinking: string;
  cashingForm: Record<string, any>;
  loadingDeleteCheck: boolean;
  errorDeleteCheck: boolean;
  loadingDeleteLinkingInvoiceBank: boolean;
  errorDeleteLinkingInvoiceBank: boolean;
  loadingMarginCheckLists: boolean;
  errorMarginCheckLists: boolean;
  marginChecklists: PaginatedResult;
  cashings: Array<Cashing>;
  errorValidateCashing: boolean;
  loadingValidateCashing: boolean;
  addCashingCommentLoading: boolean;
}

export const initialState: State = {
  cashingList: null,
  loadingCashing: false,
  errorGetCashing: null,
  needReload: false,
  loadingPatch: false,
  errorPatch: null,
  cashingInvoices: null,
  loadingCI: false,
  errorGetCI: null,
  cashingInvoicesWithScores: null,
  loadingCIS: false,
  errorGetCIS: null,
  errorAddCashing: false,
  loadingAddCashing: false,
  errorUpdateCashing: null,
  loadingUpdateCashing: false,
  loadingDeleteCashing: false,
  deleteCashing: false,
  errorDeleteCashing: false,
  cashing: null,
  loadingCashingById: false,
  errorGetCashingById: null,
  cashingActions: null,
  cashingActionsLoading: false,
  cashingActionsError: null,
  invoicesLinked: null,
  linking: false,
  errorLinking: null,
  cashingForm: null,
  loadingDeleteCheck: false,
  errorDeleteCheck: false,
  loadingDeleteLinkingInvoiceBank: false,
  errorDeleteLinkingInvoiceBank: false,
  loadingMarginCheckLists: false,
  errorMarginCheckLists: false,
  marginChecklists: null,
  cashings: null,
  errorValidateCashing: false,
  loadingValidateCashing: false,
  addCashingCommentLoading: false
};

/**
 * cashing reducer
 * @param state - store state
 * @param action - actions dispatched
 */

// tslint:disable-next-line: cyclomatic-complexity
export function reducer(state = initialState, action: CashingActions): State {
  switch (action.type) {
    case CashingActionTypes.GET_CASHING:
      return { ...state, loadingCashing: true, errorGetCashing: '' };
    case CashingActionTypes.GET_CASHING_SUCCESS:
      return { ...state, cashingList: action.payload, loadingCashing: false, errorGetCashing: '', needReload: false };
    case CashingActionTypes.GET_CASHING_ERROR:
      return { ...state, cashingList: null, loadingCashing: false, errorGetCashing: action.payload };
    case CashingActionTypes.OPEN_CASHING_CREATION_OVERLAY:
      return { ...state, cashings: null, cashing: null };
    case CashingActionTypes.PATCH_CASHING:
      return { ...state, loadingPatch: true, errorPatch: null };
    case CashingActionTypes.PATCH_CASHING_SUCCESS:
      return { ...state, needReload: true, loadingPatch: false, errorPatch: null };
    case CashingActionTypes.PATCH_CASHING_ERROR:
      return { ...state, loadingPatch: false, errorPatch: action.payload };
    case CashingActionTypes.ADD_CASHING:
      return { ...state, loadingAddCashing: true, errorAddCashing: null };
    case CashingActionTypes.ADD_CASHING_SUCCESS:
      return { ...state, cashing: action.payload, loadingAddCashing: false, errorAddCashing: false };
    case CashingActionTypes.ADD_CASHING_ERROR:
      return { ...state, cashing: null, loadingAddCashing: false, errorAddCashing: true };
    case CashingActionTypes.UPDATE_CASHING:
      return { ...state, loadingUpdateCashing: true, errorUpdateCashing: '' };
    case CashingActionTypes.UPDATE_CASHING_SUCCESS:
      return { ...state, cashing: action.payload, loadingUpdateCashing: false, errorUpdateCashing: '' };
    case CashingActionTypes.UPDATE_CASHING_ERROR:
      return { ...state, cashing: null, loadingUpdateCashing: false, errorUpdateCashing: action.payload };
    case CashingActionTypes.DELETE_CASHING:
      return { ...state, loadingDeleteCashing: true, deleteCashing: null, errorDeleteCashing: null };
    case CashingActionTypes.DELETE_CASHING_SUCCESS:
      return { ...state, loadingDeleteCashing: false, deleteCashing: true, errorDeleteCashing: false };
    case CashingActionTypes.DELETE_CASHING_ERROR:
      return { ...state, loadingDeleteCashing: false, deleteCashing: false, errorDeleteCashing: true };
    case CashingActionTypes.VALIDATE_CASHING:
      return { ...state, loadingValidateCashing: true, errorValidateCashing: null };
    case CashingActionTypes.VALIDATE_CASHING_SUCCESS:
      return { ...state, cashings: action.payload, cashing: null, loadingValidateCashing: false, errorValidateCashing: null, needReload: true };
    case CashingActionTypes.VALIDATE_CASHING_ERROR:
      return { ...state, cashings: null, cashing: null, loadingValidateCashing: false, errorValidateCashing: true };
    case CashingActionTypes.REFRESH_CASHING:
      return { ...state, cashingList: null };
    case CashingActionTypes.GET_CASHING_INVOICES:
      return { ...state, loadingCI: true, errorGetCI: '' };
    case CashingActionTypes.GET_CASHING_INVOICES_SUCCESS:
      return { ...state, cashingInvoices: action.payload, loadingCI: false, errorGetCI: '' };
    case CashingActionTypes.GET_CASHING_INVOICES_ERROR:
      return { ...state, cashingInvoices: null, loadingCI: false, errorGetCI: action.payload };
    case CashingActionTypes.GET_CASHING_WITH_SCORES:
      return { ...state, loadingCIS: true, errorGetCIS: '' };
    case CashingActionTypes.GET_CASHING_WITH_SCORES_SUCCESS:
      return { ...state, cashingInvoicesWithScores: action.payload, loadingCIS: false, errorGetCIS: '' };
    case CashingActionTypes.GET_CASHING_WITH_SCORES_ERROR:
      return { ...state, cashingInvoicesWithScores: null, loadingCIS: false, errorGetCIS: action.payload };
    case CashingActionTypes.REFRESH_CASHING_INVOICES:
      return { ...state, cashingInvoices: null, cashingInvoicesWithScores: null };
    case CashingActionTypes.GET_CASHING_BY_ID:
      return { ...state, loadingCashingById: true, errorGetCashingById: '' };
    case CashingActionTypes.GET_CASHING_BY_ID_SUCCESS:
      return { ...state, cashing: action.payload, loadingCashingById: false, errorGetCashingById: '' };
    case CashingActionTypes.GET_CASHING_BY_ID_ERROR:
      return { ...state, cashing: null, loadingCashingById: false, errorGetCashingById: action.payload };
    case CashingActionTypes.GET_PAYMENT_TRANSACTION_ACTIONS:
      return { ...state, cashingActionsLoading: true };
    case CashingActionTypes.GET_PAYMENT_TRANSACTION_ACTIONS_SUCCESS:
      return { ...state, cashingActions: action.payload, cashingActionsLoading: false };
    case CashingActionTypes.GET_PAYMENT_TRANSACTION_ACTIONS_ERROR:
      return { ...state, cashingActionsLoading: false, cashingActionsError: action.payload };
    case CashingActionTypes.LINK_CASHING_INVOICE:
      return { ...state, linking: true, errorLinking: '' };
    case CashingActionTypes.LINK_CASHING_INVOICE_SUCCESS:
      return { ...state, invoicesLinked: action.payload, linking: false, errorLinking: '' };
    case CashingActionTypes.LINK_CASHING_INVOICE_ERROR:
      return { ...state, invoicesLinked: null, linking: false, errorLinking: action.payload };
    case CashingActionTypes.LINK_CASHING_INVOICE_ERROR_DISPLAYED:
      return { ...state, invoicesLinked: null, linking: false, errorLinking: '' };
    case CashingActionTypes.DELETE_INVOICES_IDS:
      return { ...state, invoicesLinked: null };
    case CashingActionTypes.SAVE_CASHING_FORM:
      return { ...state, cashingForm: action.payload };
    case CashingActionTypes.DELETE_CHECK:
      return { ...state, loadingDeleteCheck: true, errorDeleteCheck: null };
    case CashingActionTypes.DELETE_CHECK_SUCCESS:
      return { ...state, loadingDeleteCheck: false, errorDeleteCheck: false };
    case CashingActionTypes.DELETE_CHECK_ERROR:
      return { ...state, loadingDeleteCheck: false, errorDeleteCheck: true };
    case CashingActionTypes.DELETE_LINKING_INVOICE_BANK:
      return { ...state, loadingDeleteLinkingInvoiceBank: true, errorDeleteLinkingInvoiceBank: null };
    case CashingActionTypes.DELETE_LINKING_INVOICE_BANK_SUCCESS:
      return { ...state, loadingDeleteLinkingInvoiceBank: false, errorDeleteLinkingInvoiceBank: false };
    case CashingActionTypes.DELETE_LINKING_INVOICE_BANK_ERROR:
      return { ...state, loadingDeleteLinkingInvoiceBank: false, errorDeleteLinkingInvoiceBank: true };
    case CashingActionTypes.FLUSH_PAYMENT_TRANSACTION_ACTIONS:
      return { ...state, cashingActions: null, cashingActionsLoading: false };
    case CashingActionTypes.ADD_CASHING_COMMENT_ACTION:
      return { ...state, addCashingCommentLoading: true };
    case CashingActionTypes.ADD_CASHING_COMMENT_SUCCESS_ACTION:
      return { ...state, addCashingCommentLoading: false };
    case CashingActionTypes.ADD_CASHING_COMMENT_ERROR_ACTION:
      return { ...state, addCashingCommentLoading: false };
    default:
      return state;
  }
}
