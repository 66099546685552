export class BreadCrumb {
  id: string;
  defaultLabel: string | Array<string>;
  labelDyn?: string;
  url: string;

  constructor(id: string, defaultLabel: string | Array<string>, labelDyn?: string, url: string = '') {
    this.id = id;
    this.defaultLabel = defaultLabel;
    this.labelDyn = labelDyn;
    this.url = url;
  }
}
