import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * A structural directive that renders a template used as
 * a custom cell template by the `TableComponent`.
 * The directive has to receive a `column` binding that will be
 * used to link the right template to the right column cell.
 *
 * The following example shows the shorthand syntax :
 *
 * ```
 * <div *iadTableCell="let item; column: 'myColumnKey'">...</div>
 * ```
 *
 * The shorthand form expands into a long form that uses the `iadTableCellColumn`
 * selector on an `<ng-template>` element.
 *
 * Here is the expanded version of the short-form example :
 *
 * ```
 * <ng-template iadTableCell let-item [iadTableCellColumn]="'myColumnKey">
 *   <div>...</div>
 * </ng-template>
 * ```
 */
@Directive({ selector: '[iadTableCell]' })
export class TableCellDirective<T = any> {
  @Input() iadTableCellColumn: string;

  get template(): TemplateRef<T> {
    return this.templateRef;
  }

  get column(): string {
    return this.iadTableCellColumn;
  }

  constructor(private templateRef: TemplateRef<T>) {}
}
