import { AvatarType, GreetingKey } from '../../../models';

export class AvatarConstants {
  static readonly gendersMap = new Map([
    [GreetingKey.MR, AvatarType.MALE],
    [GreetingKey.MADAM, AvatarType.FEMALE],
    [GreetingKey.LADY, AvatarType.FEMALE],
    [GreetingKey.NEUTRAL, AvatarType.NEUTRAL]
  ]);
}
