import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  Type,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty, pick } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { catchError, delay, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UploadInputConstants } from '../../../constants/upload/upload-input.constants';
import { DownloadFile } from '../../../models';
import { UploadInputConfig } from '../../../models/upload/upload-input-file.interface';
import { ApiService } from '../../../services/api/api.service';
import { UtilService } from '../../../services/util/util.service';
import { CustomValidators } from '../../../validators/custom.validator';
import { DialogConfirmComponent } from '../../dialogs';
import { WindowRef } from '../../../services/window/window.service';

const fileErrors: Array<string> = ['required', 'fileRemoteError', 'fileSizeExceeded', 'fileExtensionUnauthorized'];

@Component({
  selector: 'iad-upload-input',
  templateUrl: './upload-input.component.html',
  styleUrls: ['./upload-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UploadInputComponent),
      multi: true
    }
  ]
})
export class UploadInputComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit, ControlValueAccessor, Validator {
  @Input() config: UploadInputConfig;
  @Input() isMobile: boolean;
  @Input() placeholder: string;
  @Input() hint: string;
  @Input() customError: string;
  @Output() fileDeleted: EventEmitter<UploadInputConfig['id']>;
  @ViewChild('file', { static: false })
  fileInput: ElementRef;
  @ViewChild('fileName', { static: false })
  fileNameInput: ElementRef;

  ngControl: NgControl;
  matcher: ErrorStateMatcher;
  form: FormGroup;
  onChange: (value: File) => void;
  onTouched: () => void;
  deleteRemoteFile: Subject<void>;
  unsubscribe$: Subject<void>;
  loading: boolean;
  required: boolean;
  disabled: boolean;

  private validators: Array<ValidatorFn>;

  get value(): File {
    return this.form.get('file').value;
  }

  @Input('value')
  set value(value: File) {
    value && value.name &&
      this.form.setValue({
        file: value,
        fileName: value.name
      });
    this.onChange(value);
    this.onTouched();
  }

  constructor(
    private injector: Injector,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private apiService: ApiService,
    private utilService: UtilService,
    private translate: TranslateService,
    private windowRef: WindowRef
  ) {
    this.unsubscribe$ = new Subject();
    this.deleteRemoteFile = new Subject();
    this.fileDeleted = new EventEmitter();
    this.required = false;
    this.disabled = false;
    this.validators = [];
    this.onChange = () => {};
    this.onTouched = () => {};
    this.matcher = {
      isErrorState: () =>
        !!this.customError ||
        (!isEmpty(pick(this.form.get('fileName').errors, fileErrors)) && this.form.get('fileName').touched)
    };
  }

  /**
   * Life cycle OnChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && changes.config.currentValue) {
      const config = changes.config.currentValue;
      // skip file extensions validator if an id is provided. It means we are in an edit control workflow.
      this.validators = config.id
        ? [CustomValidators.validateFileSize(config.maxSize)]
        : [CustomValidators.validateFileSize(config.maxSize), CustomValidators.validateFileExtension(config.accept)];
      this.form && this.form.get('file').setValidators(this.validators);
    }
    this.checkForFileNameError();
  }

  /**
   * Life cycle OnInit
   */
  ngOnInit(): void {
    this.ngControl = this.injector.get<NgControl>(NgControl as Type<NgControl>, null);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.verifyConfig('You must provide a config @Input in order to make works the iad-upload-input component');
    this.config = { filePreview: true, ...this.config };
    this.form = this.fb.group({
      fileName: null,
      file: [null, this.validators]
    });
    this.initFileNameListener();
    this.initRemoteFileListener();
  }

  /**
   * Life cycle afterViewInit
   */
  ngAfterViewInit(): void {
    // handle the required validator coming from the parent control
    if (this.ngControl && this.ngControl.control && this.ngControl.control.validator) {
      const validator = this.ngControl.control.validator({} as AbstractControl);
      this.required = validator && validator.required;
      this.required && this.form.get('fileName').setValidators([Validators.required]);
      this.cd.detectChanges();
    }
  }

  /**
   * Life cycle onDestroy
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.deleteRemoteFile.complete();
  }

  /**
   * We implement this method to keep a reference to the onChange callback function passed by the forms API
   */
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  /**
   * We implement this method to keep a reference to the onTouched callback function passed by the forms API
   * @param fn - OnTouched function
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * This is a basic setter that the forms API is going to use
   * @param value - value to write
   */
  writeValue(value: File): void {
    if (value) {
      this.value = value;
    }
  }

  /**
   * This method is the one required by the Validator interface. It is used by ngControl to validate the control.
   *
   * @param control - The control to validate.
   * @return The ValidationErrors or null if the control is valid.
   */
  validate = (control: AbstractControl): ValidationErrors | null => {
    const fileName = this.form.get('fileName');
    if (this.form.invalid) {
      fileName.setErrors({ ...fileName.errors, ...this.form.get('file').errors });
      return { fileInvalid: 'file is invalid' };
    } else {
      fileName.setErrors(null);
      return null;
    }
  }

  /**
   * Delete chosen file.
   * @param event - Dom event object
   */
  deleteFile(event: MouseEvent): void {
    this.preventPropagation(event);
    this.form.get('fileName').markAsTouched();
    if (this.config.id) {
      this.dialog
        .open(DialogConfirmComponent, {
          data: {
            content: this.translate.instant('iadUploadInput.confirm.remove.message', {
              file: this.form.get('fileName').value
            })
          }
        })
        .afterClosed()
        .pipe(filter((res: boolean) => res))
        .subscribe(() => (this.config.softDelete ? this.softDeleteFile() : this.deleteRemoteFile.next()));
    } else {
      this.softDeleteFile();
    }
  }

  /**
   * Preview a file in a new tab.
   * @param event - Dom event object
   */
  previewFile(event: Event): void {
    this.preventPropagation(event);
    !this.config.previewPath
      ? this.apiService
          .get(this.config.api.key, `${this.config.api.url}/${this.config.id}`)
          .pipe(
            map((res: { name: string; data: string }) => ({
              file: this.utilService.getBlobFromBase64(res.data),
              filename: res.name
            })),
            tap((file: DownloadFile) => {
              const url = this.windowRef.nativeWindow.URL.createObjectURL(file.file);
              this.openNewTab(url);
            })
          )
          .subscribe()
      : this.openNewTab(this.config.previewPath);
  }

  /**
   * File selected in dialog.
   */
  fileSelected(files: FileList): void {
    if (files && files.length) {
      const file = files[0];
      this.config.id = null;
      this.form.get('fileName').markAsTouched();
      this.value = file;
    }
  }

  /**
   * Method to handle control state disable.
   * @param isDisabled - parent form control disable state
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.form.disable() : this.form.enable();
  }

  /**
   * Return the extensions list allowed formated for the template.
   */
  getExtensionsAllowedList(): string {
    return this.config && this.config.accept.map(extension => extension.split('/')[1]).join(', ');
  }

  /**
   * Delete file object only locally.
   */
  private softDeleteFile(): void {
    const idRemoved = this.config.id;
    this.resetFile();
    this.fileDeleted.emit(idRemoved);
  }

  /**
   * Init remote file rxjs listener.
   */
  private initRemoteFileListener(): void {
    const deleteRemoteFile$ = this.deleteRemoteFile.asObservable();
    deleteRemoteFile$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => (this.loading = true)),
        switchMap(() =>
          this.getFileDeletionObs().pipe(
            catchError(() => {
              const fileRemoteError = 'remote error';
              this.form.get('fileName').setErrors({ fileRemoteError }, { emitEvent: false });
              return of({ error: fileRemoteError });
            }),
            finalize(() => {
              this.loading = false;
              this.form.get('fileName').markAsTouched();
              this.cd.markForCheck();
            })
          )
        )
      )
      .subscribe((result: { error: string } | any) => {
        if (!result || (result && !result.error)) {
          this.softDeleteFile();
        }
      });
  }

  /**
   * Init fileName listener.
   */
  private initFileNameListener(): void {
    this.form
      .get('fileName')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        delay(0)
      )
      .subscribe((fileName: string) => {
        this.form
          .get('fileName')
          .setValue(
            this.truncateFileName(
              fileName,
              this.fileNameInput.nativeElement.scrollWidth,
              this.fileNameInput.nativeElement.clientWidth
            ),
            { emitEvent: false }
          );
        this.validate(this.form);
      });
  }

  /**
   * Reset file input value.
   */
  private resetFile() {
    this.form.reset();
    this.config.id = null;
    this.fileInput.nativeElement.value = null;
    this.value = null;
  }

  /**
   * Method to truncate filename if input is smaller than filename.
   * @param name - filename
   * @param scrollWidth - input file scrollWidth
   * @param clientWith - input file clientWidth
   */
  private truncateFileName(name: string, scrollWidth: number, clientWith: number): string {
    if (scrollWidth > clientWith) {
      const ext = UploadInputConstants.FILE_EXTENSION_REGEXP.exec(name);
      return `${name.substr(0, clientWith / UploadInputConstants.CHAR_PIXEL_RATIO)}... ${
        ext.length ? `.${ext[1]}` : ''
      }`;
    } else {
      return name;
    }
  }

  /**
   * Prevent double submission.
   * @param event - Dom event object.
   */
  private preventPropagation(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Method to call file deletion service.
   */
  private getFileDeletionObs<T>(): Observable<T> {
    const method = (this.config.api && this.config.api.method) || 'delete';
    const params = {
      params: this.config.api.params
    };
    return ['put', 'patch', 'post'].indexOf(method) !== -1
      ? this.apiService[method](
          this.config.api.key,
          `${this.config.api.url}/${this.config.id}`,
          this.config.api.body,
          params
        )
      : this.apiService[method](this.config.api.key, `${this.config.api.url}/${this.config.id}`, params);
  }

  /**
   *
   * Method to check iad upload configuration.
   * @param developerMsg - developer warning message
   */
  private verifyConfig(developerMsg: string): void {
    if (!this.config) {
      throw new Error(developerMsg);
    }
  }

  /**
   *  Extra check to fired required errors on fileName control after a touched event.
   */
  private checkForFileNameError(): void {
    if (this.ngControl && this.ngControl.control && this.ngControl.control.touched) {
      this.form.get('fileName').markAsTouched();
      this.cd.markForCheck();
    }
  }

  /**
   * Open a new tab with the inquired URL.
   */
  private openNewTab(url: string): void {
    this.windowRef.nativeWindow.open(url, '_blank');
  }
}
