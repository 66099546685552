import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import {
  ComponentsModule,
  CORE_CONFIG,
  CoreConfig,
  CoreModule,
  CoreStateModule,
  tokenGetter,
  translateLoader,
  DatepickerModule,
  DialogsModule,
  GtmHelper,
  DataDogHelper
} from '@iad-digital/iad-ngx-core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateFakeCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  CreateCashingsOverlayComponent
} from './approximation/pages/create-cashings-overlay/create-cashings-overlay.component';
import { ExportTableEffects } from './shared/store/export-table/export-table.effects';
import { CashingArchivingDialogComponent } from './approximation/shared/components/cashing-archiving-dialog/cashing-archiving-dialog.component';

/**
 * Returns an options factory object for JwtModule.
 * @param coreConfig - The `CORE_CONFIG` dependency value.
 * @returns The JwtModuleOptions configuration.
 */
export function jwtOptionsFactory(coreConfig: CoreConfig): JwtModuleOptions['config'] {
  return {
    tokenGetter,
    whitelistedDomains: coreConfig.apis
      .filter(api => api.id === 'billing' || api.id === 'production')
      .map(api => api.host)
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    DialogsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ComponentsModule,
    DatepickerModule.forRoot(),
    CoreModule,
    CoreStateModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateFakeCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoader,
        deps: [CORE_CONFIG, HttpClient]
      }
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [CORE_CONFIG]
      }
    }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([ExportTableEffects]),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : []
  ],
  entryComponents: [CreateCashingsOverlayComponent, CashingArchivingDialogComponent],
  providers: [
    { provide: LOCALE_ID, useValue: environment.locale.default }, // register default locale
    { provide: CORE_CONFIG, useValue: environment },
    GtmHelper,
    DataDogHelper
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translate: TranslateService) {
    // pipe has only access to en-US locale data by default.
    // here we want to localize the dates in another language exemple fr-FR, we have to import data for other locales.
    environment.locale.availables.forEach((l: any) => registerLocaleData(l.locale, l.name));
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(environment.locale.default);
    translate.use(environment.locale.default);
    sessionStorage.setItem('env', environment.name);
    sessionStorage.setItem('site', 'iad_digital_project_billing');
  }
}
