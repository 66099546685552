import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { AlertDialogPayload } from '../../../models';

@Component({
  selector: 'iad-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertDialogComponent {
  public confirmLabel: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogPayload,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<AlertDialogComponent>
  ) {
    this.confirmLabel = data.confirmBtn || this.translateService.instant('common.labels.dialog.confirm');
  }

  /**
   * Close dialog.
   */
  closeDialog(): void {
    this.dialogRef.close(true);
  }
}
