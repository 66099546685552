import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../material.module';
import { TopbarItemComponent } from './topbar-item/topbar-item.component';
import { TopbarComponent } from './topbar.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [TopbarComponent, TopbarItemComponent],
  exports: [TopbarComponent],
  providers: []
})
export class TopbarModule {}
