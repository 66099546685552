import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MaterialModule } from '../../../material.module';

import { PipesModule } from '../../../pipes.module';
import { GeocodeService } from '../../../services/geocode/geocode.service';
import { InputGeocodeComponent } from './input-geocode.component';
import { GEOCODE_CONFIG_V1, GEOCODE_CONFIG_V2, InputGeocodeConstants } from './input-geocode.constants';
import { GeocodeV1Strategy } from './strategy/geocode-v1-strategy.class';
import { GeocodeV2Strategy } from './strategy/geocode-v2-strategy.class';

export function geocodeConfigV1() {
  return InputGeocodeConstants.CONFIG_V1;
}
export function geocodeConfigV2() {
  return InputGeocodeConstants.CONFIG_V2;
}

@NgModule({
  imports: [CommonModule, MaterialModule, PipesModule],
  declarations: [InputGeocodeComponent],
  exports: [InputGeocodeComponent],
  providers: [
    { provide: GEOCODE_CONFIG_V1, useFactory: geocodeConfigV1 },
    { provide: GEOCODE_CONFIG_V2, useFactory: geocodeConfigV2 },
    { provide: GeocodeV1Strategy, useClass: GeocodeV1Strategy, deps: [GeocodeService, GEOCODE_CONFIG_V1] },
    {
      provide: GeocodeV2Strategy,
      useClass: GeocodeV2Strategy,
      deps: [GeocodeService, TranslateService, GEOCODE_CONFIG_V2]
    }
  ]
})
export class InputGeocodeModule {}
