import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { Menu } from '../../../models/menu/menu.interface';
import { User } from '../../../models/user/user.interface';
import { AuthService } from '../../../services/auth/auth.service';
import { CoreState } from '../../../store';
import * as RouterActions from '../../../store/router/router.actions';

@Component({
  selector: 'iad-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent {
  @Input() user: User;
  @Input() menuItems: Array<Menu>;
  @Input() menuItemOpened: string;
  @Input() highlightedLink: string;
  @Output() toggleMenu: EventEmitter<string>;
  @Output() highlightLink: EventEmitter<Array<string>>;
  cdiUrl: string;

  constructor(private authService: AuthService, private store: Store<CoreState>) {
    this.toggleMenu = new EventEmitter();
    this.highlightLink = new EventEmitter();
    this.cdiUrl = this.authService.getCdiUrl();
  }

  /**
   * Emit the toggleMenu event with param.
   */
  onToggleMenu([id]: Array<string>): void {
    this.toggleMenu.emit(id);
  }

  /**
   * Emit the toggleMenu event with param.
   */
  onHighlightLink([idSubMenu, idLink]: Array<string>): void {
    this.highlightLink.emit([idSubMenu, idLink]);
  }

  /**
   * Redirect to home page.
   */
  goToHome(): void {
    this.store.dispatch(RouterActions.goOutside({ url: this.cdiUrl }));
  }
}
