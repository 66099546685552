import { Action } from '@ngrx/store';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { PaginatedResult } from '../../../../../shared/models/paginated-result.interface';
import {
  AddCashingCommentPayload,
  Cashing,
  LinkInvoicePayment,
  PatchCashingPayload,
  PaymentTransactionActions
} from '../../../models';

export enum CashingActionTypes {
  GET_CASHING = '[APPROX] GET_CASHING',
  GET_CASHING_SUCCESS = '[APPROX] GET_CASHING_SUCCESS',
  GET_CASHING_ERROR = '[APPROX] GET_CASHING_ERROR',
  REFRESH_CASHING = '[APPROX] REFRESH_CASHING',

  OPEN_CASHING_CREATION_OVERLAY = '[APPROX] OPEN_CASHING_CREATION_OVERLAY',
  CLOSE_CASHING_CREATION_OVERLAY = '[APPROX] CLOSE_CASHING_CREATION_OVERLAY',

  GET_CASHING_BY_ID = '[APPROX] GET_CASHING_BY_ID',
  GET_CASHING_BY_ID_SUCCESS = '[APPROX] GET_CASHING_BY_ID_SUCCESS',
  GET_CASHING_BY_ID_ERROR = '[APPROX] GET_CASHING_BY_ID_ERROR',

  ADD_CASHING = '[APPROX] ADD_CASHING',
  ADD_CASHING_SUCCESS = '[APPROX] ADD_CASHING_SUCCESS',
  ADD_CASHING_ERROR = '[APPROX] ADD_CASHING_ERROR',

  UPDATE_CASHING = '[APPROX] UPDATE_CASHING',
  UPDATE_CASHING_SUCCESS = '[APPROX] UPDATE_CASHING_SUCCESS',
  UPDATE_CASHING_ERROR = '[APPROX] UPDATE_CASHING_ERROR',

  DELETE_CASHING = '[APPROX] DELETE_CASHING',
  DELETE_CASHING_SUCCESS = '[APPROX] DELETE_CASHING_SUCCESS',
  DELETE_CASHING_ERROR = '[APPROX] DELETE_CASHING_ERROR',

  VALIDATE_CASHING = '[APPROX] VALIDATE_CASHING',
  VALIDATE_CASHING_SUCCESS = '[APPROX] VALIDATE_CASHING_SUCCESS',
  VALIDATE_CASHING_ERROR = '[APPROX] VALIDATE_CASHING_ERROR',

  PATCH_CASHING = '[APPROX] PATCH_CASHING',
  PATCH_CASHING_SUCCESS = '[APPROX] PATCH_CASHING_SUCCESS',
  PATCH_CASHING_ERROR = '[APPROX] PATCH_CASHING_ERROR',

  GET_CASHING_INVOICES = '[APPROX] GET_CASHING_INVOICES',
  GET_CASHING_INVOICES_SUCCESS = '[APPROX] GET_CASHING_INVOICES_SUCCESS',
  GET_CASHING_INVOICES_ERROR = '[APPROX] GET_CASHING_INVOICES_ERROR',

  GET_CASHING_WITH_SCORES = '[APPROX] GET_CASHINGS_WITH_SCORES',
  GET_CASHING_WITH_SCORES_SUCCESS = '[APPROX] GET_CASHINGS_WITH_SCORES_SUCCESS',
  GET_CASHING_WITH_SCORES_ERROR = '[APPROX] GET_CASHINGS_WITH_SCORES_ERROR',
  REFRESH_CASHING_INVOICES = '[APPROX] REFRESH_CASHING_INVOICES',

  LINK_CASHING_INVOICE = '[APPROX] LINK_CASHING_INVOICE',
  LINK_CASHING_INVOICE_SUCCESS = '[APPROX] LINK_CASHING_INVOICE_SUCCESS',
  LINK_CASHING_INVOICE_ERROR = '[APPROX] LINK_CASHING_INVOICE_ERROR',
  LINK_CASHING_INVOICE_ERROR_DISPLAYED = '[APPROX] LINK_CASHING_INVOICE_ERROR_DISPLAYED',
  DELETE_INVOICES_IDS = '[APPROX] DELETE_INVOICES_IDS',

  EXPORT_CHECK = '[APPROX] EXPORT_CHECK',
  EXPORT_CHECK_SUCCESS = '[APPROX] EXPORT_CHECK_SUCCESS',
  EXPORT_CHECK_ERROR = '[APPROX] EXPORT_CHECK_ERROR',

  EXPORT_PAYMENT_TRANSACTION = '[APPROX] EXPORT_PAYMENT_TRANSACTION',
  EXPORT_PAYMENT_TRANSACTION_SUCCESS = '[APPROX] EXPORT_PAYMENT_TRANSACTION_SUCCESS',

  DELETE_CHECK = '[APPROX] DELETE_CHECK',
  DELETE_CHECK_SUCCESS = '[APPROX] DELETE_CHECK_SUCCESS',
  DELETE_CHECK_ERROR = '[APPROX] DELETE_CHECK_ERROR',

  SAVE_CASHING_FORM = '[APPROX] SAVE_CASHING_FORM',

  DELETE_LINKING_INVOICE_BANK = '[APPROX] DELETE_LINKING_INVOICE_BANK',
  DELETE_LINKING_INVOICE_BANK_SUCCESS = '[APPROX] DELETE_LINKING_INVOICE_BANK_SUCCESS',
  DELETE_LINKING_INVOICE_BANK_ERROR = '[APPROX] DELETE_LINKING_INVOICE_BANK_ERROR',

  GET_PAYMENT_TRANSACTION_ACTIONS = '[APPROX] GET_PAYMENT_TRANSACTION_ACTIONS',
  GET_PAYMENT_TRANSACTION_ACTIONS_SUCCESS = '[APPROX] GET_PAYMENT_TRANSACTION_ACTIONS_SUCCESS',
  GET_PAYMENT_TRANSACTION_ACTIONS_ERROR = '[APPROX] GET_PAYMENT_TRANSACTION_ACTIONS_ERROR',

  FLUSH_PAYMENT_TRANSACTION_ACTIONS = '[APPROX] FLUSH_PAYMENT_TRANSACTION_ACTIONS',

  ADD_CASHING_COMMENT_ACTION = '[APPROX] ADD_CASHING_COMMENT_ACTION',
  ADD_CASHING_COMMENT_SUCCESS_ACTION = '[APPROX] ADD_CASHING_COMMENT_SUCCESS_ACTION',
  ADD_CASHING_COMMENT_ERROR_ACTION = '[APPROX] ADD_CASHING_COMMENT_ERROR_ACTION'
}

export interface ItemsByIdRequest {
  id: number;
  page: number;
  limit: number;
  searchTerm?: string;
}

export type CashingActions =
  | GetCashingAction
  | GetCashingSuccessAction
  | GetCashingErrorAction
  | OpenCashingCreationOverlayAction
  | PatchCashingAction
  | PatchCashingSuccessAction
  | PatchCashingErrorAction
  | RefreshCashingTable
  | AddCashingAction
  | AddCashingSuccessAction
  | AddCashingErrorAction
  | UpdateCashingAction
  | UpdateCashingSuccessAction
  | UpdateCashingErrorAction
  | DeleteCashingAction
  | DeleteCashingSuccessAction
  | DeleteCashingErrorAction
  | ValidateCashingAction
  | ValidateCashingSuccessAction
  | ValidateCashingErrorAction
  | GetCashingInvoicesAction
  | GetCashingInvoicesSuccessAction
  | GetCashingInvoicesErrorAction
  | GetCashingWithScoresAction
  | GetCashingWithScoresSuccessAction
  | GetCashingWithScoresErrorAction
  | RefreshCashingInvoicesTable
  | GetCashingByIdAction
  | GetCashingByIdSuccessAction
  | GetCashingByIdErrorAction
  | LinkCashingInvoiceAction
  | LinkCashingInvoiceSuccessAction
  | LinkCashingInvoiceErrorAction
  | LinkCashingInvoiceErrorDisplayedAction
  | DeleteInvoicesIdsAction
  | DeleteCheckAction
  | DeleteCheckSuccessAction
  | DeleteCheckErrorAction
  | SaveCashingForm
  | ExportCheckAction
  | DeleteLinkingInvoiceBankAction
  | DeleteLinkingInvoiceBankSuccessAction
  | DeleteLinkingInvoiceBankErrorAction
  | GetPaymentTransactionActionsAction
  | GetPaymentTransactionActionsSuccessAction
  | GetPaymentTransactionActionsErrorAction
  | FlushPaymentTransactionActionsAction
  | AddCashingCommentAction
  | AddCashingCommentSuccessAction
  | AddCashingCommentErrorAction;

export class GetCashingAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING;

  constructor(public payload) {}
}

export class GetCashingSuccessAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_SUCCESS;

  constructor(public payload: PaginatedResult) {}
}

export class GetCashingErrorAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_ERROR;

  constructor(public payload: any) {}
}

export class OpenCashingCreationOverlayAction implements Action {
  readonly type = CashingActionTypes.OPEN_CASHING_CREATION_OVERLAY;

  constructor(public payload: Action) {}
}

export class CloseCashingCreationOverlayAction implements Action {
  readonly type = CashingActionTypes.CLOSE_CASHING_CREATION_OVERLAY;
}

export class PatchCashingAction implements Action {
  readonly type = CashingActionTypes.PATCH_CASHING;
  constructor(public payload: PatchCashingPayload) {}
}

export class PatchCashingSuccessAction implements Action {
  readonly type = CashingActionTypes.PATCH_CASHING_SUCCESS;
}

export class PatchCashingErrorAction implements Action {
  readonly type = CashingActionTypes.PATCH_CASHING_ERROR;

  constructor(public payload: any) {}
}

export class RefreshCashingTable implements Action {
  readonly type = CashingActionTypes.REFRESH_CASHING;
}

export class AddCashingAction implements Action {
  readonly type = CashingActionTypes.ADD_CASHING;
  constructor(public payload: Cashing) {}
}

export class AddCashingSuccessAction implements Action {
  readonly type = CashingActionTypes.ADD_CASHING_SUCCESS;

  constructor(public payload: Cashing) {}
}

export class AddCashingErrorAction implements Action {
  readonly type = CashingActionTypes.ADD_CASHING_ERROR;
  constructor(public error: any) {}
}

export class UpdateCashingAction implements Action {
  readonly type = CashingActionTypes.UPDATE_CASHING;

  constructor(public payload: Cashing) {}
}

export class UpdateCashingSuccessAction implements Action {
  readonly type = CashingActionTypes.UPDATE_CASHING_SUCCESS;

  constructor(public payload: Cashing) {}
}

export class UpdateCashingErrorAction implements Action {
  readonly type = CashingActionTypes.UPDATE_CASHING_ERROR;

  constructor(public payload: any) {}
}

export class DeleteCashingAction implements Action {
  readonly type = CashingActionTypes.DELETE_CASHING;

  constructor(public cashingId: number) {}
}

export class DeleteCashingSuccessAction implements Action {
  readonly type = CashingActionTypes.DELETE_CASHING_SUCCESS;
}

export class DeleteCashingErrorAction implements Action {
  readonly type = CashingActionTypes.DELETE_CASHING_ERROR;

  constructor(public payload: any) {}
}

export class ValidateCashingAction implements Action {
  readonly type = CashingActionTypes.VALIDATE_CASHING;

  constructor(public payload: Array<number>) {}
}

export class ValidateCashingSuccessAction implements Action {
  readonly type = CashingActionTypes.VALIDATE_CASHING_SUCCESS;

  constructor(public payload: Array<Cashing>) {}
}

export class ValidateCashingErrorAction implements Action {
  readonly type = CashingActionTypes.VALIDATE_CASHING_ERROR;
}

export class GetCashingInvoicesAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_INVOICES;

  constructor(public payload: ItemsByIdRequest) {}
}

export class GetCashingInvoicesSuccessAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_INVOICES_SUCCESS;

  constructor(public payload: PaginatedResult) {}
}

export class GetCashingInvoicesErrorAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_INVOICES_ERROR;

  constructor(public payload: any) {}
}

export class GetCashingWithScoresAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_WITH_SCORES;

  constructor(public payload: ItemsByIdRequest) {}
}

export class GetCashingWithScoresSuccessAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_WITH_SCORES_SUCCESS;

  constructor(public payload: PaginatedResult) {}
}

export class GetCashingWithScoresErrorAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_WITH_SCORES_ERROR;

  constructor(public payload: any) {}
}

export class RefreshCashingInvoicesTable implements Action {
  readonly type = CashingActionTypes.REFRESH_CASHING_INVOICES;
}

export class GetCashingByIdAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_BY_ID;

  constructor(public payload: number) {}
}

export class GetCashingByIdSuccessAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_BY_ID_SUCCESS;

  constructor(public payload: Cashing) {}
}

export class GetCashingByIdErrorAction implements Action {
  readonly type = CashingActionTypes.GET_CASHING_BY_ID_ERROR;

  constructor(public payload: any) {}
}

export class LinkCashingInvoiceAction implements Action {
  readonly type = CashingActionTypes.LINK_CASHING_INVOICE;

  constructor(public payload: LinkInvoicePayment) {}
}

export class LinkCashingInvoiceSuccessAction implements Action {
  readonly type = CashingActionTypes.LINK_CASHING_INVOICE_SUCCESS;

  constructor(public payload: any) {}
}

export class LinkCashingInvoiceErrorAction implements Action {
  readonly type = CashingActionTypes.LINK_CASHING_INVOICE_ERROR;

  constructor(public payload: any) {}
}

export class LinkCashingInvoiceErrorDisplayedAction implements Action {
  readonly type = CashingActionTypes.LINK_CASHING_INVOICE_ERROR_DISPLAYED;
}

export class ExportCheckAction implements Action {
  readonly type = CashingActionTypes.EXPORT_CHECK;

  constructor() {}
}

export class ExportCheckSuccessAction implements Action {
  readonly type = CashingActionTypes.EXPORT_CHECK_SUCCESS;

  constructor() {}
}

export class ExportCheckErrorAction implements Action {
  readonly type = CashingActionTypes.EXPORT_CHECK_ERROR;

  constructor(public payload: any) {}
}

export class ExportPaymentTransactionsAction implements Action {
  readonly type = CashingActionTypes.EXPORT_PAYMENT_TRANSACTION;

  constructor(public payload: any) {}
}

export class ExportPaymentTransactionsSuccessAction implements Action {
  readonly type = CashingActionTypes.EXPORT_PAYMENT_TRANSACTION_SUCCESS;
}

export class DeleteCheckAction implements Action {
  readonly type = CashingActionTypes.DELETE_CHECK;

  constructor(public checkId: number) {}
}

export class DeleteCheckSuccessAction implements Action {
  readonly type = CashingActionTypes.DELETE_CHECK_SUCCESS;

  constructor() {}
}

export class DeleteCheckErrorAction implements Action {
  readonly type = CashingActionTypes.DELETE_CHECK_ERROR;

  constructor(public payload: any) {}
}

export class DeleteInvoicesIdsAction implements Action {
  readonly type = CashingActionTypes.DELETE_INVOICES_IDS;
}

export class SaveCashingForm implements Action {
  readonly type = CashingActionTypes.SAVE_CASHING_FORM;

  constructor(public payload: Record<string, any>) {}
}

export class DeleteLinkingInvoiceBankAction implements Action {
  readonly type = CashingActionTypes.DELETE_LINKING_INVOICE_BANK;

  constructor(public cashingId: number) {}
}

export class DeleteLinkingInvoiceBankSuccessAction implements Action {
  readonly type = CashingActionTypes.DELETE_LINKING_INVOICE_BANK_SUCCESS;

  constructor() {}
}

export class DeleteLinkingInvoiceBankErrorAction implements Action {
  readonly type = CashingActionTypes.DELETE_LINKING_INVOICE_BANK_ERROR;

  constructor(public payload: any) {}
}

export class GetPaymentTransactionActionsAction implements Action {
  readonly type = CashingActionTypes.GET_PAYMENT_TRANSACTION_ACTIONS;

  constructor(public cashingId: number) {}
}

export class GetPaymentTransactionActionsSuccessAction implements Action {
  readonly type = CashingActionTypes.GET_PAYMENT_TRANSACTION_ACTIONS_SUCCESS;

  constructor(public payload: PaymentTransactionActions) {}
}

export class GetPaymentTransactionActionsErrorAction implements Action {
  readonly type = CashingActionTypes.GET_PAYMENT_TRANSACTION_ACTIONS_ERROR;

  constructor(public payload: any) {}
}

export class FlushPaymentTransactionActionsAction implements Action {
  readonly type = CashingActionTypes.FLUSH_PAYMENT_TRANSACTION_ACTIONS;
}

export class AddCashingCommentAction implements Action {
  readonly type = CashingActionTypes.ADD_CASHING_COMMENT_ACTION;

  constructor(public payload: AddCashingCommentPayload) {}
}

export class AddCashingCommentSuccessAction implements Action {
  readonly type = CashingActionTypes.ADD_CASHING_COMMENT_SUCCESS_ACTION;

  constructor(public payload: any) {}
}

export class AddCashingCommentErrorAction implements Action {
  readonly type = CashingActionTypes.ADD_CASHING_COMMENT_ERROR_ACTION;

  constructor(public payload: any) {}
}
