import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SideEvent } from '../../models/event';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  // we use any because the EventService can receive any kind of event value
  private event$: Subject<SideEvent<any>>;

  constructor() {
    this.event$ = new Subject();
  }

  /**
   * Allow to emit a custom event into the subject.
   * @param event - emitted event.
   */
  emit<T>(event: SideEvent<T>): void {
    this.event$.next(event);
  }

  /**
   * Get an observable which listen a specific event identifier.
   * @param eventName - event string identifier key
   */
  on<T>(eventName: string): Observable<T> {
    return this.event$.pipe(
      filter((emitEvent: SideEvent<T>) => emitEvent.name === eventName),
      map((emitEvent: SideEvent<T>) => emitEvent.value)
    );
  }
}
