import { Locale } from '../../../../models/locality/locale.enum';

export class MockLocaleService {
  setLocale = jasmine.createSpy('setLocale');
  getLocale = jasmine.createSpy('getLocale').and.returnValue(Locale.EN_GB);
  getSupportedLocale = jasmine.createSpy('getSupportedLocale');
  getSupportedLocaleFromIso2 = jasmine.createSpy('getSupportedLocaleFromIso2');
  isSupported = jasmine.createSpy('isSupported');
  getLocaleCountry = jasmine.createSpy('getLocaleCountry');
  getLocaleLabel = jasmine.createSpy('getLocaleLabel');
  getCustomLocale = jasmine.createSpy('getCustomLocale');
}
