import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { WindowRef } from "../../services";
import moment from 'moment-timezone';

declare global {
  interface Window {
    dataLayer: Record<string, any>;
  }
}

@Injectable()
export class GtmHelper {

  constructor(private router: Router, private location: Location, private window: WindowRef) {}

  public addGtmEvent (): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const env = sessionStorage.getItem('env');
        const site = sessionStorage.getItem('site');

        let navCountry
        let navTimeZone

        navTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        navCountry = moment.tz.zone(navTimeZone).countries()[0].toLowerCase()

        if (user) {
          this.window.nativeWindow.dataLayer.push({
            event: 'pageview',
            p_site: site,
            p_title: this.location.path(),
            p_path: this.location.path(),
            p_environment: env,
            p_language: this.window.nativeWindow.navigator.language.toLowerCase(),
            p_country: navCountry,
            p_front: 'WCS',
            collaborator_id: user.peopleId,
            collaborator_status: user.qualification.level,
            collaborator_city: user.addressPersonal ? user.addressPersonal.city : '',
            collaborator_stateOrProvince: '',
            collaborator_country: user.concession.slug,
            collaborator_storeId: '',
          });
        } else {
          this.window.nativeWindow.dataLayer.push({
            event: 'pageview',
            p_title: this.location.path(),
            p_path: this.location.path(),
            p_site: site,
            p_environment: env,
            p_language: this.window.nativeWindow.navigator.language.toLowerCase(),
            p_country: navCountry,
            p_front: 'WCS',
          });
        }
      }
    });
  }
}
