import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  pure: false
})
export class FileSizePipe implements PipeTransform {
  constructor() {}

  /**
   * Transform pipe method.
   *
   * @param value - file size.
   * @param precision - file size formatting precision;
   */
  transform(value: any, precision: number = 1): string {
    if (isNaN(parseFloat(value)) || !isFinite(value)) {
      return '-';
    }
    const units: Array<string> = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
    const number = Math.floor(Math.log(value) / Math.log(1024));
    return `${(value / Math.pow(1024, Math.floor(number))).toFixed(precision)} ${units[number]}`;
  }
}
