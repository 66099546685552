import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Concession } from '../models';
import * as RouterActions from '../store/router';
import { UserState } from '../store/user';
import * as fromUserSelectors from '../store/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class ConcessionGuard implements CanActivate {
  constructor(private store: Store<UserState>) {}

  /**
   * Activate the route if the concessionSlug has access.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(fromUserSelectors.selectConcession),
      map((concession: Concession) => {
        if (route.data.concessionsSlug.includes(concession.slug)) {
          return true;
        }
        this.store.dispatch(RouterActions.go({ path: ['/403'] }));
        return false;
      }),
      take(1)
    );
  }
}
