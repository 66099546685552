import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '../../../material.module';
import { OverlayLayoutFooterDirective } from './overlay-layout-footer.directive';
import { OverlayLayoutTitleDirective } from './overlay-layout-title.directive';
import { OverlayLayoutComponent } from './overlay-layout.component';

const DECLARABLES = [
  OverlayLayoutComponent,
  OverlayLayoutFooterDirective,
  OverlayLayoutTitleDirective
];

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [DECLARABLES],
  exports: [DECLARABLES]
})
export class OverlayLayoutModule {}
