<div
  class="iad-loader"
  fxLayout="column"
  fxLayoutAlign="center center">

  <div
    class="iad-loader-spinner"
    [class.flip]="counterClockwise">

    <div
      *ngIf="background"
      class="iad-loader-spinner-background"
      [ngStyle]="{
        'border-width.px': strokeWidth,
        'width.px': diameter,
        'height.px': diameter
      }">
    </div>

    <mat-progress-spinner
      [color]="color"
      [mode]="mode"
      [value]="value"
      [diameter]="diameter"
      [strokeWidth]="strokeWidth">
    </mat-progress-spinner>
  </div>

  <div 
    *ngIf="label"
    class="iad-loader-label"
    [style.font-size.px]="labelSize">
    {{ label$ | async }}
  </div>
</div>
