import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../material.module';
import { LoaderModule } from '../ui/loader/loader.module';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';

const COMPONENTS = [
  DialogConfirmComponent,
  AlertDialogComponent,
  PromptDialogComponent,
  LoadingDialogComponent
];

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule.forChild(), FormsModule, LoaderModule],
  declarations: COMPONENTS,
  entryComponents: COMPONENTS
})
export class DialogsModule {}
