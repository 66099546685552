import * as BottomSheetActions from './lib/store/bottom-sheet/bottom-sheet.actions'
import * as DialogActions from './lib/store/dialog/dialog.actions'
import * as FileActions from './lib/store/file/file.actions'
import * as MenuActions from './lib/store/menu/menu.actions'
import * as MenuSelectors from './lib/store/menu/menu.selectors'
import * as NotificationsActions from './lib/store/notifications/notifications.actions'
import * as NotificationsReducer from './lib/store/notifications/notifications.reducer'
import * as NotificationsSelectors from './lib/store/notifications/notifications.selectors'
import * as ReferencesActions from './lib/store/references/references.actions'
import * as ReferencesReducer from './lib/store/references/references.reducer'
import * as ReferencesSelectors from './lib/store/references/references.selectors'
import * as RouterActions from './lib/store/router/router.actions'
import * as SnackbarActions from './lib/store/snackbar/snackbar.actions'
import * as UserActions from './lib/store/user/user.actions'
import * as UserReducer from './lib/store/user/user.reducer'
import * as UserSelectors from './lib/store/user/user.selectors'

/*
 * Public API Surface of core
 */
export * from './lib/abstracts';
export * from './lib/components';
export * from './lib/components.module';
export * from './lib/constants';
export * from './lib/core.module';
export * from './lib/core-state.module';
export * from './lib/datepicker.module';
export * from './lib/directives';
export * from './lib/directives.module';
export * from './lib/pipes';
export * from './lib/pipes.module';
export * from './lib/guards';
export * from './lib/material.module';
export * from './lib/components/dialogs/dialogs.module';
export * from './lib/components/bottom-sheets/bottom-sheets.module';
export * from './lib/mocks/unit-test';
export * from './lib/models';
export * from './lib/operators';
export * from './lib/resolvers';
export * from './lib/services';
export * from './lib/utils';
export * from './lib/validators';
export * from './lib/mixins';

// Store's exports
export { CoreState, getRouterState } from './lib/store/index';
export const fromNotificationsActions = NotificationsActions;
export const fromNotificationsSelectors = NotificationsSelectors;
export const fromNotificationsReducer = NotificationsReducer;
export const fromDialogActions = DialogActions;
export const fromFileActions = FileActions;
export const fromRouterActions = RouterActions;
export const fromUserActions = UserActions;
export const fromUserReducer = UserReducer;
export const fromUserSelectors = UserSelectors;
export const fromSnackbarActions = SnackbarActions;
export const fromReferencesActions = ReferencesActions;
export const fromReferencesSelectors = ReferencesSelectors;
export const fromReferencesReducer = ReferencesReducer;
export const fromMenuSelectors = MenuSelectors;
export const fromMenuActions = MenuActions;
export const fromBottomSheetActions = BottomSheetActions;

// Environments exports
export * from './lib/environments';
