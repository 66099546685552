import { Injectable } from '@angular/core';

import { TimeConstants } from './../../constants/time/time.constants';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  /**
   * Checks if the given string match with the pattern `HH:mm` (24-hour clock).
   * @param time - The string to test
   * @returns True if the time is valid, otherwise false
   */
  isTwentyFourHourTime(time: string): boolean {
    return TimeConstants.TWENTY_FOUR_HOUR_TIME_REGEX.test(time);
  }

  /**
   * Returns the time by joining valid hours and minutes with a colon.
   * @param hours - The hours in two digits format
   * @param minutes - The minutes in two digits format
   * @returns A string in `HH:mm` format if hours and minutes are defined, otherwise null
   */
  getTwentyFourHourTime(hours: string, minutes: string): string | null {
    const time: string = [hours, minutes].join(':');

    return this.isTwentyFourHourTime(time) ? time : null;
  }
}
