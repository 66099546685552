import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

type ValidatorType = (fg: FormGroup) => void;

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorsService {
  constructor() {}

  /**
   * add or remove error when startDateValue and endDateValue have errors
   * @param fg: search bill form group
   */
  dateMaxMinValidation(startDate: string, endDate: string): any {
    return (fg: FormGroup) => {
      const startDateControl = fg.controls[startDate];
      const endDateControl = fg.controls[endDate];
      if (
        (startDateControl.errors && !startDateControl.errors.dateMinSuppDateMax) ||
        (endDateControl.errors && !endDateControl.errors.dateMinSuppDateMax)
      ) {
        return;
      }
      if (!_.isNil(startDateControl.value) && !_.isNil(endDateControl.value)) {
        if (startDateControl.value.valueOf() > endDateControl.value.valueOf()) {
          startDateControl.setErrors({ dateMinSuppDateMax: true });
          endDateControl.setErrors({ dateMinSuppDateMax: true });
        } else {
          startDateControl.setErrors(null);
          endDateControl.setErrors(null);
        }
      } else {
        startDateControl.setErrors(null);
        endDateControl.setErrors(null);
      }
    };
  }

  /**
   * add or remove error when maxvalue and minvalue have errors
   * @param minLabel min value label
   * @param maxLabel max value label
   */
  customMaxMinValidation(minLabel: string = 'minValue', maxLabel: string = 'maxValue'): ValidatorType {
    return (fg: FormGroup) => {
      const maxValue = fg.get(maxLabel).value;
      const minValue = fg.get(minLabel).value;
      if (
        !_.isNil(fg.controls[maxLabel].getError('pattern')) ||
        !_.isNil(fg.controls[minLabel].getError('pattern'))
      ) {
        return;
      } else if (!_.isNil(maxValue) && !_.isNil(minValue)) {
        const max = String(maxValue).replace(',', '.');
        const min = String(minValue).replace(',', '.');
        if (_.isEmpty(max) || _.isEmpty(min)) {
          fg.controls[maxLabel].setErrors(null);
          fg.controls[minLabel].setErrors(null);
        } else if (Number(max) < Number(min)) {
          fg.controls[maxLabel].setErrors({ minSuppMax: true });
          fg.controls[minLabel].setErrors({ minSuppMax: true });
        } else {
          fg.controls[maxLabel].setErrors(null);
          fg.controls[minLabel].setErrors(null);
        }
      } else {
        fg.controls[maxLabel].setErrors(null);
        fg.controls[minLabel].setErrors(null);
      }
    };
  }

  /**
   * check number of digits after comma
   * @param amountLabel: the amount label
   */
  customAmountValidation(amountLabel: string = 'amount'): ValidatorType {
    return (fg: FormGroup) => {
      const amount = fg.get(amountLabel).value as string;
      if (!_.isNil(amount)) {
        const split = amount.split(',')[1];
        if (split) {
          if (split.length > 2) {
            fg.controls[amountLabel].setErrors({ moreThenTreeDigits: true });
          } else if (_.isNil(fg.controls[amountLabel].getError('pattern'))) {
            fg.controls[amountLabel].setErrors(null);
          }
        }
      }
    };
  }

  /**
   * check if it's a comma and not a point
   * @param amountLabel: the amount label
   */
  customAmountCommaValidation(amountLabel: string = 'amount'): ValidatorType {
    return (fg: FormGroup) => {
      const amount = fg.get(amountLabel).value as string;
      if (!_.isNil(amount)) {
        if (amount.includes('.')) {
          fg.controls[amountLabel].setErrors({ pointNotComma: true });
        } else if (
          _.isNil(fg.controls[amountLabel].getError('pattern')) &&
          _.isNil(fg.controls[amountLabel].getError('moreThenTreeDigits'))
        ) {
          fg.controls[amountLabel].setErrors(null);
        }
      }
    };
  }
}
