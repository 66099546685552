import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatIconRegistry, MatPaginatorIntl } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { StorageServiceModule } from 'angular-webstorage-service';
import { get } from 'lodash';

import { gMapsApiKey } from './models/google/google-maps.constant';
import { CdiRedirectResolver } from './resolvers/cdi-redirect.resolver';
import { AuthConstant } from './services';
import { MatPaginatorIad } from './utils/paginator/mat-paginator-iad.class';
import { CORE_CONFIG, CoreConfig } from './models/core-config/core-config.interface';
import { LocaleService } from './services/locale/locale.service';
import { Locale } from './models/locality/locale.enum';

/**
 * Get the JWT Token key.
 */
export function tokenGetter(): string {
  return JSON.parse(sessionStorage.getItem(AuthConstant.JWT_TOKEN_KEY));
}

/**
 * APP_INITIALIZER factory method. We are setting the default locale here to make sure translation files are fully
 * loaded before the app bootstrap process.
 * @param config - The app config object reference.
 * @param localeService - The locale service.
 * @returns () => Promise<any> - The app initializer logic function block.
 */
export function initAppFactory(config: CoreConfig, localeService: LocaleService): () => Promise<any> {
  const initApp = (): Promise<any> => {
    const locale: string = get(config, 'locale.default', Locale.EN_GB as string);
    localeService.setDefaultLocale(locale);
    return localeService.setLocale(locale).toPromise();
  };
  return initApp;
}

@NgModule({
  imports: [
    CommonModule,
    StorageServiceModule,
    AgmCoreModule.forRoot({
      apiKey: gMapsApiKey,
      libraries: ['places']
    })
  ],
  providers: [
    CdiRedirectResolver,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIad, deps: [TranslateService] },
    { provide: APP_INITIALIZER, useFactory: initAppFactory, deps: [CORE_CONFIG, LocaleService], multi: true }
  ],
  exports: [HttpClientModule]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
    ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }

    // Register custom iad icons
    const icons = [
      'factory',
      'erase-field',
      'appartment',
      'building',
      'garage',
      'plan',
      'bedroom',
      'nb-rooms',
      'new-project',
      'new-recommendation',
      'real-estate-area',
      'ground-area',
      'shoe-print',
      'loading-character'
    ];
    icons.forEach((name: string) => {
      this.matIconRegistry.addSvgIcon(
        name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${name}.svg`)
      );
    });
  }
}
