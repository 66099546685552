import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DialogsModule } from '../../components/dialogs/dialogs.module';
import { CloseDialogResponse } from '../../models/dialog/dialog.interface';
import { DialogConfirmComponent } from './../../components/dialogs/dialog-confirm/dialog-confirm.component';
import { PromptDialogComponent } from './../../components/dialogs/prompt-dialog/prompt-dialog.component';

@Injectable({ providedIn: DialogsModule })
export class DialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Helper method used to open a confirm dialog.
   *
   * @param title - Title to display on top of the dialog.
   * @param content - The content to display inside the dialog.
   * @param confirmBtn - The label to display inside the accept button.
   * @param cancelBtn - The label to display inside the cancel button.
   */
  openConfirmDialog(title: string, content: string, confirmBtn?: string, cancelBtn?: string): Observable<CloseDialogResponse> {
    return this.openClassicDialog(DialogConfirmComponent, title, content, confirmBtn, cancelBtn);
  }

  /**
   * Helper method used to open a prompt dialog.
   *
   * @param title - Title to display on top of the dialog.
   * @param content - The content to display inside the dialog.
   * @param confirmBtn - The label to display inside the accept button.
   * @param cancelBtn - The label to display inside the cancel button.
   */
  openPromptDialog(title: string, content: string, confirmBtn?: string, cancelBtn?: string): Observable<CloseDialogResponse> {
    return this.openClassicDialog(PromptDialogComponent, title, content, confirmBtn, cancelBtn);
  }

  /**
   * Low-level helper method used to open a classic dialog.
   *
   * @param component - The dialog rendering component.
   * @param title - Title to display on top of the dialog.
   * @param content - The content to display inside the dialog.
   * @param confirmBtn - The label to display inside the accept button.
   * @param cancelBtn - The label to display inside the cancel button.
   */
  openClassicDialog<T>(
    component: ComponentType<T>,
    title: string,
    content: string,
    confirmBtn?: string,
    cancelBtn?: string
  ): Observable<CloseDialogResponse> {
    const config: MatDialogConfig = { data: { title, content, confirmBtn, cancelBtn } } as MatDialogConfig;

    return this.openDialog(component, config);
  }

  /**
   * Low-level helper method used to open a dialog.
   *
   * @param component - The dialog rendering component.
   * @param config - The dialog config.
   */
  openDialog<T>(component: ComponentType<T>, config: MatDialogConfig): Observable<CloseDialogResponse> {
    const dialogRef: MatDialogRef<T> = this.dialog.open(component, config);

    return dialogRef.afterClosed().pipe(
      map((dialogResult: string | boolean | undefined): CloseDialogResponse => ({
        dialogId: dialogRef.id,
        dialogResult
      }))
    );
  }
}
