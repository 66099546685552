import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowRef {
  get nativeWindow(): Window {
    // return the global native browser window object
    return window;
  }
}
