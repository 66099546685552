import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { capitalize } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GeocodeCountry,
  GeocodeGroup,
  GeocodeInputConfiguration,
  GeocodeInputConfigurationRow,
  GeocodeInputLocality,
  GeocodeLocality
} from '../../../../models/geocode/geocode.interface';
import { GEOCODE_CONFIG_V2 } from '../input-geocode.constants';
import { GeocodePagination } from './../../../../models/api-response/api-response.interface';
import { ISOCountry } from './../../../../models/locality/iso-country.enum';
import { GeocodeService } from './../../../../services/geocode/geocode.service';
import { inputGeocodeTokens } from './../input-geocode.constants';
import { GeocodeStrategy } from './geocode-strategy.interface';

@Injectable()
export class GeocodeV2Strategy implements GeocodeStrategy {
  constructor(
    private geocodeService: GeocodeService,
    private translate: TranslateService,
    @Inject(GEOCODE_CONFIG_V2) private configuration: GeocodeInputConfiguration
  ) {}

  /**
   * Get V2 localities.
   * @param country - geocode country.
   * @param term - search term.
   */
  getLocalities(
    country: GeocodeCountry,
    term: string,
    excludedIds: Array<number>
  ): Observable<Array<GeocodeGroup | GeocodeInputLocality>> {
    const configs: Array<GeocodeInputConfigurationRow> = this.configuration[country.iso.toLowerCase()];
    const observableBatch: Array<Observable<GeocodeGroup>> = new Array();
    configs.forEach((config: GeocodeInputConfigurationRow) => {
      observableBatch.push(
        this.geocodeService[`getCountry${capitalize(config.service)}`](ISOCountry[country.iso], {
          name: term,
          limit: config.limit
        }).pipe(
          map((result: GeocodePagination<GeocodeLocality>) => ({
            name: this.translate.instant(config.groupName),
            config,
            localities: result.items
              .filter((locality: GeocodeLocality) => !excludedIds.some((id: number) => id === locality.id))
              .map((locality: GeocodeLocality) => ({
                ...locality,
                searchLevel: config.searchField
              }))
          }))
        )
      );
    });
    return forkJoin(observableBatch);
  }

  /**
   * Format geocode locality.
   * @param country - geocode country.
   * @param locality - geocode locality.
   */
  formatLocality(country: GeocodeCountry, locality: GeocodeInputLocality, group: GeocodeGroup): string {
    return this.geocodeService.formatGeocodePattern(locality, group.config, inputGeocodeTokens);
  }
}
