<h2>
  {{ 'approximation.cashingArchivingDialog.title' | translate }}
</h2>

<form [formGroup]="form" (ngSubmit)="submit()">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        matInput
        placeholder="{{ 'approximation.cashingArchivingDialog.placeholder' | translate }}"
        formControlName="archivingReason"
        required
      >
    </mat-form-field>
  </div>

  <div mat-dialog-actions fxLayoutAlign="end">
    <button mat-button color="primary" type="button" (click)="cancel()">
      {{ 'approximation.cashingArchivingDialog.cancel' | translate }}
    </button>
    <button mat-button color="primary" type="submit" [disabled]="form.invalid">
      {{ 'approximation.cashingArchivingDialog.validate' | translate }}
    </button>
  </div>
</form>
