import { BehaviorSubject } from 'rxjs';

export class MockTranslateService {
  onLangChange = new BehaviorSubject('fr');
  instant = jasmine.createSpy('instant');
  use = jasmine.createSpy('use');
  getTranslation = jasmine.createSpy('getTranslation');
  setDefaultLang = jasmine.createSpy('setDefaultLang');
  getBrowserCultureLang = jasmine.createSpy('getBrowserCultureLang');
  currentLang = 'fr';
}
