import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BottomSheetListComponent } from '../../components/bottom-sheets/bottom-sheet-list/bottom-sheet-list.component';
import {
  BottomSheetOverlayComponent,
} from '../../components/bottom-sheets/bottom-sheet-overlay/bottom-sheet-overlay.component';
import { BottomSheetConstants } from '../../constants/bottom-sheet/bottom-sheet.constants';
import { BottomSheetListItem } from '../../models/bottom-sheet/bottom-sheet-list-item.interface';
import { BottomSheetOverlayConfig } from '../../models/bottom-sheet/bottom-sheet-overlay-config.interface';
import { BottomSheetOverlayData } from '../../models/bottom-sheet/bottom-sheet-overlay-data.interface';
import { BottomSheetListConfig } from './../../models/bottom-sheet/bottom-sheet-list-config.interface';
import { BottomSheetListData } from './../../models/bottom-sheet/bottom-sheet-list-data.interface';

@Injectable()
export class BottomSheetService {
  constructor(private bottomSheet: MatBottomSheet) {}

  /**
   * Low-level method used to open a bottom sheet.
   * Actions passed in parameter will be always dispatched after dismiss.
   * To dispatch custom actions according to specific component behavior, use the
   * `MatBottomSheetRef.dismiss` method and pass actions as `result` parameter.
   *
   * @param component - The bottom sheet rendering component
   * @param config - The bottom sheet config
   * @param afterDismissedActions - The actions that will be always dispatched after dismiss
   */
  openSheet<T, D = any>(
    component: ComponentType<T>,
    config: MatBottomSheetConfig<D>,
    afterDismissedActions: Array<Action> = []
  ): Observable<Array<Action>> {

    return this.bottomSheet
      .open<T, D, Array<Action>>(component, config)
      .afterDismissed()
      .pipe(map(actions => [...afterDismissedActions, ...(actions || [])] ));
  }

  /**
   * Open a bottom sheet that includes a `MatList`.
   *
   * @param items - The `MatList` items
   * @param afterDismissedActions - The actions that will be always dispatched after dismiss
   */
  openListSheet(
    items: Array<BottomSheetListItem>,
    afterDismissedActions: Array<Action> = []
  ): Observable<Array<Action>> {
    const bottomSheetConfig: BottomSheetListConfig = {
      data: { items }
    };

    return this.openSheet<BottomSheetListComponent, BottomSheetListData>(
      BottomSheetListComponent,
      bottomSheetConfig,
      afterDismissedActions
    );
  }

  /**
   * Open an overlay bottom sheet that includes a custom component.
   *
   * @param component - The custom component to inject into the overlay
   * @param title - The overlay title
   * @param config - An optional config that will be merged with the default bottom sheet overlay config
   * @param afterDismissedActions - The actions that will be always dispatched after dismiss
   */
  openOverlaySheet<T = any>(
    component: ComponentType<T>,
    title: string,
    config: MatBottomSheetConfig = {},
    afterDismissedActions: Array<Action> = []
  ): Observable<Array<Action>> {
    const bottomSheetConfig: BottomSheetOverlayConfig<T> = {
      ...config,
      panelClass: BottomSheetConstants.OVERLAY_PANEL_CLASS,
      data: {
        ...config.data,
        component,
        title
      }
    };

    return this.openSheet<BottomSheetOverlayComponent<T>, BottomSheetOverlayData<T>>(
      BottomSheetOverlayComponent,
      bottomSheetConfig,
      afterDismissedActions
    );
  }

  /**
   * Dismiss the current bottom sheet.
   */
  dismiss(): void {
    this.bottomSheet.dismiss();
  }
}
