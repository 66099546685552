import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';

import { DatepickerInputComponent } from './datepicker-input.component';
import { MaterialModule } from './../../../material.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    MatDatepickerModule
  ],
  declarations: [DatepickerInputComponent],
  exports: [DatepickerInputComponent]
})

export class DatepickerInputModule {}
