import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePtPT from '@angular/common/locales/pt-PT';

import { CoreConfig } from '../models';
import { TopbarEnvironment } from '../models/core-config/core-config.enum';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const p6DemoEnvironment: CoreConfig = {
  production: false,
  topbarEnvironment: TopbarEnvironment.P6,
  name: 'p6demo',
  apis: [
    {
      id: 'wis',
      url: 'https://p6.demo.iadholding.com',
      options: {}
    },
    {
      id: 'd8',
      url: 'https://p6.demo.iadholding.com',
      options: {}
    },
    {
      id: 'oa',
      url: 'https://7.preprod.api.iadfrance.com',
      options: {
        headers: {
          Authorization: 'Basic aWFkZnJhbmNlOmI0OS1GdXQ2Tg=='
        },
        withCredentials: false
      }
    },
    {
      id: 'passwordRecovery',
      url: 'api-agent.ci.iadfrance.com',
      options: {
        headers: {
          Authorization: 'Basic cGFzc3dvcmRfcmVjb3Zlcnk6cGFzc3dvcmRSZWNvdmVyeTIwMTZA'
        },
        withCredentials: false
      }
    },
    {
      id: 'webmail',
      url: 'https://p6.demo.iadholding.com',
      options: {
        headers: {
          Authorization: `Basic cmFja3NwYWNlOnJhY2tzcGFjZTIwMTYh`
        }
      }
    },
    {
      id: 'monolith',
      url: 'https://monolith-demo-p6.iadholding.com',
      options: {
        withCredentials: true
      }
    }
  ],
  urls: [
    {
      id: 'wis',
      url: 'https://p6.demo.iadholding.com'
    },
    {
      id: 'cas',
      url: 'https://p6.demo.iadholding.com/cas'
    },
    {
      id: 'cdi',
      url: 'https://p6.demo.iadholding.com'
    },
    {
      id: 'oa',
      url: null,
      urls: [
        {
          name: 'france',
          url: 'www-agent.ci.iadfrance.com'
        },
        {
          name: 'portugal',
          url: ''
        }
      ]
    },
    {
      id: 'network',
      url: 'https://network-demo-p6.iadholding.com'
    },
    {
      id: 'monolith',
      url: 'https://monolith-demo-p6.iadholding.com'
    }
  ],
  locale: {
    default: 'fr_FR',
    fallback: 'en_GB',
    localeKeys: ['fr_FR', 'en_GB', 'es_ES', 'pt_PT', 'it_IT', 'de_DE', 'en_US'],
    availables: [
      { name: 'fr_FR', locale: localeFr },
      { name: 'en_GB', locale: localeEn },
      { name: 'es_ES', locale: localeEs },
      { name: 'pt_PT', locale: localePtPT },
      { name: 'it_IT', locale: localeIt },
      { name: 'de_DE', locale: localeDe },
      { name: 'en_US', locale: localeEn }
    ],
    directories: ['core']
  },
  enabledJwt: true
};
