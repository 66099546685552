export enum CapacitationSubJobSlug {
  ALUR_LAW_1 = 'loi-alur-1',
  ALUR_LAW_2 = 'loi-alur-2',
  INITIAL_EVALUATION = 'initial-evaluation',
  IAD_CULTURE = 'iad-culture',
  SALE = 'vente',
  RENT = 'location',
  SALE_ACCESSION = 'vente-accession',
  SALE_INVESTOR_TAX_EXEMPTION = 'vente-investisseur-defiscalisation',
  MANAGEMENT = 'management',
  MLM = 'mlm',
  BECOME_MANAGER = 'devenir-manager',
  SKILL_TRAINER = 'formateur-habilite'
}

export enum CapacitationJobSlug {
  ALUR_LAW_1 = 'loi-alur-1',
  ALUR_LAW_2 = 'loi-alur-2',
  INITIAL_EVALUATION = 'initial-evaluation',
  IAD_CULTURE = 'iad-culture',
  TRADITIONAL_REAL_ESTATE = 'immobilier-traditionnel',
  BUSINESS_REAL_ESTATE = 'immobilier-d-entreprise',
  NEW_REAL_ESTATE = 'immobilier-neuf',
  MLM_MANAGEMENT = 'management-mlm',
  TECHNICAL_HABILITATION = 'habilitation-technique'
}
