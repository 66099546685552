export class IadDateConstants {

  static readonly TIME_SHORT = 'HH:mm';
  static readonly TIME_FULL = 'HH:mm:ss';

  /**
   * Static method to retrieve the Euro short date formatter DD/MM/YYYY.
   * @param separator - the date separator.
   * @returns - the date pattern.
   */

  static EURO_SHORT(separator: string = '/'): string {
    return `DD${separator}MM${separator}YYYY`;
  }

  /**
   * Static method to retrieve the Euro long date formatter DD/MMMM/YYYY (with month name).
   * @param separator - the date separator.
   * @returns - the date pattern.
   */

  static EURO_LONG(separator: string = '/'): string {
    return `DD${separator}MMMM${separator}YYYY`;
  }

  /**
   * Static method to retrieve the Euro long date formatter DD/MM/YYYY HH:mm.
   * @param separator - the date separator.
   * @returns - the date pattern.
   */

  static EURO_LONG_DATETIME(separator: string = '/'): string {
    return `DD${separator}MM${separator}YYYY HH:mm`;
  }

  /**
   * Static method to retrieve the datetime formatter DD-MM-YYYY HH:mm (without timeZone).
   * @param separator - the date separator.
   * @returns - the date pattern.
   */

  static EURO_DATETIME(separator: string = '-'): string {
    return `DD${separator}MM${separator}YYYY HH:mm`;
  }

  /**
   * Static method to retrieve the US/EN short date formatter YYYY-MM-DD.
   * @param separator - the date separator.
   * @returns - the date pattern.
   */

  static EN_US_SHORT(separator: string = '/'): string {
    return `MM${separator}DD${separator}YYYY`;
  }

  /**
   * Static method to retrieve the US/EN long date formatter YYYY-MMMM-DD (with month name).
   * @param separator - the date separator.
   * @returns - the date pattern.
   */

  static EN_US_LONG(separator: string = '-'): string {
    return `YYYY${separator}MMMM${separator}DD`;
  }

  /**
   * Static method to retrieve the datetime formatter YYYY-MM-DDTHH:mm:ssZ (with timeZone).
   * @param separator - the date separator.
   * @returns - the date pattern.
   */

  static EN_US_DATETIME_TIMEZONE(separator: string = '-'): string {
    return `YYYY${separator}MM${separator}DDThh:mm:ssZ`;
  }

  /**
   * Static method to retrieve the datetime formatter YYYYMMddTHHmmss for ICS file
   * ICS file is a calendar file saved in a universal calendar format.
   * @param separator - the date separator.
   * @param timeSeparator - the date timeSeparator.
   * @returns - the date pattern.
   */

  static EN_US_DATETIME_ICS(separator: string = '', timeSeparator: string = ''): string {
    return `YYYY${separator}MM${separator}DDTHH${timeSeparator}mm${timeSeparator}ss`;
  }

  /**
   * Static method to retrieve the datetime formatter YYYY-MM-DD HH:mm (without timeZone).
   * @param separator - the date separator.
   * @returns - the date pattern.
   */

  static EN_US_DATETIME(separator: string = '-'): string {
    return `YYYY${separator}MM${separator}DD HH:mm`;
  }
}
