import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PdfViewerModule } from '../pdf-viewer/pdf-viewer.module';
import { DocumentViewerComponent } from './document-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    PdfViewerModule
  ],
  declarations: [DocumentViewerComponent],
  exports: [DocumentViewerComponent]
})
export class DocumentViewerModule { }
