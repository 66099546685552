import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CopyToClipboardStatus } from './copy-to-clipboard-status.enum';

type CopyToClipboardIcon = 'fa-copy' | 'fa-check-circle';

@Component({
  selector: 'iad-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyToClipboardComponent {
  /** The text to copy into the user's clipboard. */
  @Input() value: string;

  /** The message to display in the tooltip. */
  @Input() tooltip: string;

  /** Emits when some text is copied to the clipboard. */
  @Output() copied = new EventEmitter<void>();

  /** An icon stream used to animate the `MatIcon`. */
  icon$ = new BehaviorSubject<CopyToClipboardIcon>('fa-copy');

  /**
   * Push a new icon value to animate the `MatIcon` and emit a copy event
   * according to the status.
   * @param status - The copy status.
   */
  onStatusChange(status: CopyToClipboardStatus): void {
    if (status === CopyToClipboardStatus.COPIED) {
      this.copied.emit();
      this.icon$.next('fa-check-circle');
    } else {
      this.icon$.next('fa-copy');
    }
  }
}
