import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatThemePalette, MenuItem } from '../../../models';

@Component({
  selector: 'iad-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionMenuComponent<D = any> {
  /** Theme color palette used for the `mat-icon-button`. */
  @Input() color: MatThemePalette;

  /** The data that will be used as action parameter. */
  @Input() data: D;

  /** Whether the mat icon button has to be disabled. */
  @Input() disabled: boolean;

  /** The font icon used for the `mat-icon-button`. Defaulted to `fa-ellipsis-v`. */
  @Input() fontIcon: string;

  /** The items collection to render in the `mat-menu`. */
  @Input() items: Array<MenuItem<D>>;

  /** Custom class list for the `mat-icon-button`. */
  @Input() classList: string;

  /** The tooltip content used for the `mat-icon-button`. */
  @Input() tooltip: string;
}
