import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap } from 'rxjs/operators';

import { BottomSheetService } from './../../services/bottom-sheet/bottom-sheet.service';
import * as BottomSheetActions from './bottom-sheet.actions';

@Injectable()
export class BottomSheetEffects {
  openListSheet$ = createEffect(() => this.actions$.pipe(
    ofType(BottomSheetActions.openListSheet),
    concatMap(({ items, afterDismissedActions }) =>
      this.bottomSheet.openListSheet(items, afterDismissedActions)
    ),
    concatMap(actions => [ BottomSheetActions.dismissSheet(), ...(actions || [])])
  ));

  openOverlaySheet$ = createEffect(() => this.actions$.pipe(
    ofType(BottomSheetActions.openOverlaySheet),
    concatMap(({ component, title, config, afterDismissedActions }) =>
      this.bottomSheet.openOverlaySheet(component, title, config, afterDismissedActions)
    ),
    concatMap(actions => [ BottomSheetActions.dismissSheet(), ...(actions || [])])
  ));

  constructor(
    private actions$: Actions,
    private bottomSheet: BottomSheetService
  ) {}
}
