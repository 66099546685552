import { EntityState } from '@ngrx/entity';
import { EntitySelectors } from '@ngrx/entity/src/models';
import { createSelector } from '@ngrx/store';

import { getMenuState } from '..';
import { SidenavMode } from '../../models';
import { Menu } from '../../models/menu/menu.interface';
import { MenuState } from './menu.reducer';
import * as fromMenuReducer from './menu.reducer';

export const {
  selectAll: getAllMenuItems
}: EntitySelectors<Menu, EntityState<Menu>> = fromMenuReducer.adapter.getSelectors();

export const selectMenu = createSelector(getMenuState, getAllMenuItems);
export const selectMenuLoading = createSelector(getMenuState, (state: MenuState): boolean => state.loading);
export const selectMenuError = createSelector(getMenuState, (state: MenuState): string => state.error);
export const selectMenuOpened = createSelector(getMenuState, (state: MenuState): boolean => state.opened);

export const selectItemSelected = createSelector(getMenuState, (state: MenuState): string => state.selectedItem);
export const selectSubMenuItemSelected = createSelector(getMenuState, (state: MenuState): string => state.selectedSubmenuItem);
export const selectMenuMode = createSelector(getMenuState, (state: MenuState): SidenavMode => state.mode);

