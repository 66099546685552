import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePtPT from '@angular/common/locales/pt-PT';

import { CoreConfig } from '../models';
import { TopbarEnvironment } from "../models/core-config/core-config.enum";

export const prodEnvironment: CoreConfig = {
  topbarEnvironment: TopbarEnvironment.PRODUCTION,
  production: true,
  name: 'prod',
  apis: [
    {
      id: 'wis',
      url: 'https://intranet.iadholding.com',
      options: {}
    },
    {
      id: 'd8',
      url: 'https://intranet.iadholding.com',
      options: {}
    },
    {
      id: 'oa',
      url: 'http://api.iadfrance.com',
      options: {
        headers: {
          Authorization: 'Basic aWFkZnJhbmNlOmI0OS1GdXQ2Tg=='
        },
        withCredentials: false
      }
    },
    {
      id: 'passwordRecovery',
      url: 'https://intranet.iadholding.com',
      options: {
        headers: {
          Authorization: 'Basic cGFzc3dvcmRfcmVjb3Zlcnk6cGFzc3dvcmRSZWNvdmVyeTIwMTZA'
        },
        withCredentials: false
      }
    },
    {
      id: 'webmail',
      url: 'https://intranet.iadholding.com',
      options: {
        headers: {
          Authorization: `Basic cmFja3NwYWNlOnJhY2tzcGFjZTIwMTYh`
        }
      }
    },
    {
      id: 'monolith',
      url: 'https://monolith.iadholding.com',
      options: {
        withCredentials: true
      }
    }
  ],
  urls: [
    {
      id: 'wis',
      url: 'https://intranet.iadholding.com'
    },
    {
      id: 'cas',
      url: 'https://intranet.iadholding.com/cas'
    },
    {
      id: 'cdi',
      url: 'https://intranet.iadholding.com'
    },
    {
      id: 'oa',
      url: null,
      urls: [
        {
          name: 'france',
          url: 'https://www.iadfrance.com'
        },
        {
          name: 'portugal',
          url: 'https://www.iadportugal.pt'
        }
      ]
    },
    {
      id: 'network',
      url: 'https://network.iadholding.com'
    },
    {
      id: 'monolith',
      url: 'https://monolith.iadholding.com'
    }
  ],
  locale: {
    default: 'fr_FR',
    fallback: 'en_GB',
    localeKeys: ['fr_FR', 'en_GB', 'es_ES', 'pt_PT', 'it_IT', 'de_DE', 'en_US'],
    availables: [
      { name: 'fr_FR', locale: localeFr },
      { name: 'en_GB', locale: localeEn },
      { name: 'es_ES', locale: localeEs },
      { name: 'pt_PT', locale: localePtPT },
      { name: 'it_IT', locale: localeIt },
      { name: 'de_DE', locale: localeDe },
      { name: 'en_US', locale: localeEn }
    ],
    directories: ['core']
  },
  enabledJwt: true
};
