import { Directive } from '@angular/core';

/**
 * Footer of the `OverlayLayoutComponent`, used as `ng-content` selector.
 */
@Directive({
  selector: '[iad-overlay-layout-footer], [iadOverlayLayoutFooter]',
  host: { class: 'iad-overlay-layout-footer' }
})
export class OverlayLayoutFooterDirective {}
