import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { SpeedDialAction } from '../../../models';

@Component({
  selector: 'iad-speed-dial',
  templateUrl: './speed-dial.component.html',
  styleUrls: ['./speed-dial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpeedDialComponent implements OnInit {
  @Input() open: boolean;
  @Input() direction: 'up' | 'down' | 'left' | 'right';
  @Input() animationMode: 'fling' | 'scale';
  @Input() fixed: boolean;
  @Input() spin: boolean;
  @Input() icon: string;
  @Input() actions: Array<SpeedDialAction>;
  @Input() label: string;
  @Input() color: string;
  @Input() actionColor: string;
  @Input() actionLabelDirection: 'left' | 'right';
  @Output() clicked: EventEmitter<string>;
  xsmall$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.open = false;
    this.direction = 'down';
    this.animationMode = 'scale';
    this.fixed = false;
    this.spin = false;
    this.actionLabelDirection = 'left';
    this.color = 'primary';
    this.actionColor = 'secondary';
    this.icon = 'fal fa-plus';
    this.clicked = new EventEmitter<string>();
  }

  /**
   * Life cycle onInit
   */
  ngOnInit(): void {
    this.xsmall$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(
      map((state: BreakpointState) => state.matches),
      share()
    );
  }

  /**
   * Emit the clicked action's id
   *
   * @param string id - Actions'id
   */
  actionClicked(id: string): void {
    this.clicked.emit(id);
  }
}
