import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  DoCheck,
  ElementRef,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Type,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  NG_VALIDATORS,
  NgControl,
  NgForm,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelectChange } from '@angular/material/select';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { Country, ISOCountry, CustomNumberConfig } from '../../../models';

const COMPONENT_SELECTOR = 'iad-phone-input';

/**
 * Boilerplate for applying mixins to PhoneInputComponent.
 */
export class PhoneInputComponentBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl
  ) {}
}

/**
 * We are using low-level mixinErrorState to handle errorState properly.
 */
export const _PhoneInputComponentBase = mixinErrorState(PhoneInputComponentBase);

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: PhoneInputComponent
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    }
  ]
})
export class PhoneInputComponent extends _PhoneInputComponentBase
  implements OnInit, DoCheck, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor, Validator {
  static nextId = 0;
  @Input() countries: Array<Country>;
  @Input() phoneNumberType: PhoneNumberType;
  @Input() defaultRegionCode: string;
  @Input() customsNumbersConfig: Array<CustomNumberConfig>;
  stateChanges: Subject<void>;
  phoneNumberUtil: PhoneNumberUtil;
  phoneNumber: PhoneNumber;
  phoneForm: FormGroup;
  inputMask: Array<RegExp | string>;
  onChange: (value: string) => void;
  onTouched: () => void;
  rxSub: Subscription;

  /**
   * This property allows someone to set or get the value of our control. Its type should be the same type we used for
   * the type parameter when we implemented MatFormFieldControl. We are using the rawInput property of the phoneNumber
   * aka the international number.
   */
  @Input()
  get value(): string {
    const nationalNumber: string = this.phoneForm.get('nationalNumber').value;
    if (nationalNumber === null || nationalNumber.length === 0 || !this.phoneNumber) {
      return '';
    }
    return this.sanitizeValue(this.phoneNumber.getRawInput());
  }
  set value(value: string | null) {
    this.initPhoneNumber(value);
    this.onChange(this.value);
    this.stateChanges.next();
  }

  /**
   * This property should return the ID of an element in the component's template that we want the <mat-form-field> to
   * associate all of its labels and hints with. In this case, we'll use the host element and just generate a unique
   * ID for it.
   */
  @HostBinding() id = `${COMPONENT_SELECTOR}-${PhoneInputComponent.nextId++}`;

  /**
   * This property allows us to tell the <mat-form-field> what to use as a placeholder. In this example, we'll do the
   * same thing as matInput and <mat-select> and allow the user to specify it via an @Input(). Since the value of the
   * placeholder may change over time, we need to make sure to trigger change detection in the parent form field by
   * emitting on the stateChanges stream when the placeholder changes.
   */
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  _placeholder: string;

  /**
   * This property indicates whether or not the form field control should be considered to be in a focused state.
   * When it is in a focused state, the form field is displayed with a solid color underline.
   */
  focused: boolean;

  /**
   * This property indicates whether the form field control is empty. For our control, we'll consider it empty if the
   * value is undefined.
   */
  get empty() {
    return !this.value;
  }

  /**
   * This property is used to indicate whether the label should be in the floating position. Because we are using a
   * select and input combo we need to always float.
   */
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return true;
  }

  /**
   * This property is used to indicate whether the input is required. <mat-form-field> uses this information to add a
   * required indicator to the placeholder. Again, we'll want to make sure we run change detection if the required
   * state changes.
   */
  @Input()
  get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }
  private _required: boolean;

  /**
   * This property tells the form field when it should be in the disabled state. In addition to reporting the right
   * state to the form field, we need to set the disabled state on the nationalNumber input that make up our component.
   */
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this._disabled ? this.phoneForm.disable() : this.phoneForm.enable();
    this.stateChanges.next();
  }
  private _disabled: boolean;

  /**
   * This property indicates whether the associated NgControl is in an error state.
   */
  errorState: boolean;

  /**
   * This property allows us to specify a unique string for the type of control in form field. The <mat-form-field> will
   * add an additional class based on this type that can be used to easily apply special styles to a <mat-form-field>
   * that contains a specific type of control.
   */
  controlType: string;

  /**
   * Apply those IDs to our host element.
   */
  @HostBinding('attr.aria-describedby') describedBy: string;

  constructor(
    private fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private injector: Injector,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, null);
    this.stateChanges = new Subject<void>();
    this.phoneNumberUtil = PhoneNumberUtil.getInstance();
    this.phoneNumberType = PhoneNumberType.FIXED_LINE_OR_MOBILE;
    this.defaultRegionCode = ISOCountry.FR;
    this.phoneForm = fb.group({
      countryCode: [''],
      nationalNumber: ['']
    });
    this._required = false;
    this._disabled = false;
    this.focused = false;
    this.errorState = false;
    this.controlType = COMPONENT_SELECTOR;
    this.describedBy = '';
    this.onChange = (value: string): void => {};
    this.onTouched = (): void => {};
  }

  /**
   * For the purposes of our component, we want to consider it focused if any of the part inputs are focused. We can
   * use the FocusMonitor from @angular/cdk to easily check this. We also need to remember to emit on the stateChanges
   * stream so change detection can happen.
   */
  ngOnInit(): void {
    // Retrieve NgControl manually to avoid circular dependency injection.
    this.ngControl = this.injector.get<NgControl>(NgControl as Type<NgControl>, null);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    this.fm.monitor(this.elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin && !this._disabled;
      if (!this.focused && !this._disabled) {
        this.onTouched();
      }
      this.stateChanges.next();
    });
    this.rxSub = this.phoneForm
      .get('nationalNumber')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value: string): void => {
        this.updatePhoneNumber(value, this.phoneForm.get('countryCode').value);
        this.onChange(this.value);
        this.stateChanges.next();
      });
  }

  /**
   * We need to re-evaluate this on every change detection cycle, because there are some error triggers that we can't
   * subscribe to (e.g. parent form submissions). This means that whatever logic is in here has to be super lean or we
   * risk destroying the performance.
   */
  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  /**
   * We should make sure to complete stateChanges when our component is destroyed. We also need to stop monitoring the
   * focus status.
   */
  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.rxSub.unsubscribe();
  }

  /**
   * This method is used by the <mat-form-field> to specify the IDs that should be used for the aria-describedby
   * attribute of your component. The method has one parameter, the list of IDs, we just need to apply the given IDs
   * to our host element.
   */
  setDescribedByIds(ids: Array<string>) {
    this.describedBy = ids.join(' ');
  }

  /**
   * Allows Angular to update the model (phoneNumber). If the phoneNumber parsing fails, we fallback to an empty
   * object initialized with the default countryCode. We also need to set the initial form value and input properties.
   *
   * @param value - The new model value.
   */
  writeValue(value: string): void {
    this.initPhoneNumber(value);
    this.stateChanges.next();
  }

  /**
   * Allows Angular to register a function to call when the model (phoneNumber) changes. Save the function as a
   * property to call later here.
   *
   * @param fn - The onChange callback to register.
   */
  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  /**
   * Allows Angular to register a function to call when the input has been touched. Save the function as a
   * property to call later here.
   *
   * @param fn - The onTouched callback to register.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Allows Angular to disable the input.
   *
   * @param disabled - The current form control state value.
   */
  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  /**
   * This method is the one required by the Validator interface. It is used by ngControl to validate the matFormField
   * control.
   *
   * @param control - The control to validate.
   * @return The ValidationErrors or null if the control is valid.
   */
  validate(control: AbstractControl): ValidationErrors | null {
    // Don't validate empty values to allow optional controls.
    if (control.value === null || control.value.length === 0) {
      return null;
    }
    const phoneNumber: PhoneNumber = this.parsePhoneNumber(control.value);
    return phoneNumber &&
      this.phoneNumberUtil.isValidNumber(phoneNumber) &&
      this.phoneNumberUtil.isPossibleNumberForType(phoneNumber, this.phoneNumberType)
      ? null
      : {
          nationalNumberInvalid: true
        };
  }

  /**
   * This is where everything starts, the heart of the beast. We initialize the phoneNumber object by the given rawInput
   * parameter. Then we update the internal phoneForm value. finally we setup the nationalNumber input properties.
   *
   * @param value - The initial phoneNumber value.
   */
  initPhoneNumber(value: string): void {
    this.updatePhoneNumber(value);
    this.updateInputAttributes();
    this.phoneForm.setValue({
      countryCode: this.phoneNumber.getCountryCode(),
      nationalNumber: this.phoneNumberUtil.format(this.phoneNumber, PhoneNumberFormat.NATIONAL)
    });
  }

  /**
   * This method update the phoneNumber properties. If the parameter provided is NaN we clear the phoneNumber.
   * In both cases we need to notify observers about the value change by calling onChange().
   *
   * @param rawInput - The new phoneNumber value.
   * @param countryCode - The phone country code.
   */
  updatePhoneNumber(rawInput: string, countryCode: number = NaN): void {
    this.phoneNumber = this.parsePhoneNumber(rawInput);
    if (!this.phoneNumber) {
      countryCode = !isNaN(countryCode) ?
        countryCode : parseInt(this.phoneNumberUtil.getCountryCodeForRegion(this.defaultRegionCode), 10);
      this.phoneNumber = new PhoneNumber();
      this.phoneNumber.setCountryCode(countryCode);
    }
    this.phoneNumber.setRawInput(this.phoneNumberUtil.format(this.phoneNumber, PhoneNumberFormat.INTERNATIONAL));
  }

  /**
   * Shorthand method to parse a rawInput phone value.
   *
   * @param rawInput - The new phoneNumber value.
   */
  parsePhoneNumber(rawInput: string): PhoneNumber {
    let phoneNumber: PhoneNumber;
    try {
      phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(this.sanitizeValue(rawInput), this.getRegionCode());
    } catch (e) {}
    return phoneNumber;
  }

  /**
   * We invoke this method to update nationalNumber input's placeholder and textMask. The placeholder corresponds to
   * a random example number related to the current phone countryCode and type. The textMask is obtained by mapping
   * the fresh new placeholder into an array of number regExps.
   */
  updateInputAttributes(): void {
    const customNumber: string = this.checkCustomsNumbersConfig(this.getRegionCode(), this.phoneNumberType);
    const exampleNumber: number = customNumber ? this.phoneNumberUtil.parse(customNumber, this.getRegionCode()) :
      this.phoneNumberUtil.getExampleNumberForType(this.getRegionCode(), this.phoneNumberType);
    this._placeholder = this.phoneNumberUtil.format( exampleNumber, PhoneNumberFormat.NATIONAL);
    this.inputMask = this._placeholder.split('').map((char: string): any => (char.match(/[0-9]/) ? /\d/ : ' '));
  }

  /**
   * Helper method used to get the current regionCode related to the phoneNumber countryCode property.
   *
   * @return the current regionCode.
   */
  getRegionCode(): string {
    return this.phoneNumber ? this.phoneNumberUtil.getRegionCodeForCountryCode(this.phoneNumber.getCountryCode()) : '';
  }

  /**
   * This method remove redundant space charaters.
   *
   * @param value - The value to sanitize.
   * @return the sanitized value.
   */
  sanitizeValue(value: string): string {
    return (value || '').replace(/\s/g, '');
  }

  /**
   * This method will be called when the form field is clicked on. It allows your component to hook in and handle that
   * click however it wants. The method has one parameter, the MouseEvent for the click. In our case we'll just focus
   * the first <input> if the user isn't about to click an <input> anyways.
   */
  onContainerClick(event: MouseEvent) {}

  /**
   * Callback invoked when a new countryCode is selected. we need to update the phoneNumber and input properties.
   *
   * @param change - The selectionChange object containing the new countryCode value.
   */
  onCountryCodeSelected(change: MatSelectChange): void {
    this.phoneForm.get('nationalNumber').reset();
    this.updatePhoneNumber(null, change.value);
    this.updateInputAttributes();
    this.onChange(this.value);
    this.stateChanges.next();
  }

  /**
   * This method checks if there is a custom configuration according to the region and the type of number.
   *
   * @param regionCode - ISO code of region.
   * @param phoneNumberType - Type of number, landline or mobile.
   */
  checkCustomsNumbersConfig(regionCode: string, phoneNumberType: number): string | undefined {
    if (this.customsNumbersConfig) {
      for (const config of this.customsNumbersConfig) {
        if (config.regionCode === regionCode && config.phoneNumberType === phoneNumberType) {
          return config.exampleNumber;
        }
      }
    }
  }
}
