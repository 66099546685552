import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout'
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators'

import { CORE_CONFIG, CoreConfig, SidenavMode } from '../../../models'
import { Menu } from '../../../models/menu/menu.interface'
import { Profile } from '../../../models/profile/profile.enum'
import { User } from '../../../models/user/user.interface'
import { CoreState } from '../../../store'
import * as fromMenuActions from '../../../store/menu/menu.actions'
import * as fromMenuSelectors from '../../../store/menu/menu.selectors'
import * as NotificationActions from '../../../store/notifications/notifications.actions'
import * as fromNotificationSelectors from '../../../store/notifications/notifications.selectors'
import * as fromUserSelectors from '../../../store/user/user.selectors'

@Component({
  selector: 'iad-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  @Input() cssTheme: string;
  @ViewChild('iadTopBar', { static: true }) iadTopBar: ElementRef;

  profile: Profile;
  user$: Observable<User>;
  menuItems$: Observable<Array<Menu>>;
  menuItemOpened$: Observable<string>;
  subMenuItemHighlighted$: Observable<string>;
  menuOpened: boolean;
  notificationOpened: boolean;
  menuMode$: Observable<SidenavMode>;
  s3Url: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store<CoreState>,
    private breakpointObserver: BreakpointObserver,
    @Inject(CORE_CONFIG) public config: CoreConfig
  ) {}

  /**
   * ngOnInit method.
   */
  ngOnInit(): void {
    // Potential future Breaking changes, route snapshot data profil was misspelled
    this.profile = this.route.snapshot.data.profil || this.route.snapshot.data.profile;
    this.user$ = this.store.pipe(
      select(fromUserSelectors.selectUser),
      filter(user => !!user),
      shareReplay(1)
    );
    this.getSidenavMenuState();
    this.getNotificationState();
    this.setMobileObservable();

    const appElem = this.iadTopBar.nativeElement;
    appElem.addEventListener('menuOpened', (event) => {
      this.menuOpened = event.detail[0]
    })
  }

  /**
   * Dispatch SelectItemAction
   *
   * @param itemId - subMenuItemId
   */
  openItemMenu(itemId: string): void {
    this.store.dispatch(fromMenuActions.selectItem({ selectedItem: itemId }));
  }

  /**
   * Dispatch SelectSubMenuAction
   *
   * @param [itemId, linkId] - subMenuItemId and linkItemId
   */
  highlightLink([itemId, linkId]: Array<string>): void {
    this.store.dispatch(fromMenuActions.selectSubMenu({ selectedSubmenuItem: [itemId, linkId] }));
  }

  /**
   * Handle menu close / open state changed
   * @param opened - boolean to indicate if menu is opened
   */
  menuStateChanged(opened: boolean): void {
    this.menuOpened !== opened && this.store.dispatch(fromMenuActions.toggleMenu({ opened }));
  }

  /**
   * Handle notification close / open state changed
   * @param opened - boolean to indicate if notification is opened
   */
  notificationStateChanged(opened: boolean): void {
    this.notificationOpened !== opened && this.store.dispatch(NotificationActions.toggleNotification({ opened }));
  }

  /**
   * Set an observable that emit when we encounter mobile breakpoint or not
   */
  private setMobileObservable(): void {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(
        map((state: BreakpointState) => state.matches),
        distinctUntilChanged()
      )
      .subscribe((isMobileScreen: boolean) => {
        this.store.dispatch(fromMenuActions.toggleMenu({ opened: !isMobileScreen }));
        this.store.dispatch(fromMenuActions.toggleMenuMode({ mode: !isMobileScreen }));
      });
  }

  /**
   * Retrieve convenient selectors for notifications states.
   */
  private getNotificationState(): void {
    this.store
      .pipe(select(fromNotificationSelectors.selectNotificationOpened))
      .subscribe(state => (this.notificationOpened = state));
  }

  /**
   * Retrieve convenient selectors for menu states.
   */
  private getSidenavMenuState() {
    this.store.pipe(select(fromMenuSelectors.selectMenuOpened)).subscribe(state => (this.menuOpened = state));
    this.menuItems$ = this.store.pipe(
      select(fromMenuSelectors.selectMenu),
      filter(menu => !!menu)
    );
    this.menuItemOpened$ = this.store.pipe(
      select(fromMenuSelectors.selectItemSelected),
      filter(item => !!item)
    );
    this.subMenuItemHighlighted$ = this.store.pipe(
      select(fromMenuSelectors.selectSubMenuItemSelected),
      filter(item => !!item)
    );
    this.menuMode$ = this.store.pipe(select(fromMenuSelectors.selectMenuMode));
  }
}
