import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GeocodeCountry,
  GeocodeGroup,
  GeocodeInputConfiguration,
  GeocodeInputLocality,
  GeocodeLocality
} from '../../../../models/geocode/geocode.interface';
import { GEOCODE_CONFIG_V1, inputGeocodeTokens } from '../input-geocode.constants';
import { GeocodePagination } from './../../../../models/api-response/api-response.interface';
import { ISOCountry } from './../../../../models/locality/iso-country.enum';
import { GeocodeService } from './../../../../services/geocode/geocode.service';
import { GeocodeStrategy } from './geocode-strategy.interface';

@Injectable()
export class GeocodeV1Strategy implements GeocodeStrategy {
  constructor(
    private geocodeService: GeocodeService,
    @Inject(GEOCODE_CONFIG_V1) private configuration: GeocodeInputConfiguration
  ) {}

  /**
   * Get V1 localities.
   * @param country - geocode country.
   * @param term - search term.
   */
  getLocalities(
    country: GeocodeCountry,
    term: string,
    excludedIds: Array<number>
  ): Observable<Array<GeocodeGroup | GeocodeInputLocality>> {
    let localities$: Observable<GeocodePagination<GeocodeLocality>>;

    const params = { [country.searchFilter || 'q']: term };
    const isoCountry: ISOCountry = ISOCountry[country.iso.toUpperCase()];

    switch (country.searchField) {
      case 'region1':
        localities$ = this.geocodeService.getCountryRegions1(isoCountry, params);
        break;
      case 'region2':
        localities$ = this.geocodeService.getCountryRegions2(isoCountry, params);
        break;
      case 'region3':
        localities$ = this.geocodeService.getCountryRegions3(isoCountry, params);
        break;
      case 'region4':
        localities$ = this.geocodeService.getCountryRegions4(isoCountry, params);
        break;
      case 'locality':
        localities$ = this.geocodeService.getCountryLocalities(isoCountry, params);
        break;
      case 'street':
        localities$ = this.geocodeService.getCountryStreets(isoCountry, params);
        break;
    }
    return localities$.pipe(
      map((result: GeocodePagination<GeocodeLocality>) => result.items),
      map((localities: Array<GeocodeLocality>) =>
        localities
          .filter((locality: GeocodeLocality) => !excludedIds.some((id: number) => id === locality.id))
          .map((locality: GeocodeLocality) => ({ ...locality, searchLevel: country.searchField }))
      )
    );
  }

  /**
   * Format geocode locality.
   * @param country - geocode country.
   * @param locality - geocode locality.
   */
  formatLocality(country: GeocodeCountry, locality: GeocodeInputLocality): string {
    return this.geocodeService.formatGeocodePattern(
      locality,
      this.configuration[country.iso.toLowerCase()][0],
      inputGeocodeTokens
    );
  }
}
