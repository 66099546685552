import { createSelector } from '@ngrx/store';

import { selectUserState } from '..';
import { User } from '../../models';
import { Concession } from './../../models/concession/concession.interface';
import { ConcessionSlug } from './../../models/concession/concession-slug.enum';
import { UserState } from './user.reducer';

export const selectUser = createSelector(selectUserState, (state: UserState): User => state.user);
export const selectUserLoading = createSelector(selectUserState, (state: UserState): boolean => state.loading);
export const selectUserLocale = createSelector(selectUserState, (state: UserState): string => state.locale);
export const selectUserError = createSelector(selectUserState, (state: UserState): string => state.error);
export const selectConcession = createSelector(
  selectUserState,
  (state: UserState): Concession => (state.user && state.user.concession ? state.user.concession : null)
);
export const selectIsFranceConcession = createSelector(
  selectUserState,
  (state: UserState): boolean => {
    if (state.user && state.user.concession) {
      return state.user.concession.slug === ConcessionSlug.FRANCE;
    }
  }
);
