import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, mapTo, take } from 'rxjs/operators';

import { CORE_CONFIG, CoreConfig } from '../models/core-config/core-config.interface';
import { User } from '../models/user/user.interface';
import * as AuthActions from '../store/auth/auth.actions';
import { UserState } from '../store/user';
import * as UserActions from '../store/user/user.actions';
import * as fromUserSelectors from '../store/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(@Inject(CORE_CONFIG) private config: CoreConfig, private store: Store<UserState>) {}

  /**
   * Can activate method.
   * @param next - next route handler
   */
  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Dispatch ngRx action to fetch user
    const isJwtEnabled = this.config.enabledJwt;
    if (isJwtEnabled) {
      this.store.dispatch(AuthActions.login());
    } else {
      this.store.dispatch(UserActions.getUser());
    }
    // execute authentication check
    return this.ensureAuthentication();
  }

  /**
   * Method to ensure authentication in canActivate.
   * @param route - router Snapshot.
   * @returns Observable<boolean> - return ob servable boolean.
   */
  ensureAuthentication(): Observable<boolean> {
    // Blocking guard, until user is not null
    return this.store.pipe(
      select(fromUserSelectors.selectUser),
      filter((user: User) => !!user),
      mapTo(true),
      take(1)
    );
  }
}
