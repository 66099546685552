export enum ISOCountry {
  FR = 'FR',
  PT = 'PT',
  IT = 'IT',
  ES = 'ES',
  DE = 'DE',
  GB = 'GB',
  FL = 'FL',
  US = 'US'
}
