import { TimepickerClockType } from '../../../models/timepicker/timepicker-clock-type.enum';
import { TimepickerClockState } from './../../../models/timepicker/timepicker-clock-state.interface';
import { MatDialogConfig } from '@angular/material';

export class TimepickerDialogConstants {
  public static readonly FULL_ANGLE = 360;
  public static readonly HALF_ANGLE = 180;
  public static readonly CLOCK_VALUES_COUNT = 12;
  public static readonly HOURS_PER_CLOCK = 12;
  public static readonly MINUTES_PER_CLOCK = 60;
  public static readonly INITIAL_CLOCK_STATE: TimepickerClockState = {
    hours: '12',
    minutes: '00',
    time: '12:00',
    clock: TimepickerClockType.HOURS
  };
  public static readonly CONFIG: MatDialogConfig = {
    autoFocus: false,
    maxWidth: '100%',
    panelClass: 'iad-timepicker-dialog-pane',
    restoreFocus: false,
  };
}
