import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[iadDynamicComponent]'
})
export class DynamicComponentDirective<T extends { data: any }> implements OnInit {

  @Input() component: new (...args: any[]) => T;
  @Input() data: T['data'];
  componentRef: ComponentRef<T>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  /**
   * On initialization, resolve the component factory and create the component instance.
   */
  ngOnInit(): void {
    if (this.component) {
      const componentFactory = this.resolver.resolveComponentFactory(this.component);
      this.componentRef = this.container.createComponent(componentFactory);
      this.componentRef.instance.data = this.data;
    }
  }
}
