import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { User } from '../models/user/user.interface';
import * as RouterActions from '../store/router/router.actions';
import { UserState } from '../store/user';
import * as fromUserSelectors from '../store/user/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorStatusGuard implements CanActivate {
  constructor(private store: Store<UserState>) {}

  /**
   * Can activate method. Used to check if a route can be crossed.
   * @param next - next route handler
   */
  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(fromUserSelectors.selectUser),
      filter((user: User) => !!user),
      map((user: User) => {
        if (user.collaboratorStatus === 'reference.collaborator_status.blocked') {
          this.store.dispatch(RouterActions.go({path: ['/403-blocked']}))
          return false;
        }
        return true
      }),
      take(1)
    );
  }
}
