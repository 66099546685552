export enum ReferencesSlugs {
  AGENT_TYPE = 'reference_agent_type',
  BTQ = 'btq',
  COLLABORATOR_STATUS = 'collaboratorStatus',
  CONTRACT_TYPE = 'contractType',
  COUNTRY = 'country',
  COUNTRY_OF_CITIZENSHIP = 'countryOfCitizenship',
  DEPARTMENT = 'department',
  DIPLOMA = 'diploma',
  EMAIL_TYPE = 'emailType',
  GREETINGS = 'greetings',
  MARITAL_STATUS = 'maritalStatus',
  ORGANIZATION_TYPE = 'organizationType',
  PARTNER_STATUS = 'partnerStatus',
  PATH_TYPE = 'pathType',
  PHONE_TYPE = 'phoneType',
  QUALIFICATION = 'qualification',
  QUALIFICATION_LEVEL = 'qualificationLevel',
  REAL_ESTATE_PROJECT_QUALIFICATION = 'realEstateProjectQualification',
  SOCIAL_FORM = 'referenceSocialForm',
  SOCIO_PROFESSIONAL_CATEGORY = 'socioProfessionalCategory',
  FINANCIAL_ADVANCE_TYPE = 'referenceFinancialAdvanceType',
  PAYMENT_MODE = 'referencePaymentMode',
  VISIT_THOUGHT = 'visitThought',
  PRODUCT_TYPE = 'productType',
  REAL_ESTATE_PROJECT_STATUS = 'realEstateProjectStatus',
  PRODUCT_TYPE_GROUP = 'productTypeGroup'
}
