import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment';

import { TimeConstants } from './../constants/time/time.constants';

export class CustomValidators {
  /**
   * Validator for place input.
   * @param control - AbstractControl
   */
  static validatePlace(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value || (control.value && !control.value.address1)) {
      return { placeInput: 'place is required' };
    }
    return null;
  }

  /**
   * Validator for file max size
   * @param control - AbstractControl
   */
  static validateFileSize(maxSize: number): ValidatorFn {
    const result: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value instanceof File && maxSize && control.value.size > maxSize) {
        return { fileSizeExceeded: 'file exceeded allowed max size' };
      }
      return null;
    };
    return result;
  }

  /**
   * Validator for file extension
   * @param control - AbstractControl
   */
  static validateFileExtension(extensionsAllowed: Array<string>): ValidatorFn {
    const result: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value instanceof File && extensionsAllowed && !extensionsAllowed.includes(control.value.type)) {
        return { fileExtensionUnauthorized: 'file extension is not authorised' };
      }
      return null;
    };
    return result;
  }

  /**
   * Validator for currency Input - check if the value is a valid number.
   * @param control - AbstractControl
   */
  static validateCurrency(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }

    const value = control.value.amount || control.value;
    return Number.isNaN(typeof value === 'string' ? Number.parseFloat(value) : value) ? { nan: true } : null;
  }

  /**
   * Validator that requires the control's value to match a regex pattern
   * corresponding to the 24-hour clock format.
   * Use the `Validators.pattern()` function.
   * @param control - The form control
   * @returns An error map with the `invalidTime` property if the validation fails, otherwise null.
   */
  static validateTwentyFourHourTime(control: AbstractControl): ValidationErrors | null {
    const hasError: boolean = !!Validators.pattern(TimeConstants.TWENTY_FOUR_HOUR_TIME_REGEX)(control);

    return hasError ? { invalidTime: true } : null;
  }

  /*
   * Validator that requires the control's value to be greater than the given date in parameter.
   * @param maxDateAllowed - The max date allowed as moment date object.
   */
  static validateMaxDate(maxDateAllowed: moment.Moment): ValidatorFn | null {
    const result: ValidatorFn = (control: AbstractControl): { maxDate: string } | null => (
      control.value && maxDateAllowed && maxDateAllowed.isBefore(control.value)
      ? { maxDate: 'Choosen date is greater than allowed.' }
      : null
    );
    return result;
  }

  /**
   * Validator that requires the control's value to be lower than the given date in parameter.
   * @param minDateAllowed - The min date allowed as moment date object.
   */
  static validateMinDate(minDateAllowed: moment.Moment): ValidatorFn | null {
    const result: ValidatorFn = (control: AbstractControl): { minDate: string } | null => (
      control.value && minDateAllowed && minDateAllowed.isAfter(control.value)
      ? { minDate: 'Choosen date is lower than minDate' }
      : null
    );

    return result;
  }
}
