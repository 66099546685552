import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { User } from '../../models';
import { AuthService } from '../../services/auth/auth.service';
import { LocaleService } from '../../services/locale/locale.service';
import { WindowRef } from '../../services/window/window.service';
import * as MenuActions from '../menu/menu.actions';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    switchMap(() => forkJoin([this.authService.getUser(), this.authService.getAuthToken()]).pipe(
      map(([peopleMeUser, userJWT]: [User, User]) => this.extractPeopleMeProperties(
        peopleMeUser,
        userJWT,
        [
          'avatar',
          'qualificationLevel',
          'idCityObs',
          'phone',
          'addressPersonal',
          'sector',
          'idPeople',
          'qualificationLevelReference',
          'qualificationReference',
          'collaboratorStatus'
        ]
      )),
      tap(user => this.authService.setUser(user)),
      switchMap(user => this.localeService.setLocale(this.authService.computeUserLocale()).pipe(
        mapTo(user)
      )),
      switchMap((user: User) => [
        AuthActions.loginSuccess({ user }),
        MenuActions.getMenu({ concession: user.concession })
      ]),
      catchError(error => {
        this.windowRef.nativeWindow.location.href = this.authService.getAuthLoginUrl();
        return of(AuthActions.loginFailure({ error }));
      })
    ))
  ));

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private localeService: LocaleService,
    private windowRef: WindowRef
  ) {}

  /**
   * Extract given peopleMe properties and merge them into user JWT
   * @param peopleMeUser User - User get from people/me
   * @param userJWT User - User decoded from JWT
   * @param properties Array<keyof User> - properties from user people me object to merge into userJWT
   */
  private extractPeopleMeProperties(peopleMeUser: User, userJWT: User, properties: Array<keyof User>): User {
    return properties.reduce((newUser, property) =>
      ({ ...newUser, ...(peopleMeUser[property] ? { [property]: peopleMeUser[property] } : {}) }),
      { ...userJWT }
    );
  }
}
