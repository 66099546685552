import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '../../models/user/user.interface';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PeoplesService {
  constructor(private apiService: ApiService) {}

  /**
   * Retrieve one person.
   * @param peopleId - ID of the searched person.
   */
  getPeople(peopleId: number): Observable<User> {
    return this.apiService.get<User>('wis', `/directory/peoples/${peopleId}`);
  }
}
