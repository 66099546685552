/// <reference types="@types/googlemaps" />
import { AgmGeocoder, GeocoderResult } from '@agm/core';
import { Component, forwardRef, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { isObject } from 'lodash';
import { Observable, Subject, of, combineLatest } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  takeUntil
} from 'rxjs/operators';

import { AddressConstants } from '../../../constants/address/address.constants';
import { Country, GridConfig } from '../../../models';
import { Address, AddressFieldConfig } from './../../../models/locality/address.interface';

@Component({
  selector: 'iad-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    }
  ]
})
export class AddressComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() config: AddressFieldConfig;
  @Input() grid: GridConfig;
  @Input() enableGeocoordinatesFetch: boolean;

  form: FormGroup;
  onChange: (value: Address) => void;
  onTouched: () => void;
  fieldNames: Record<string, string>;
  fetchingGeoCoordinates = false; // in case we need loader, etc

  address1$: Observable<Address | string>;
  state$: Observable<string>;
  postalCode$: Observable<[string, string]>;
  city$: Observable<[string, string]>;
  fullAddress$: Observable<Address>;
  unsubscribe$: Subject<void>;

  get value(): Address {
    return this.form.value;
  }

  @Input('value')
  set value(value: Address) {
    this.onChange(value);
    this.onTouched();
  }

  constructor(private fb: FormBuilder, private agmGeocoder: AgmGeocoder, private ref: ChangeDetectorRef) {
    this.unsubscribe$ = new Subject();
    this.onChange = () => {};
    this.onTouched = () => {};
    this.fieldNames = {};
    this.form = this.fb.group({
      address1: null,
      address2: null,
      city: null,
      country: null,
      postalCode: null,
      latitude: null,
      longitude: null,
      state: null
    });
  }

  /**
   * Life cycle OnInit
   */
  ngOnInit(): void {
    this.initGridConfig();
    this.setRxFields();
    this.applyConfig();
    this.generateUniqueFields();
    this.initListeners();
  }

  /**
   * Life cycle OnDestroy
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * We implement this method to keep a reference to the onChange callback function passed by the forms API
   */
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  /**
   * We implement this method to keep a reference to the onTouched callback function passed by the forms API
   * @param fn - OnTouched function
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * This is a basic setter that the forms API is going to use
   * @param value - value to write
   */
  writeValue(value): void {
    if (value) {
      this.form.patchValue({ ...this.form.value, ...value, address1: { ...value } }, { emitEvent: false });
    }
  }

  /**
   * This method is the one required by the Validator interface. It is used by ngControl to validate the control.
   *
   * @param control - The control to validate.
   * @return The ValidationErrors or null if the control is valid.
   */
  validate = (control: AbstractControl): ValidationErrors | null => {
    return this.form.invalid ? { address: 'address is invalid' } : null;
  }

  /**
   * reset form values
   */
  resetValues(country: Country): void {
    const values: Record<string, any> = {};
    Object.keys(this.form.value).forEach((key: string) => ((values[key] = key !== 'country' ? null : country), {})),
      this.form.reset(values);
    this.form.updateValueAndValidity();
  }

  /**
   * Allow to enable/disable form state (readonly)
   * @param isDisabled - flag to indicate if form is  disabled.
   */
  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable({ emitEvent: false }) : this.form.enable({ emitEvent: false });
  }

  /**
   * Method used to remove browsers address autocomplete on input fields
   */
  private generateUniqueFields(): void {
    ['address2', 'city', 'postalCode'].forEach(
      (key: string) => (this.fieldNames[key] = `${key}${new Date().getTime().toString()}`)
    );
  }

  /**
   * Init grid areas configuration.
   */
  private initGridConfig(): void {
    this.grid = (this.grid && { ...AddressConstants.DEFAULT_GRID, ...this.grid }) || AddressConstants.DEFAULT_GRID;
  }

  /**
   * Setup rxjs class properties.
   */
  private setRxFields() {
    this.address1$ = this.form.get('address1').valueChanges;
    this.postalCode$ = this.form.get('postalCode').valueChanges.pipe(
      startWith(null as string),
      pairwise(),
      startWith([null, null] as [string, string])
    );
    this.city$ = this.form.get('city').valueChanges.pipe(
      startWith(null as string),
      pairwise(),
      startWith([null, null] as [string, string])
    );
    this.fullAddress$ = this.form.valueChanges.pipe(
      distinctUntilChanged(),
      map(values => this.extractAddress1(values)),
      switchMap((values: Address) =>
        this.canFetchGeoCoordinates(values)
          ? this.fetchGeoCoordinates(values)
          : of(values)
      ),
      takeUntil(this.unsubscribe$)
    );
    this.state$ = this.form.get('state').valueChanges;
  }

  /**
   * Method to check if we should get latitude & longitude.
   * @param address - an Address object
   */
  private canFetchGeoCoordinates(address: Address): boolean {
    return (
      this.enableGeocoordinatesFetch &&
      address.address1 &&
      address.city &&
      address.postalCode &&
      (!address.latitude || !address.longitude)
    );
  }

  /**
   * Used to retrieve latitude & longitude from an address object.
   * @param address - an Address object
   */
  private fetchGeoCoordinates(address: Address): Observable<Address> {
    this.fetchingGeoCoordinates = true;
    this.onChange({ ...address }); // we 'emit' the current values before fetching asynchronously the geocoordinates (in particular to update the parent forms about the Address validity state)
    const countryName = isObject(address.country)
      ? address.country.label
      : address.country;
    const addressStr = `${address.address1}, ${address.postalCode}, ${address.city}, ${countryName}`;
    return of(address).pipe(
      delay(800),
      switchMap(() =>
        this.agmGeocoder.geocode({ address: addressStr }).pipe(
          map((geocode: GeocoderResult[]) =>
            this.extractGeoCoordinates(address, geocode)
          ),
          catchError(e => {
            return of(address);
          })
        )
      )
    );
  }

  /**
   * Apply configuration from props.
   */
  private applyConfig(): void {
    this.config && Object.keys(this.config).forEach((key: string) => this.form.get(key).setValidators(this.config[key]));
  }

  /**
   * Initialize values changes listeners.
   */
  private initListeners() {
    this.address1$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((address: Address) => {
        if (address && address.latitude && address.longitude) {
          this.form.patchValue(
            {
              city: address.city,
              postalCode: address.postalCode,
              latitude: address.latitude,
              longitude: address.longitude
            },
            { emitEvent: false } // Don't propagate the emitted values to the main form value listener
          );
        } else {
          this.form.patchValue(
            { latitude: null, longitude: null },
            { emitEvent: false }
          );
        }
      });

    combineLatest(this.postalCode$, this.city$)
      .pipe(
        filter(() => this.enableGeocoordinatesFetch),
        takeUntil(this.unsubscribe$)
        )
      .subscribe(() =>
        this.form.patchValue(
          { latitude: null, longitude: null },
          { emitEvent: false }
        )
      );

    this.fullAddress$.subscribe((value: Address) => {
      this.onChange({ ...value });
      this.fetchingGeoCoordinates = false;
      this.ref.detectChanges();
    });
  }

  /**
   * Extract place input address1 into form fields.
   * @param values - form values
   * @param preserveCityAnCode - boolean to indicate if city and postalCode should be preserve from google input result
   */
  private extractAddress1(values: Record<string, any>): Address {
    if (isObject(values.address1)) {
      values = {
        ...values,
        address1: (values.address1 as Address).address1
      };
    }
    return values as Address;
  }

  /**
   * Extract geocoordinates from geocoder's result into form fields.
   * @param values - form values
   * @param geocoderResults - values returned by the Geocoder service
   */
  private extractGeoCoordinates(values: Address, geocoderResults: GeocoderResult[]): Address {
    if (geocoderResults && geocoderResults.length > 0) {
      const latitude = geocoderResults[0].geometry.location.lat().toString();
      const longitude = geocoderResults[0].geometry.location.lng().toString();
      this.form.patchValue({ latitude, longitude }, { emitEvent: false });
      values = {
        ...values,
        latitude,
        longitude
      };
    }
    return values;
  }
}
