import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { get } from 'lodash';

import { AvatarSize, AvatarType, GreetingKey, User } from './../../../models';
import { AvatarConstants } from './avatar.constants';

/**
 * The avatar component is used to display circular user profile pictures
 * that can be simplistic grey picture (default) or user photos.
 * If the user property is defined, the avatar url will be used but replaced
 * with a gender avatar if no url found. The default property is used to
 * display a default/fallback avatar.
 */
@Component({
  selector: 'iad-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnChanges {
  @ViewChild('avatar', { static: true })
  avatar: ElementRef;

  /** The default avatar used as fallback. */
  @Input() default?: AvatarType;

  /** The user object used to retrieve the avatar. */
  @Input() user?: Partial<User>;

  /** The avatar size used to render the right CSS styles. */
  @Input() size?: AvatarSize;

  /** The string that contains concatenated CSS classes. */
  classes: string;

  constructor(private renderer: Renderer2) {
    this.default = AvatarType.MALE;
    this.size = AvatarSize.LARGE;
    this.classes = `iad-avatar-${this.default} iad-avatar-${this.size}`;
  }

  /**
   * When at least one data-bound properties has changed, update avatar
   * classes. Afterwise, update avatar URL only if the user has changed.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user || changes.default || changes.size) {
      this.classes = this.getAvatarClasses();
      this.setBackground();
    }
  }

  /**
   * Returns the size property if it's a valid `AvatarSize` value, otherwise `AvatarSize.LARGE`.
   */
  getAvatarSize(): AvatarSize {
    return Object.values(AvatarSize).includes(this.size) ? this.size : AvatarSize.LARGE;
  }

  /**
   * Returns the avatar type according to the type and the gender of the user.
   * If no avatar found, fallback on the default property.
   */
  getAvatarType(): AvatarType {
    return !!this.user
      ? this.user.type === AvatarType.ORGANIZATION
        ? AvatarType.ORGANIZATION
        : this.getGenderAvatarType() || this.default
      : this.default;
  }

  /**
   * Returns avatar classes as string format.
   */
  getAvatarClasses(): string {
    return `iad-avatar-${this.getAvatarSize()}`;
  }

  /**
   * Returns the avatar type according to the user greeting.
   * If the greeting is undefined or invalid, return null.
   */
  getGenderAvatarType(): AvatarType | null {
    return AvatarConstants.gendersMap.get(this.getGreetingsKey()) || null;
  }

  /**
   * Extracts the last chunk from the greeting label key.
   * For example, if the label key is `'references.greetings.mr'`,
   * the greeting key will be `'mr'`.
   */
  getGreetingsKey(): GreetingKey | null {
    return (
      get(this.user, 'greetings.labelKey', '')
        .split('.')
        .pop() || null
    );
  }

  /**
   * Handle an edge case with the backend api. Api can return avatar file which don't exists (HTTP 404 code).
   * So we need to set a background fallback if the image cannot be load, otherwise we got a blank broken image.
   */
  setBackground(): void {
    const type = this.getAvatarType();
    const defaultUrl = `url(/assets/avatar-${type}.${type === AvatarType.ORGANIZATION ? 'svg' : 'png'})`;
    this.renderer.setStyle(
      this.avatar.nativeElement,
      'background-image',
      this.user && this.user.avatar ? `url(${this.user.avatar}), ${defaultUrl}` : `${defaultUrl}`
    );
  }
}
