import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';

import { TopbarEnum } from '../../components/layouts/topbar/topbar.enum';
import { Concession } from '../../models/concession/concession.interface';
import { TopbarItem } from '../../models/topbar/topbar.interface';
import { User } from '../../models/user/user.interface';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from '../notifications/notification.service';
import { RackspaceService } from '../rackspace/rackspace.service';
import { UrlService } from '../url/url.service';
import { ConcessionSlug } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class TopBarService {
  concession: Concession;
  flags: Array<string>;
  flagsPattern: RegExp;
  flagsMapping: Map<string, string>;
  deactivatedItems: Map<string, boolean>;
  itemCounters: Map<string, number>;

  /**
   * Constructor
   * @param http - http client
   * @param authService - auth service
   * @param rackspaceService - rackspace service
   * @param notificationService - notification service
   * @param urlService - url service
   */
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private rackspaceService: RackspaceService,
    private notificationService: NotificationService,
    private urlService: UrlService
  ) {
    this.itemCounters = new Map();
  }

  /**
   * Method used to fetch the topbar items according to the given user profile. This the FrontEnd responsability
   * to replace external links base path.
   *
   * @param app - The user profile string value.
   * @returns The observable that emits next Array<TopbarItem> value.
   */
  getItems(app: string, slug: string, user: User): Observable<Array<TopbarItem>> {
    this.initItemsMapping(slug, user);

    // Let's fetch the topbar config.
    return forkJoin([
      this.http.get(`assets/mocks/topbar-default-config.json`),
      this.http.get(`assets/mocks/topbar-${app}-${slug}.json`)
    ]).pipe(
      map(([defaultConfig, concessionConfig]: [Array<TopbarItem>, Array<TopbarItem>]): Array<TopbarItem> => {
        return this.mapItems(merge(defaultConfig, concessionConfig));
      })
    );
  }

  /**
   * Initialize convenient members.
   */
  initItemsMapping(slug: string, user: User): void {
    // First of all, we define the replacing flags from the environment urls config.
    this.flags = this.urlService.getUrls().map((url: any) => `${url.id}`);
    this.flags.push('easymovie', 'portugalEasymovie');
    // From the flags array we build the replacing pattern.
    this.flagsPattern = new RegExp(this.flags.map((flag: string) => `(%${flag}Url%)`).join('|'));

    // Now we need to define the mapping between replacing flags and corresponding base path.
    this.flagsMapping = new Map<string, string>(
      this.flags.map<[string, string]>((id: string) => [id, this.urlService.get(id, slug)]))
      .set('cdi', this.authService.getCdiUrl())
      .set('portugalEasymovie', this.buildEasyMovieUrl(slug, user))
      .set('easymovie', this.buildEasyMovieUrl(slug, user));

    // Keep track of specific item active status.
    this.deactivatedItems = new Map<string, boolean>([
      [TopbarEnum.CITYSCAN, !this.authService.hasCityScanAccount()],
      [TopbarEnum.ELEARNING, !this.authService.has360learningAccount()]
    ]);
  }

  /**
   * Build EasyMovie url according concession.
   *
   * @param slug - The concession.
   * @param user - Current user.
   * @returns Url for EasyMovie app.
   */
  buildEasyMovieUrl(slug: string, user: User): string {
    const { emailProfessional, firstName, lastName, phone, sector, addressPersonal } = user;

    if (slug === ConcessionSlug.PORTUGAL) {
      return `https://iad.easy.movie/pt/?email=${emailProfessional}`;
    } else if (slug === ConcessionSlug.FRANCE) {
      const frParams = {
        ...(emailProfessional ? { email: emailProfessional } : {}),
        ...(firstName ? { firstName } : {}),
        ...(lastName ? { lastName } : {}),
        ...(phone ? { telephone: phone } : {}),
        ...(sector && sector.city ? { nameAgency: sector.city } : {}),
        ...(addressPersonal ? {
          addressAgency: `${addressPersonal.address1}`,
          city: addressPersonal.city,
          postalCode: addressPersonal.postalCode
        } : {}),
      };

      const params = new HttpParams({fromObject: frParams});
      return `https://iad.easy.movie/?${params}`;
    }
  }

  /**
   * This method format topbar items.
   *
   * @param items - The raw item collection to format.
   * @param parent - The parent item of the current item collection.
   * @returns The mapped item collection.
   */
  mapItems(items: Array<TopbarItem>, parent: TopbarItem = null): Array<TopbarItem> {
    return (items || [])
      .filter((item: TopbarItem) => item.active && !this.deactivatedItems.get(item.name))
      .map((item: TopbarItem) => this.mapItem(item, parent));
  }

  /**
   * This method format topbar item. We need to format url to replace the base path flags and then loop
   * through children to do the same.
   *
   * @param item - The item to format.
   * @param parent - The parent item to link with its given child.
   * @returns The mapped item.
   */
  mapItem(item: TopbarItem, parent: TopbarItem): TopbarItem {
    return {
      ...item,
      parent,
      url: item.url ? item.url.replace(this.flagsPattern, this.flagsPatternReplacer) : null,
      ng1Url: item.ng1Url ? item.ng1Url.replace(this.flagsPattern, this.flagsPatternReplacer) : null,
      children: item.children && item.children.length > 0 ? this.mapItems(item.children, item) : null
    };
  }

  /**
   * Get the topbar item counters observable that emits the next item collection with updated counter value.
   *
   * @param user - The current connected user..
   * @param items - The topbar item collection to update.
   * @returns The item counters Observable.
   */
  getItemCounters(user: User, items: Array<TopbarItem>): Observable<Array<TopbarItem>> {
    return forkJoin([
      this.notificationService.getNotificationsCount()
    ]).pipe(
      tap(([notificationsCount]: [number, number]): void => {
        this.itemCounters.set(TopbarEnum.NOTIFICATIONS, notificationsCount);
        this.updateItemCounters(items);
      }),
      mapTo(items)
    );
  }

  /**
   * Recursively update item counter attributes. We are visiting the topbar item tree and sum up counters value.
   *
   * @param items - The tobar item collection to visit.
   * @returns The total item counter value.
   */
  private updateItemCounters(items: Array<TopbarItem>): number {
    return (items || []).reduce((totalCount: number, item: TopbarItem) => {
      item.counter = item.children ? this.updateItemCounters(item.children) : this.itemCounters.get(item.name) || 0;
      return totalCount + item.counter;
    }, 0);
  }

  /**
   * Helper method used for url flags replacement.
   *
   * @param args - The replacing parameters including captures.
   * @returns The replaced string value.
   */
  private flagsPatternReplacer = (...args: Array<string>): string => {
    return this.flagsMapping.get(
      this.flags[args.slice(1, this.flags.length + 1).findIndex((value: string) => value !== undefined)]
    );
  }
}
