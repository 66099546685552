import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { EMPTY, Observable, timer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LoadingDialogConstants } from './loading-dialog.constants';
import { MatSpinnerMode, LoadingDialogPayload } from '../../../models';

@Component({
  selector: 'iad-loader-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingDialogComponent implements OnInit {
  MatSpinnerMode = MatSpinnerMode;
  LoadingDialogConstants = LoadingDialogConstants;

  loaderStepTime: number;
  $loaderPercent: Observable<number>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: LoadingDialogPayload) {
    this.loaderStepTime = LoadingDialogConstants.LOADER_STEP_TIME;
  }

  ngOnInit() {
    this.$loaderPercent =
      this.data.mode === MatSpinnerMode.DETERMINATE
        ? timer(0, this.loaderStepTime).pipe(
            filter(index => index * this.loaderStepTime < this.data.loadingTime),
            map(index => ((index * this.loaderStepTime) / this.data.loadingTime) * 100),
            filter(percent => percent < LoadingDialogConstants.LOADER_MAX_PERCENT)
          )
        : EMPTY;
  }
}
