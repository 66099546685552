import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '../../../material.module';
import { NavigationService } from '../../../services/menu/navigation.service';
import { AvatarModule } from '../../ui/avatar/avatar.module';
import { MenuComponent } from './menu.component';

@NgModule({
  imports: [CommonModule, MaterialModule, AvatarModule],
  declarations: [MenuComponent],
  exports: [MenuComponent],
  providers: [NavigationService]
})
export class MenuModule {}
