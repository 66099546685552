import { Component, OnInit } from '@angular/core';

import { GtmHelper, DataDogHelper } from '@iad-digital/iad-ngx-core';

@Component({
  selector: 'iad-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private gtm: GtmHelper, private datadog: DataDogHelper) {}

  /**
   * init App Component
   */
  ngOnInit(): void {
    this.gtm.addGtmEvent();
    this.datadog.setDataDogUser();
  }
}
