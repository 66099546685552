import { FileMimeType, UploaderFileState, UploadStatus } from '../../models';

export const DEFAULT_SIZE_MAX = 10_000_000;

export const READY_TO_UPLOAD_STATE = {
  status: UploadStatus.READY,
  progression: 0,
  loaded: 0
} as UploaderFileState;
export const UPLOADED_STATE = {
  status: UploadStatus.SUCCESS,
  progression: 100
} as UploaderFileState;

export const FILE_MIME_TYPE_MAP = new Map<FileMimeType, string>(
  [
    [ FileMimeType.IMAGE_GIF, 'GIF' ],
    [ FileMimeType.IMAGE_JPEG, 'JPEG' ],
    [ FileMimeType.IMAGE_PNG, 'PNG' ],
    [ FileMimeType.PDF, 'PDF' ]
  ]
);
