import { Subject, Observable } from 'rxjs';

export class MockRouter {
  subject: Subject<any>;
  events: Observable<any>;

  navigate = jasmine.createSpy('navigate');

  constructor() {
    this.subject = new Subject();
    this.events = this.subject.asObservable();
  }
}
