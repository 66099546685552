import { Injectable } from '@angular/core';
import { Reference } from '@iad-digital/iad-ngx-core';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ConcessionSlug, Locale } from '../../models';
import { ApiError } from '../../models/api-response';
import { Country } from '../../models/locality/country.interface';
import { ReferencesSlugs } from '../../models/references';
import { References } from '../../models/references/references.interface';
import { ApiService } from '../api/api.service';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {
  phoneNumberUtil: PhoneNumberUtil;

  constructor(private apiService: ApiService, private utilService: UtilService) {
    this.phoneNumberUtil = PhoneNumberUtil.getInstance();
  }

  /**
   *
   * Fetch reference by name.
   *
   * @param string name - The reference name
   * @param object|null options - The options property with concession and language property.
   * @returns Observable - Observable of references
   */
  getReferencesByName(name: string, options: { concession?: string; language?: string }): Observable<Array<any>> {
    const params = options;
    return this.apiService.get<Array<any>>('wis', `/directory/references/${name}`, { params });
  }

  /**
   *
   * Fetch references by concession slug.
   *
   * @param ReferencesSlugs[] referencesSlugs - List of references to fetch
   * @param ConcessionSlug concessionSlug - Concession slug
   * @param string locale - locale language
   * @param object filters - The filters property with direction and sort property.
   * @returns Observable - Observable of references
   */
  getReferencesByConcessionSlug(
    referencesSlugs: Array<ReferencesSlugs | string>,
    concessionSlug: ConcessionSlug,
    locale: Locale,
    filters: {
      direction?: string;
      sort?: boolean;
    } = {}
  ): Observable<References> {
    const params = {
      'referencesSlugs[]': referencesSlugs,
      locale,
      ...filters
    };
    return this.apiService
      .get<References>('wis', `/directory/references/concessions/${concessionSlug}/references`, { params })
      .pipe(
        tap((references: References) => {
          if (referencesSlugs.indexOf(ReferencesSlugs.COUNTRY) !== -1) {
            (references[ReferencesSlugs.COUNTRY] || []).map((country: Country) => {
              country.code = parseInt(this.phoneNumberUtil.getCountryCodeForRegion(country.isoCode), 10);
            });
          }
        }),
        catchError((error: ApiError) => throwError(error.message))
      );
  }


  /**
   *
   * Fetch reference by name.
   *
   * @param string name - The reference name
   * @param string - The concession slug property with concession and language property.
   * @returns Observable - Observable of references
   */
   getReferenceByNameV2(name: string, concessionSlug?: string): Observable<References> {
    const params = {
      concession: concessionSlug
    }
   return this.apiService.get<Array<any>>('wis', `/directory/references/v2/${name}`, { params })
   .pipe(
     map((references: Array<Reference>) => {
       // Format the return like old reference format
       return  {
         [name] : references.map((reference: any) => ({
         id: reference.code,
         label: reference.label,
         labelKey: reference.labelKey
         }))
       }
     })
   );
 }
}
