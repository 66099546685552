import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';

import { BreadCrumb, BreadcrumbEditDynLabel } from '../../../models/breadcrumb';
import { BreadcrumbService } from '../../../services/breadcrumb/breadcrumb.service';
import { LocaleService } from './../../../services/locale/locale.service';

@Component({
  selector: 'iad-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  // Array of breadcrumbs
  breadcrumbs: Array<BreadCrumb>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private bcService: BreadcrumbService,
    private localeService: LocaleService
  ) {
    this.breadcrumbs = [];
    const navigationEnd$ = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      distinctUntilChanged()
    );
    combineLatest([
      navigationEnd$,
      this.translate.onLangChange.pipe(startWith(this.localeService.defaultLocale))
    ]).subscribe(() => (this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root)));

    this.bcService.breadcrumbEdit$.subscribe((bcEdit: BreadcrumbEditDynLabel) => {
      const breadcrumb = this.breadcrumbs.find((bc: BreadCrumb) => bc.id === bcEdit.breadcrumbId);
      if (breadcrumb) {
        breadcrumb.labelDyn = bcEdit.value;
      }
    });
  }

  /**
   * Recursive method to build breadcrumb within router urls.
   * @param route - current route in recursion loop.
   * @param url - recursive url according routes.
   * @param breadcrumbs  - breadcrumbs array.
   */
  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    if (route.snapshot.url.length) {
      url += `/${route.snapshot.url.join('/')}`;
    }
    if (
      route.snapshot.routeConfig &&
      route.snapshot.routeConfig.data &&
      route.snapshot.routeConfig.data.breadcrumb
    ) {
      const breadcrumb = { ...route.snapshot.routeConfig.data.breadcrumb } as BreadCrumb;
      breadcrumb.defaultLabel = this.translate.instant(breadcrumb.defaultLabel);
      breadcrumb.url = url;
      breadcrumbs = [...breadcrumbs, breadcrumb];
    }
    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, url, breadcrumbs);
    }
    return breadcrumbs;
  }
}
