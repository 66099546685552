import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  CollaboratorStatusGuard,
  BreadCrumb,
  BreadCrumbCommonL10n,
  CdiRedirectResolver,
  ContentComponent,
  Error403Component,
  Error404Component,
  RedirectComponent,
  Error403BlockedAgentComponent,
} from '@iad-digital/iad-ngx-core';

import { BreadCrumbBillingL10n } from './billing/shared/breadcrumb';
import { BreadCrumbExportBillingL10n } from './export-billing/shared/breadcrumb';

/**
 * Helper function to redirect on Cdi App.
 * @param path - path
 * @param externalUrl - external cdi url
 * @param breadcrumb - breadcrumb label
 */
export function redirectToCdi(
  externalUrl: string,
  breadcrumb: string | Array<string>
): { resolve: any; data: any } {
  return {
    resolve: {
      url: CdiRedirectResolver
    },
    data: {
      breadcrumb: new BreadCrumb('home', breadcrumb),
      externalUrl
    }
  };
}

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'agent',
        data: {
          breadcrumb: new BreadCrumb('agent', BreadCrumbCommonL10n.home),
          profile: 'agent'
        },
        component: ContentComponent,
        children: [
          {
            path: '',
            redirectTo: '/agent/home',
            pathMatch: 'full'
          },
          {
            path: 'home',
            component: RedirectComponent,
            ...redirectToCdi('home', BreadCrumbCommonL10n.home)
          },
          {
            path: 'payment-interface',
            canActivate: [CollaboratorStatusGuard],
            loadChildren: () =>
              import('./payment-interface/payment-interface.module').then(
                m => m.PaymentInterfaceModule
              )
          }
        ]
      },
      {
        path: 'headquarter',
        component: ContentComponent,
        data: {
          breadcrumb: new BreadCrumb('headquarter', BreadCrumbCommonL10n.home),
          profile: 'headquarter'
        },
        children: [
          {
            path: '',
            redirectTo: '/headquarter/home',
            pathMatch: 'full'
          },
          {
            path: 'home',
            component: RedirectComponent,
            ...redirectToCdi('home', BreadCrumbCommonL10n.home)
          },
          {
            path: 'backoffice',
            data: {
              breadcrumb: new BreadCrumb(
                'backoffice',
                BreadCrumbCommonL10n.backoffice
              )
            },
            children: [
              {
                path: '',
                redirectTo: '/headquarter/backoffice/home',
                pathMatch: 'full'
              },
              {
                path: 'home',
                component: RedirectComponent,
                ...redirectToCdi('home', BreadCrumbCommonL10n.home)
              },
              {
                path: 'billing',
                data: {
                  breadcrumb: new BreadCrumb(
                    'billing',
                    BreadCrumbBillingL10n.billing
                  )
                },
                loadChildren: () =>
                  import('./billing/billing.module').then(m => m.BillingModule)
              },
              {
                path: 'payment-interface',
                loadChildren: () =>
                  import('./payment-interface/payment-interface.module').then(
                    m => m.PaymentInterfaceModule
                  )
              },
              {
                path: 'export-billing',
                data: {
                  breadcrumb: new BreadCrumb(
                    'exportBilling',
                    BreadCrumbExportBillingL10n.exportBilling
                  )
                },
                loadChildren: () =>
                  import('./export-billing/export-billing.module').then(
                    m => m.ExportBillingModule
                  )
              },
              {
                path: 'approximation',
                loadChildren: () =>
                  import('./approximation/approximation.module').then(
                    m => m.ApproximationModule
                  )
              }
            ]
          }
        ]
      },
      {
        path: '403',
        component: Error403Component
      },
      {
        path: '403-blocked',
        component: Error403BlockedAgentComponent
      }
    ]
  },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
