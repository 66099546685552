import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';
import { get } from 'lodash';

import { TableConstants } from '../../../constants/table/table.constants';
import { TableCellDirective } from '../../../directives/table-cell/table-cell.directive';
import {
  TableAction,
  TableBodyColumn,
  TableColumn,
  TableColumnKey,
  TableCustomCellTemplate,
  TableEllipsis,
  TableEllipsisPosition,
  TableHeaderColumn,
  TablePageEvent,
  TablePagination,
  TableElementStatus,
} from '../../../models/table';

@Component({
  selector: 'iad-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent<T extends object> implements OnInit, OnChanges, AfterContentInit {
  /** The columns collection to display. */
  @Input() columns: Array<TableColumn<T>>;

  /** The item collection to inject in the table. */
  @Input() data: Array<T>;

  /** The ellipsis button configuration. */
  @Input() ellipsis: TableEllipsis<T>;

  /** The action collection to display as header buttons. */
  @Input() headerActions: Array<TableAction<T>>;

  /** Whether the table row dividers has to be hidden. */
  @Input() hideRowDividers: boolean;

  /** Whether the table is loading. */
  @Input() loading: boolean;

  /** The table pagination configuration. */
  @Input() pagination: TablePagination;

  /** Whether the actions column has to be shown. */
  @Input() showActionsColumn: boolean;

  /** Whether the checkbox column has to be shown. */
  @Input() showCheckboxColumn: boolean;

  /** A callback function to execute on each table element to retrieve their status. */
  @Input() getTableElementStatus: (element: T) => TableElementStatus;

  /** The event emitted when the table page changes. */
  @Output() page: EventEmitter<TablePageEvent>;

  /** The event emitted when the user selects an item in the table. */
  @Output() selection: EventEmitter<Array<T>>;

  /** The paginator instance that will be assigned to the dataSource. */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** The table cell query list needed to inject custom cell templates. */
  @ContentChildren(TableCellDirective) customCells: QueryList<TableCellDirective>;

  /** The host class list we need to apply to the table container element. */
  @HostBinding('class') @Input('class') set hostClassList(value: string) {
    this.classList = value;
  }

  bodyColumns: Array<TableBodyColumn | TableColumnKey<T>>;
  bodyColumnsWidth: Record<TableColumnKey<T>, { 'max-width.px': number }>;
  classList: string;
  customCellTemplate: TableCustomCellTemplate<T>;
  dataSource: MatTableDataSource<T>;
  headerColumns: Array<TableHeaderColumn>;
  initialized: boolean;
  selectedRows: SelectionModel<T>;
  tableBodyColumn: typeof TableBodyColumn;
  tableHeaderColumn: typeof TableHeaderColumn;

  /**
   * Whether the number of selected elements matches the total number of rows.
   */
  get isAllRowsSelected(): boolean {
    return this.selectedRows.selected.length === this.dataSource.data.length;
  }

  /**
   * Whether the ellipsis can be shown in the footer.
   */
  get showFooterEllipsis(): boolean {
    return this.isEllipsisPositionAvailable(TableEllipsisPosition.BOTTOM);
  }

  /**
   * Whether the ellipsis can be shown in the header.
   */
  get showHeaderEllipsis(): boolean {
    return this.isEllipsisPositionAvailable(TableEllipsisPosition.TOP);
  }

  constructor() {
    this.dataSource = new MatTableDataSource([]);
    this.pagination = {};
    this.page = new EventEmitter();
    this.selectedRows = new SelectionModel(true, []);
    this.selection = new EventEmitter();
    // Store enums references because we need to use them in template
    this.tableBodyColumn = TableBodyColumn;
    this.tableHeaderColumn = TableHeaderColumn;
  }

  /**
   * On changes, set new properties values.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      changes.pagination && this.setPagination();
      (changes.columns || changes.showCheckboxColumn || changes.showActionsColumn) && this.setBodyColumns();
      (changes.columns) && this.setBodyColumnsWidth();
      (changes.ellipsis || changes.headerActions) && this.setHeaderColumns();
      (changes.data || changes.pagination) && this.setData();
    }
  }

  /**
   * On initialization, set main properties.
   */
  ngOnInit(): void {
    this.setData();
    this.initializePaginator();
    this.setPagination();
    this.setBodyColumns();
    this.setBodyColumnsWidth();
    this.setHeaderColumns();
    this.initialized = true;
  }


  /**
   * After content initialization, set custom cell template map.
   */
  ngAfterContentInit(): void {
    this.setCustomCellTemplate();
  }

  /**
   * Get side class color if it is activated.
   * @param element - data row.
   */
  renderTableElementStatus(element: T): TableElementStatus {
    return this.getTableElementStatus(element);
  }

  /**
   * Sets the `dataSource.data` property to inject items into the table.
   */
  private setData(): void {
    this.dataSource.data = this.data;
  }

  /**
   * Sets body columns that will be displayed according to binded inputs.
   */
  private setBodyColumns(): void {
    this.bodyColumns = [
      ...(this.showCheckboxColumn ? [TableBodyColumn.CHECKBOX] : []),
      ...(this.columns || []).map(({ key }) => key),
      ...(this.showActionsColumn ? [TableBodyColumn.ACTIONS] : []),
    ];
  }

  /**
   * Sets the body columns width map.
   */
  private setBodyColumnsWidth(): void {
    this.bodyColumnsWidth = this.columns.reduce(
      (map, column) => ({
        ...map,
        [column.key]: column.maxWidth ? { 'max-width.px': column.maxWidth } : null
      }),
      {} as Record<TableColumnKey<T>, { 'max-width.px': number }>
    );
  }

  /**
   * Sets header columns that will be displayed according to binded inputs.
   */
  private setHeaderColumns(): void {
    this.headerColumns = [
      ...(this.showHeaderEllipsis ? [TableHeaderColumn.ELLIPSIS] : []),
      ...(this.headerActions ? [TableHeaderColumn.ACTIONS] : []),
    ];
  }

  /**
   * Defines the table paginator.
   * This method have to be only triggered once during the `OnInit` hook.
   */
  private initializePaginator(): void {
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Sets the pagination by merging the `pagination` binded input with
   * the `TableConstants.DEFAULT_PAGINATION` and assigns pagination
   * values to the `dataSource.paginator` object.
   */
  private setPagination(): void {
    this.pagination = { ...TableConstants.DEFAULT_PAGINATION, ...this.pagination };
    Object.keys(this.pagination).forEach(key => this.dataSource.paginator[key] = this.pagination[key]);
  }

  /**
   * Sets the custom cell template mapping to retrieve each `TemplateRef`.
   */
  private setCustomCellTemplate(): void {
    this.customCellTemplate = this.customCells.reduce(
      (map, cell) => ({...map, [cell.column]: cell.template }),
      {}
    );
  }

  /**
   * Toggles a row and emits a selection event.
   * @param row - The row to toggle
   */
  toggleRow(row: T): void {
    this.selectedRows.toggle(row);
    this.selection.emit(this.selectedRows.selected);
  }

  /**
   * Selects all rows if they are not all selected. Otherwise clear selection.
   * After that, emits a selection event.
   */
  toggleAllRows(): void {
    this.isAllRowsSelected
      ? this.selectedRows.clear()
      : this.selectedRows.select(...this.dataSource.data);
    this.selection.emit(this.selectedRows.selected);
  }

  /**
   * Emits a page event.
   * @param event - The paginator page event value
   */
  emitPageEvent(event: PageEvent): void {
    this.page.emit({
      ...event,
      isLastPage: !this.dataSource.paginator.hasNextPage(),
      numberOfPages: this.dataSource.paginator.getNumberOfPages()
    });
  }

  /**
   * Returns `true` if the ellipsis can be positioned at the given position. Else `false`.
   * @param position - The ellipsis position to check
   */
  isEllipsisPositionAvailable(position: TableEllipsisPosition.TOP | TableEllipsisPosition.BOTTOM): boolean {
    const currentPosition = get(this.ellipsis, 'position', null);
    return currentPosition === position || currentPosition === TableEllipsisPosition.BOTH;
  }

  /**
   * Returns the column `max-width` CSS object.
   * @param column - The table column
   */
  getColumnMaxWidth(column: TableColumn<T>): { 'max-width': string } {
    return { 'max-width': column.maxWidth ? `${column.maxWidth}px` : 'initial' };
  }
}
