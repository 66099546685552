export class LocaleConstant {
  // locale supported in application.
  public static SUPPORTED_LOCALES = [{
    id: 'en_GB',
    label: 'United Kingdom'
  }, {
    id: 'fr_FR',
    label: 'France'
  }, {
    id: 'es_ES',
    label: 'Español'
  }, {
    id: 'it_IT',
    label: 'Italia'
  }, {
    id: 'pt_PT',
    label: 'Portugal'
  }, {
    id: 'en_US',
    label: 'Florida'
  }];
}
