import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../../models/user/user.interface';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class RackspaceService {
  constructor(private apiService: ApiService) {}

  /**
   * Get web mail items number
   * @param user - user Object
   */
  getNumWebmailMessagesInfos(user: User): Observable<any> {
    const [mailboxName, domain]: any = user.emailProfessional.split('@');

    return this.apiService.get<Array<any>>(
      'webmail',
      `/directory/rackspace/mailbox/${domain}/${mailboxName}/messagesnumber`
    );
  }

  /**
   * Get the web mail count observable that emits the next number of incoming web mail value.
   *
   * @returns The web mail count observable.
   */
  getNewWebmailMessagesCount(user: User): Observable<number> {
    return this.getNumWebmailMessagesInfos(user).pipe(map((data: any) => data.newMessages));
  }

  /**
   * Method to recover password for web mail.
   */
  generateNewPassword(): Observable<any> {
    return this.apiService.get('wis', `/directory/rackspace/mailbox/recover-password`);
  }
}
