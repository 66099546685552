export class MockWindowRef {
  nativeWindow = {
    pageYOffset: 0,
    location: jasmine.createSpyObj(['href']),
    open: jasmine.createSpy('open'),
    URL: {
      createObjectURL: jasmine.createSpy('createObjectURL'),
      revokeObjectURL: jasmine.createSpy('revokeObjectURL')
    },
    history: { back: jasmine.createSpy('history') },
    ga: jasmine.createSpy('ga') // google analytics function
  };
}
