import { Pipe, PipeTransform } from '@angular/core';

import { UtilService } from '../../services/util/util.service';

@Pipe({
  name: 'highlight'
})
export class HighLightPipe implements PipeTransform {
  constructor(private utilService: UtilService) {}

  /**
   * Main method of Highlight pipe
   *
   * @param value - the default value
   * @param term - term to make bold
   */
  transform(value: string, term: string): string {
    const regex = new RegExp(`(${this.utilService.escapeRegExp(term)})`, 'gi');
    return value.replace(regex, `<b>$1</b>`);
  }
}
