import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '../../../material.module';
import { ActionMenuComponent } from './action-menu.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [ActionMenuComponent],
  exports: [ActionMenuComponent]
})
export class ActionMenuModule {}
