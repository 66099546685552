import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IadExtendedCVAField } from '../../../abstracts/extended-cva-field/extended-cva-field.class';
import { CountryCurrency, CurrencyInputDisplay, IadMoneyAmount, Locale } from '../../../models';
import { CustomValidators } from '../../../validators';

@Component({
  selector: 'iad-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrencyInputComponent extends IadExtendedCVAField<IadMoneyAmount> implements OnInit, OnDestroy, OnChanges {
  /** The locale used to format the amount according to */
  @Input() locale: Locale;

  /** Decimal representation options (see CurrencyPipe from Angular official doc) */
  @Input() digitsInfo: string;

  /**  The format for the currency indicator (see CurrencyPipe from Angular official doc) */
  @Input() displaySuffix: CurrencyInputDisplay | string;

  /** If true, show the currency symbol as prefix instead of currency code */
  @Input() prefixSymbol: boolean;

  @ViewChild(MatInput, { static: true }) currencyInput: MatInput;
  value: IadMoneyAmount;
  prefix: string;
  unsubscribe$: Subject<void>;
  defaultErrors: Record<string, string>;

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private currencyPipe: CurrencyPipe,
    cdr: ChangeDetectorRef
  ) {
    super(new FormControl(null, CustomValidators.validateCurrency), ngControl, cdr);
    this.locale = Locale.FR_FR;
    this.digitsInfo = '';
    this.displaySuffix = '';
    this.prefixSymbol = false;
    this.prefix = CountryCurrency.EUR;
    this.unsubscribe$ = new Subject<void>();
    this.defaultErrors = {
      nan: 'Not a valid number'
    };
  }

  /**
   * Life cycle OnInit
   * Subscribe to formControl valueChanges to prevent the parent control that the value has changed.
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.setPrefixValue();
    this.mergeDefaultErrors();

    this.formControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(newValue => {
      const cleanValue = newValue && this.getCleanValue(newValue);
      this.value = {
        ...this.value,
        amount: cleanValue === 0 ? 0 : cleanValue || newValue
      };

      this.updateError();
      this.onChange(this.value);
    });
  }

  /**
   * Life cycle OnChanges
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.value && (changes.displaySuffix || changes.locale)) {
      this.onBlur(this.value.amount.toString());
    }
    if (this.value && (changes.prefixSymbol || changes.locale)) {
      this.setPrefixValue();
    }
  }

  /**
   * Cleanup subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * From ControlValueAccessor interface.
   * @param value - the value received from the parent control.
   */
  writeValue(value: IadMoneyAmount): void {
    if (!value) {
      return ;
    }
    this.value = value;
    // We must use setTimeout here because of a component's inputs access problem.
    // As we set valueAccessor manually (IadExtendedCVAField), ngOnInit is fired after writeValue.
    // Therefore, we can only get default properties value, so getDisplayAmount() will get displaySuffix as ''.
    // https://github.com/angular/angular/issues/29218
    setTimeout(() => {
      const newValue = this.isNumber(value.amount) ? this.getDisplayAmount(value.amount) : value.amount;
      this.formControl.setValue(newValue, { emitEvent: false });
      this.updateError();
    });
  }

  /**
   * When focus on the input, set his value to the saved clean value.
   */
  onFocus(): void {
    this.formControl.setValue(this.value.amount, { emitEvent: false });
  }

  /**
   * If the value is a valid number, format it and assign it to the formControl
   * Assign the new internal value
   * @param val - Value received from the input blur event.
   */
  onBlur(val: string): void {
    if (this.isNumber(val)) {
      const cleanValue = this.getCleanValue(val);
      this.currencyInput.value = this.getDisplayAmount(cleanValue);
    } else {
      this.currencyInput.value = this.value.amount ? this.value.amount.toString() : null;
    }
  }

  /**
   * Use the currencyPipe to format the value.
   * @param value - The value that we need to format
   */
  private getDisplayAmount(value: number | string): string {
    return this.currencyPipe.transform(
      value,
      this.value.currencyCode || 'EUR',
      this.displaySuffix,
      this.digitsInfo,
      this.locale
    );
  }

  /**
   * Return true if the parameter is a valid number (string or number type)
   * @param val - a string or number.
   */
  private isNumber(val: string | number): boolean {
    return !Number.isNaN(typeof val === 'string' ? Number.parseFloat(val) : val);
  }

  /**
   * Set prefix as symbol or currency code (ISO 4217)
   */
  private setPrefixValue(): void {
    this.prefix = this.prefixSymbol ?
    getCurrencySymbol(this.value.currencyCode, 'wide', this.locale) :
    this.value.currencyCode;
  }

  /**
   * Return a clean value and converted into number.
   * @param val - The value.
   */
  private getCleanValue(val: string): number | null {
    return val
    ? parseFloat(parseFloat(val.trim().replace(/\,/g, '.').replace(/\s/g, '')).toFixed(6))
    : null;
  }

  /**
   * Merge default component's errors and custom parent's errors.
   */
  private mergeDefaultErrors(): void {
    this.errors = {
      ...this.defaultErrors,
      ...this.errors
    };
  }
}
