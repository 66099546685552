import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isObject } from 'lodash';
import { filter, map, tap } from 'rxjs/operators';

import { CloseDialogPayload } from './../../models/dialog/dialog.interface';
import * as fromDialogActions from './dialog.actions';

@Injectable()
export class DialogEffects {
  closeDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDialogActions.closeDialog),
      map(action => action.payload),
      tap((payload: string | CloseDialogPayload) => {
        const dialog = this.dialog.getDialogById(isObject(payload) ? payload.dialogId : payload);
        if (dialog) {
          dialog.close();
        }
      }),
      filter((payload: string | CloseDialogPayload) => isObject(payload)),
      map((payload: CloseDialogPayload) => payload.afterCloseAction)
    )
  );

  constructor(private actions$: Actions, private dialog: MatDialog) {}
}
