export enum MatThemePalette {
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARN = 'warn'
}

export enum MatSpinnerMode {
  DETERMINATE = 'determinate',
  INDETERMINATE = 'indeterminate'
}
