import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IadExtendedCVAField } from '../../../abstracts/extended-cva-field/extended-cva-field.class';

/**
 * The iad datepicker input component uses mat-datepicker component from Angular Material.
 * It simplifies the creation of a mat-datepicker instance
 */
@Component({
  selector: 'iad-datepicker-input',
  templateUrl: './datepicker-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerInputComponent extends IadExtendedCVAField<moment.Moment> implements OnInit, OnDestroy {
  /** The minimum valid date. */
  @Input() min?: moment.Moment;

  /** The maximum valid date. */
  @Input() max?: moment.Moment;

  /** Emit the new selected date */
  @Output() dateChange: EventEmitter<moment.Moment>;

  /** Use to unsubscribe the FormControl subscription */
  private unsubscribe$: Subject<void>;


  constructor(
    @Self() @Optional() ngControl: NgControl,
    cdr: ChangeDetectorRef
  ) {
    super(new FormControl(), ngControl, cdr);
    this.unsubscribe$ = new Subject();
    this.dateChange = new EventEmitter();
  }

  /**
   * OnInit hook.
   */
  ngOnInit() {
    super.ngOnInit();
    this.label = this.label || 'Date';
    this.placeholder = this.placeholder || 'Please enter a date.';
    this.formControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((value: moment.Moment) => {
      this.updateError();
      this.onChange(value);
    });
  }

  /**
   * Cleanup subscriptions.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Set the control value.
   * @param value - the value received from the parent.
   */
  writeValue(value: moment.Moment | string): void {
    // We must use setTimeout here because of a component's inputs access problem.
    // As we set valueAccessor manually (IadExtendedCVAField), ngOnInit is fired after writeValue.
    // Therefore, we can only get default property value and formatDate() won't be called.
    // https://github.com/angular/angular/issues/29218
    setTimeout(() => {
      this.formControl.setValue(moment(value));
      this.errors && this.updateError();
    });
  }

  /**
   * Emit the new selected date
   * @param event - Event from mat datepicker input
   */
  onDateChange(event: MatDatepickerInputEvent<moment.Moment>): void {
    this.dateChange.emit(event.value);
  }
}
