import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, mapTo, switchMap, tap } from 'rxjs/operators';

import { CORE_CONFIG, CoreConfig } from '../../models/core-config/core-config.interface';
import { AuthService } from '../../services/auth/auth.service';
import { LocaleService } from '../../services/locale/locale.service';
import * as MenuActions from '../menu/menu.actions';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
  getUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.getUser),
    switchMap(() => this.authService.getUser().pipe(
      tap(user => this.authService.setUser(user)),
      switchMap(user => this.localeService.setLocale(this.authService.computeUserLocale()).pipe(
        mapTo(user))
      ),
      filter(_ => !this.config.enabledJwt),
      switchMap(user => [
        UserActions.getUserSuccess({ user }),
        MenuActions.getMenu({ concession: user.concession })
      ]),
      catchError(error => of(UserActions.getUserFailure({ error })))
    ))
  ));

  constructor(
    @Inject(CORE_CONFIG) private config: CoreConfig,
    private actions$: Actions,
    private authService: AuthService,
    private localeService: LocaleService
  ) {}
}
