import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '../../../material.module';
import { LoaderComponent } from './loader.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [LoaderComponent],
  exports: [LoaderComponent]
})
export class LoaderModule {}
