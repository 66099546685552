import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, switchMap, tap } from 'rxjs/operators';

import { ALL_QUALIFICATIONS, CollaboratorQualifications, PaginatedResult, User } from '../../../models';
import { CollaboratorsService } from '../../../services/collaborators/collaborators.service';

const COMPONENT_SELECTOR = 'iad-collaborators-autocomplete';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './collaborators-autocomplete.component.html',
  styleUrls: ['./collaborators-autocomplete.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CollaboratorsAutocompleteComponent }]
})
export class CollaboratorsAutocompleteComponent
  implements OnInit, OnDestroy, DoCheck, AfterViewInit, ControlValueAccessor, MatFormFieldControl<User> {
  static nextId = 0;
  @HostBinding() id: string;
  @HostBinding('attr.aria-describedby') describedBy: string;
  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }
  @Input() showIdOldArchi: boolean;
  @Input() qualifications: Array<CollaboratorQualifications | string>;
  @Input()
  get value(): User | null {
    return this.input.value;
  }
  set value(collaborator: User | null) {
    this.input.setValue(collaborator);
    this.stateChanges.next();
  }
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.input.disable() : this.input.enable();
    this.stateChanges.next();
  }
  get empty(): boolean {
    return !this.input.value;
  }
  controlType: string;
  focused: boolean;
  stateChanges: Subject<void>;
  input: FormControl;
  collaborators$: Observable<Array<User>>;
  isSearching: boolean;
  internalError: boolean;
  errorState: boolean;
  onChange: (_: User) => void;
  onTouched: (_: User) => void;
  private _placeholder: string;
  private _required: boolean;
  private _disabled: boolean;

  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl,
    private collabService: CollaboratorsService,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    this._required = false;
    this._disabled = false;
    this.describedBy = '';
    this.internalError = false;
    this.controlType = COMPONENT_SELECTOR;
    this.focused = false;
    this.qualifications = [...ALL_QUALIFICATIONS];
    this.isSearching = false;
    this.id = `collaborators-autocomplete-${CollaboratorsAutocompleteComponent.nextId++}`;
    this.stateChanges = new Subject<void>();
    this.input = new FormControl(null);
    this.onChange = (_: User) => {};
    this.onTouched = (_: User) => {};
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(elRef.nativeElement, true).subscribe((origin: FocusOrigin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  /**
   * Life cycle onInit
   */
  ngOnInit(): void {
    this.onInputChanged();
  }

  /**
   * Life cycle afterViewInit
   */
  ngAfterViewInit(): void {
    if (this.ngControl && this.ngControl.control) {
      const validators = this.ngControl.control.validator
        ? [this.ngControl.control.validator, this.internalErrorValidator]
        : [this.internalErrorValidator];
      this.ngControl.control.setValidators(validators);
    }
  }

  /**
   * Life cycle doCheck
   */
  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.errors && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  /**
   * Method set describe by ids.
   * @param ids - ids
   */
  setDescribedByIds(ids: Array<string>): void {
    this.describedBy = ids.join(' ');
  }

  /**
   * Method onContainer click
   * @param event - Mouse event
   */
  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }
  // ---------------------------

  // --- CONTROL VALUE ACCESSOR ---
  /**
   * Update model value after a blur event on our control "input"
   *
   * @param fn - Function called when the model must be changed after blur event
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Update model value from view to model
   *
   * @param fn any - Function called when the model value must be changed
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Write a new value from model to the form control "input"
   *
   * @param collaborator User- collaborator to set
   */
  writeValue(collaborator: User | null): void {
    this.value = collaborator;
  }

  /**
   * Disable the control according to isDisabled property.
   *
   * @param isDisabled boolean - tell us if the control must be disabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // ------------------------------

  /**
   * Method called when an option has been selected. Update the model with the selected value.
   *
   * @param event MatAutocompleteSelectedEvent - Selected event. Contain the selected option value
   */
  onCollaboratorSelected(event: MatAutocompleteSelectedEvent): void {
    if (event && event.option) {
      this.value = event.option.value;
      this.onChange(event.option.value);
    }
  }

  /**
   * Extract firstname and lastname from a collaborator and concat them
   *
   * @param collaborator User - current collaborator
   */
  formatCollaboratorName(collaborator: User): string | undefined {
    return collaborator
      ? `${collaborator.firstName} ${collaborator.lastName}${this.showIdOldArchi ? ` (${collaborator.idOldArchi})` : ''}`
      : undefined;
  }

  /**
   * Life cycle onDestroy
   */
  ngOnDestroy(): void {
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.complete();
  }

  /**
   * Validator used to check if an server error occurred
   */
  private internalErrorValidator = (): { internalError: boolean } | null => {
    return this.internalError ? { internalError: true } : null;
  }

  /**
   * Create the observable will emits collaborators according to the input value
   */
  private onInputChanged(): void {
    const filterSetValueAndNonObject = filter((value: string | User) => !!value && !!!(value instanceof Object));

    this.collaborators$ = this.input.valueChanges.pipe(
      debounceTime(500),
      tap((value: string | User) => {
        this.internalError = false;
        if (!value) {
          this.onChange(null);
        }
      }),
      filterSetValueAndNonObject,
      tap(() => {
        this.isSearching = true;
        this.onChange(null);
      }),
      switchMap((value: string) => {
        const search = !isNaN(+value) ? { localId: value } : { search: value };
        return this.collabService
          .getCollaborators(search, { qualifications: this.qualifications, numberPerPage: 50 })
          .pipe(
            map((paginatedCollaborators: PaginatedResult<User>) => paginatedCollaborators.result),
            catchError(() => {
              this.internalError = true;
              this.ngControl.control.updateValueAndValidity({ onlySelf: true });
              return of([]);
            }),
            finalize(() => (this.isSearching = false))
          );
      })
    );
  }
}
