import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'iad-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogConfirmComponent {
  public confirmLabel: string;
  public cancelLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DialogConfirmComponent>) {
    this.confirmLabel = data.confirmBtn || _('common.labels.dialog.confirm');
    this.cancelLabel = data.cancelBtn || _('common.labels.dialog.cancel');
  }

  /**
   * Method to close dialog.
   */
  closeDialog(isConfirm: boolean = false): void {
    this.dialogRef.close(isConfirm);
  }
}
