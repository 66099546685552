import { TablePagination } from '../../models';

export class TableConstants {
  public static readonly DEFAULT_PAGINATION: TablePagination = {
    disabled: false,
    hidePageSize: false,
    pageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    showFirstLastButtons: false
  };
}
