import { Inject, Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';

import { CORE_CONFIG, CoreConfig, SubUrlProperty, UrlProperty } from '../../models/core-config/core-config.interface';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  constructor(@Inject(CORE_CONFIG) private config: CoreConfig) {}

  /**
   * Method to get Url from environment.
   * @param id - url Id
   * @param concession - concession (ex: france)
   */

  get(id: string, concession: string = 'france'): string {
    const urlProp: UrlProperty = this.config.urls.find((url: UrlProperty) => url.id === id);
    if (urlProp && urlProp.urls && Array.isArray(urlProp.urls)) {
      const subUrl: SubUrlProperty = urlProp.urls.find((url: SubUrlProperty) => url.name === concession);
      return subUrl && subUrl.url;
    } else {
      return urlProp && urlProp.url;
    }
  }

  /**
   * Get the urls config from environment.
   *
   * @returns The url config collection.
   */

  getUrls(): Array<any> {
    return cloneDeep(this.config.urls);
  }
}
