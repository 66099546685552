<div class="loading-dialog" fxLayout="column" fxLayoutAlign="space-around center">
  <mat-icon class="fal" svgIcon="loading-character"></mat-icon>

  <iad-loader [mode]="data.mode || MatSpinnerMode.INDETERMINATE"
              [value]="$loaderPercent | async"
              [diameter]="LoadingDialogConstants.LOADER_DIAMETER"
              [strokeWidth]="LoadingDialogConstants.LOADER_STROKE_WIDTH">
  </iad-loader>

  <p class="text-center">{{ data.content }}</p>
</div>

