export class AuthConstant {
  public static USER_KEY = 'user';
  public static JWT_TOKEN_KEY = 'jwt_token';

  /**
   * Static method to get all Auth keys
   */
  public static GET_ALL_KEYS(): any {
    return [AuthConstant.USER_KEY, AuthConstant.JWT_TOKEN_KEY];
  }
}
