import { NgModule } from '@angular/core';

import { DownloadDirective } from './directives/download/download.directive';
import { DynamicComponentDirective } from './directives/dynamic-component/dynamic-component.directive';
import { FeatureFlipDirective } from './directives/feature-flip/feature-flip.directive';
import { LetDirective } from './directives/let/let.directive';

const EXPORTED_DECLARATIONS = [
  LetDirective,
  DownloadDirective,
  FeatureFlipDirective,
  DynamicComponentDirective,
];

@NgModule({
  declarations: EXPORTED_DECLARATIONS,
  exports: EXPORTED_DECLARATIONS
})
export class DirectivesModule {}
