import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSliderChange } from '@angular/material';

import { MatThemePalette } from '../../../models/material/material.enum';

@Component({
  selector: 'iad-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true
    }
  ]
})
export class SliderComponent implements ControlValueAccessor, OnInit, OnChanges {
  /** Whether the component is disabled */
  @Input() disabled: boolean;

  /** Theme color palette used for the slider */
  @Input() color: MatThemePalette;

  /**
   * Function that will be used to format the value before it is displayed in the thumb label.
   * Can be used to format very large number in order for them to fit into the slider thumb
   */
  @Input() displayWith: (value: number) => string | number;

  /** Whether the slider is inverted */
  @Input() invert: boolean;

  /** The minimum value that the slider can have */
  @Input() min: number;

  /** The maximum value that the slider can have */
  @Input() max: number;

  /** Slider's value */
  @Input() value: number | null;

  /** The values at which the thumb will snap */
  @Input() step: number;

  /** Whether or not to show the thumb label */
  @Input() thumbLabel: boolean;

  /**
   * How often to show ticks. Relative to the step so that a tick always appears on a step.
   * Ex: Tick interval of 4 with a step of 3 will draw a tick every 4 steps (every 12 values)
   */
  @Input() tickInterval: number | 'auto';

  /** Whether the slider is vertical */
  @Input() vertical: boolean;

  /** Whether or not to show the thumb label on component's initialization */
  @Input() showThumbLabelOnInit: boolean;

  /** The min slider's label */
  @Input() minLabel: string;

  /** The max slider's label */
  @Input() maxLabel: string;

  onChange: (value: number) => void;
  onTouched: () => void;

  constructor() {
    this.min = 0;
    this.max = 100;
    this.onChange = () => {};
    this.onTouched = () => {};
  }

  /**
   * Angular lifecycle hook
   * Assign few properties
   */
  ngOnInit(): void {
    this.value = this.value || this.min;
    this.minLabel = this.invert ? this.max.toString() : this.min.toString();
    this.maxLabel = this.invert ? this.min.toString() : this.max.toString();
  }

  /**
   * Angular lifecycle hook
   * If invert mode's enabled, swap label values
   * @param changes SimpleChanges
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invert) {
      [this.minLabel, this.maxLabel] = [this.maxLabel, this.minLabel];
    }
  }

  /**
   * Emit the change event.
   * @param event - MatSliderChange
   */
  emitChange(event: MatSliderChange): void {
    this.onChange(event.value);
    this.onTouched();
  }

  /**
   * We implement this method to keep a reference to the onChange callback function passed by the forms API
   */
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  /**
   * We implement this method to keep a reference to the onTouched callback function passed by the forms API
   * @param fn - OnTouched function
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * This is a basic setter that the forms API is going to use
   * @param value - value to write.
   */
  writeValue(value: number): void {
    if (value) {
      this.value = value;
    }
  }

  /**
   * Set stars in readonly mode.
   * @param isDisabled - form control disabled state
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
