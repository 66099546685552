export class MockGeocodeService {
  getCountriesAndConcessions = jasmine.createSpy('getCountriesAndConcessions');
  getCountryLocalities = jasmine.createSpy('getCountryLocalities');
  getCountryRegions1 = jasmine.createSpy('getCountryRegions1');
  getCountryRegions2 = jasmine.createSpy('getCountryRegions2');
  getCountryRegions3 = jasmine.createSpy('getCountryRegions3');
  getCountryRegions4 = jasmine.createSpy('getCountryRegions4');
  getCountryStreets = jasmine.createSpy('getCountryStreets');
  getCountryStreet = jasmine.createSpy('getCountryStreet');
  formatGeocodePattern = jasmine.createSpy('formatGeocodePattern');
}
