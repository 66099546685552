import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PdfViewerModule as Ng2PdfViewerModule } from 'ng2-pdf-viewer';

import { MaterialModule } from '../../../material.module';
import { LoaderModule } from '../loader/loader.module';
import { PdfViewerNavModule } from './../pdf-viewer-nav/pdf-viewer-nav.module';
import { PdfViewerComponent } from './pdf-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    Ng2PdfViewerModule,
    MaterialModule,
    PdfViewerNavModule,
    LoaderModule
  ],
  declarations: [PdfViewerComponent],
  exports: [PdfViewerComponent]
})
export class PdfViewerModule { }
