import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginatorIad extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  /**
   * Method overridden from MatPaginatorIntl
   */
  getAndInitTranslations(): void {
    this.itemsPerPageLabel = this.translate.instant('matPaginatorIad.itemPerPageLabel');
    this.nextPageLabel = this.translate.instant('matPaginatorIad.nextPageLabel');
    this.previousPageLabel = this.translate.instant('matPaginatorIad.previousPageLabel');
    this.lastPageLabel = this.translate.instant('matPaginatorIad.lastPageLabel');
    this.firstPageLabel = this.translate.instant('matPaginatorIad.firstPageLabel');
  }

  /**
   * Method overridden from MatPaginatorIntl
   */
  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 à ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} -  ${endIndex} à ${length}`;
  }
}
