export class MockUtilService {
  toCamelCase = jasmine.createSpy('toCamelCase');
  deepCopy = jasmine.createSpy('deepCopy');
  getFileContent = jasmine.createSpy('getFileContent');
  escapeRegExp = jasmine.createSpy('escapeRegExp');
  downloadFile = jasmine.createSpy('downloadFile');
  getFilename = jasmine.createSpy('getFilename');
  getBlobFromBase64 = jasmine.createSpy('getBlobFromBase64');
  getIsoCodeFromCountry = jasmine.createSpy('getIsoCodeFromCountry');
  clearFormArray = jasmine.createSpy('clearFormArray');
  getDefaultBirthDate = jasmine.createSpy('getDefaultBirthDate');
}
