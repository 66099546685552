import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { Action } from '@ngrx/store';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';

import { BottomSheetOverlayData } from './../../../models/bottom-sheet/bottom-sheet-overlay-data.interface';

@Component({
  selector: 'iad-bottom-sheet-overlay',
  templateUrl: './bottom-sheet-overlay.component.html',
  styleUrls: ['./bottom-sheet-overlay.component.scss']
})
export class BottomSheetOverlayComponent<T = any> implements OnInit {
  @ViewChild('content', { static: true }) contentContainer: ElementRef<HTMLElement>;
  data: BottomSheetOverlayData<T>;
  hasScrolled$: Observable<boolean>;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetOverlayComponent<T>, Array<Action>>,
    @Inject(MAT_BOTTOM_SHEET_DATA) data: any // We can't type correctly the data because of an AOT issue.
  ) {
    // This data will be passed into the `iadDynamicContent` directive.
    // We need to pass the `MatBottomSheetRef` to handle custom bottom sheet dismiss into the dynamic component.
    this.data = { ...data, bottomSheetRef: this.bottomSheetRef };
  }

  /**
   * On initialization, define the `hasScrolled$` observable
   * used to trigger the divider display.
   */
  ngOnInit() {
    this.hasScrolled$ = this.getContentScrollEvent().pipe(
      pluck('target'),
      map((target: HTMLElement) => target.scrollTop > 0),
      distinctUntilChanged()
    );
  }

  /**
   * Returns an observable of content `scroll` event.
   */
  getContentScrollEvent(): Observable<Event> {
    return fromEvent(this.contentContainer.nativeElement, 'scroll');
  }

  /**
   * Triggers the `MatBottomSheetRef` dismiss method.
   */
  dismiss(): void {
    this.bottomSheetRef.dismiss();
  }
}
