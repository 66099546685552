import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Input, OnChanges, OnDestroy, OnInit, Optional, QueryList, Self, ViewChildren
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { MatChip } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChipListItem } from '../../../models/chip-list-item/chip-list-item.interface';
import { IadExtendedCVAField } from '../../../abstracts/extended-cva-field/extended-cva-field.class';

@Component({
  selector: 'iad-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipListComponent<T> extends IadExtendedCVAField<T | T[]> implements OnInit, AfterViewInit, OnDestroy {
  /** The options collection to display. */
  @Input() items: Array<ChipListItem<T>>;

  /** Whether the matChipList is in multiple selection mode or not. */
  @Input() multiple: boolean;

  /** The label to display for the select all matChip. */
  @Input() allSelectedText: string;

  /** A function to compare the option values with the selected values. */
  @Input() compareWith: (o1: T, o2: T) => boolean;

  /** Accessors to the control value. */
  @Input()
  get value(): T | T[] {
    return !Array.isArray(this.formControl.value) ? this.formControl.value : (this.formControl.value as T[])
      .filter((value: T): boolean => !!value);
  }

  set value(value: T | T[]) {
    this.writeValue(value);
  }

  /** The matChip components contained within this matChipList. */
  @ViewChildren('chip') chips: QueryList<MatChip>;

  isAllSelected: boolean;
  destroy$: Subject<void>;

  constructor(@Optional() @Self() ngControl: NgControl, cdr: ChangeDetectorRef) {
    super(new FormControl(), ngControl, cdr);
    this.multiple = false;
    this.isAllSelected = false;
    this.destroy$ = new Subject<void>();
    this.compareWith = (o1: T, o2: T) => o1 === o2;
  }

  /**
   * Let's subscribe to the internal formControl valueChanges to notify observers about the matChipList selection
   * changes. As a side effect here, we need to check the select all matChip state.
   */
  ngOnInit(): void {
    this.formControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.checkAllSelected();
      this.onChange(this.value);
    });
  }

  /**
   * Clean up internal subscriptions by completing destroy$ subject.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * We need to wait for the afterViewInit component hook to init the chips stuff. Because at
   * this time we are sure to have the chipList properly available. We need to subscribe to
   * the chips queryList change eventEmitter to init the chips again. This event occurs when the
   * items input changes over time.
   */
  ngAfterViewInit(): void {
    this.checkAllSelected();
    this.chips.changes.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => this.checkAllSelected());
  }

  /**
   * Straight forward implementation of the writeValue() here. Simply update the formControl value without
   * notification and update the select all matChip selection state.
   * @param value - The value changes.
   */
  writeValue(value: T | T[]): void {
    this.formControl.setValue(value, { emitEvent: false });
    this.checkAllSelected();
  }

  /**
   * Shorthand method used to update the select all matChip selection state.
   */
  checkAllSelected(): void {
    const values = Array.isArray(this.value) ? this.value : [this.value];
    this.isAllSelected = this.multiple && (this.items || [])
      .filter((item: ChipListItem<T>): boolean => !item.disabled)
      .every((item: ChipListItem<T>): boolean => values.includes(item.value));
  }

  /**
   * Call the toggleSelected() method if the current matChip is enabled.
   * @param chip - The matChip item to toggle selection state;
   */
  toggle(chip: MatChip): void {
    !chip.disabled && chip.toggleSelected(true);
  }

  /**
   * Toggle all matChips selection state depending on the allChip selected flag.
   * @param allChip - The allMatChip to toggle selection state;
   */
  toggleAll(allChip: MatChip): void {
    if (!allChip.disabled) {
      const method = allChip.selected ? 'deselect' : 'select';
      this.chips.filter((chip: MatChip): boolean => !chip.disabled)
        .forEach((chip: MatChip): void => chip[method]());
      allChip.toggleSelected(true);
    }
  }
}
