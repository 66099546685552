import { HttpEvent, HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UploadStatus } from '../../models/upload/upload-status.enum';
import { HttpParameter } from '../../models/upload/uploader-config.interface';
import { UploaderFileState } from '../../models/upload/uploader-file-state.interface';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private apiService: ApiService) { }

  /**
   * Upload one file
   *
   * @param file File - file to upload
   * @param api string - api name based on environment file
   * @param url string - endpoint to upload the file
   */
  upload(file: File, api: string, url: string, parameters: Array<HttpParameter> = []): Observable<UploaderFileState> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    parameters.forEach((param) => {
      formData.append(param.parameter, param.value);
    });

    return this.apiService.post(api, url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event: HttpEvent<HttpProgressEvent>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return { status: UploadStatus.PROGRESSING, loaded: 0, progression: 0 };
          case HttpEventType.UploadProgress:
            const progression = this.calcPercentageOfProgression(event.loaded, event.total);
            return { status: UploadStatus.PROGRESSING, loaded: event.loaded, progression };
          case HttpEventType.Response:
            return { status: UploadStatus.SUCCESS, loaded: file.size, progression: 100, data: event.body };
        }
      }),
      catchError((error: string) => of({ status: UploadStatus.FAIL, loaded: 0, message: error }))
    );
  }

  /**
   * Remove file from server
   * @param file UploadFile - delete server's file
   */
  remove(api: string, url: string): Observable<void> {
    return this.apiService.delete(api, url);
  }

  /**
   * Get upload's percentage of progression.
   *
   * @param loaded number - current file's size loaded
   * @param total number - total number to load
   */
  private calcPercentageOfProgression(loaded: number, total: number): number {
    return Math.round((100 * loaded) / total);
  }
}
