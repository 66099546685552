import { EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { getReferencesSelector } from '..';
import { Reference, ReferencesSlugs } from '../../models';
import { adapter, ReferencesState } from './references.reducer';

export const selectReferencesLoading = createSelector(
  getReferencesSelector,
  (state: ReferencesState): boolean => state.loading
);
export const selectReferencesError = createSelector(
  getReferencesSelector,
  (state: ReferencesState): string => state.error
);
export const selectReferences = createSelector(
  getReferencesSelector,
  (state: ReferencesState): { [slug: string]: EntityState<Reference> } => state.references
);

const selectRef = createSelector(
  selectReferences,
  (refs: { [slug: string]: EntityState<Reference> }, slug: ReferencesSlugs) => refs[slug] || adapter.getInitialState()
);

export const selectReference = () => createSelector(selectRef, adapter.getSelectors().selectAll);
