import { Directive, HostBinding } from '@angular/core';

/**
 * Title of the `OverlayLayoutComponent`, used as `ng-content` selector.
 */
@Directive({
  selector: '[iad-overlay-layout-title], [iadOverlayLayoutTitle]',
  host: { class: 'iad-overlay-layout-title' }
})
export class OverlayLayoutTitleDirective {}
