import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'iad-system-content',
  templateUrl: './system-content.component.html',
  styleUrls: ['./system-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemContentComponent implements OnInit {
  cardWidth: number;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.cardWidth = this.route.snapshot.data['cardWidth'] || 480;
  }
}
