import { createAction } from '@ngrx/store';

import { SnackbarDisplayTypes, SnackbarPayload } from '../../models';

export const dismiss = createAction('[SNACKBAR] DISMISS');
export const show = createAction('[SNACKBAR] SHOW', (options: SnackbarPayload) => ({
  ...options,
  message: options.message || '',
  duration: options.duration || 3000,
  action: options.action || null,
  displayType: options.displayType || SnackbarDisplayTypes.SUCCESS
}));
