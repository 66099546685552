import { productionawsEnvironment } from '@iad-digital/iad-ngx-core';

export const environment = {
  ...productionawsEnvironment,
  apis: [
    ...productionawsEnvironment.apis,
    {
      id: 'billing',
      url: 'https://billing.api.playiad.com',
      host: 'billing.api.playiad.com',
      options: {}
    },
    {
      id: 'production',
      url: 'https://production.api.playiad.com',
      host: 'production.api.playiad.com',
      options: {}
    }
  ],
  urls: [
    ...productionawsEnvironment.urls,
    {
      id: 'ng',
      url: 'https://billing.playiad.com'
    },
    {
      id: 'production',
      url: 'https://production.playiad.com'
    },
    {
      id: 'billing',
      url: 'https://billing.playiad.com'
    },
    {
      id: 'external-business',
      url: 'https://businessindication.playiad.com'
    },
    {
      id: 'network',
      url: 'https://network.playiad.com'
    }
  ],
  locale: {
    ...productionawsEnvironment.locale,
    directories: [
      ...productionawsEnvironment.locale.directories,
      'shared',
      'billing',
      'payment-interface',
      'export-billing',
      'approximation'
    ]
  },
  enabledJwt: true
};
