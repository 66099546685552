import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';

import { MaterialModule } from '../../../material.module';
import { PhoneInputComponent } from './phone-input.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule.forChild(), TextMaskModule, MaterialModule],
  declarations: [PhoneInputComponent],
  exports: [PhoneInputComponent]
})
export class PhoneInputModule {}
