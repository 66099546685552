import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Capacitation, CapacitationDetail, CollaboratorStatusEnum } from '../../models';
import { PaginatedResult, PaginatedResultV2 } from '../../models/api-response/api-response.interface';
import { User } from '../../models/user/user.interface';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  constructor(private apiService: ApiService) {}

  /**
   * Retrieve all agents.
   *
   * @param filters - Filters that defined the REST call route granularity.
   * @returns Observable - A paginated agent list
   */
  getAgents(filters?: {
    name?: string;
    slug?: string;
    capacitation?: string | number;
    concession?: string;
    sector?: string | number;
    radius?: any;
    isManager?: boolean;
    tutorId?: string | number;
    circle?: string | number;
    page?: number;
    numberPerPage?: number;
  }): Observable<PaginatedResult<User>> {
    return this.apiService.get<PaginatedResult<User>>('wis', '/directory/directories/agents', { params: filters });
  }

  /**
   *
   * Retrieve agent history.
   *
   * @param filters - Filters that defined the REST call route granularity.
   * @returns Observable - Return an observable of history.
   */
  getAgentsHistory(filters?: {
    idPeople?: number;
    field_name?: string;
    page?: number;
    number_per_page?: number;
  }): Observable<PaginatedResultV2<History>> {
    return this.apiService.get<PaginatedResultV2<History>>('wis', '/directory/directories/agents/history', {
      params: filters
    });
  }

  /**
   * Check if user is an head quarter.
   * @returns Observable - Observable of boolean
   */
  isHeadQuarter(): Observable<boolean> {
    return this.apiService.get<boolean>('wis', '/directory/directories/agents/is_head_quarter');
  }

  /**
   *
   * Retrieve agents by status.
   *
   * @param CollaboratorStatusEnum status - status of the agent
   * @param filters - Filters that defined the REST call route granularity.
   * @returns Observable - Return an observable of agents.
   */
  getAgentsByStatus(
    status: CollaboratorStatusEnum,
    filters?: {
      slug?: string;
      search?: string;
      isManager?: boolean;
      page?: number;
      numberPerPage?: number;
    }
  ): Observable<PaginatedResult<User>> {
    const params = {
      status,
      ...filters
    };

    return this.apiService.get<PaginatedResult<User>>('wis', '/directory/directories/agents/status', { params });
  }

  /**
   *
   * Archive an agent.
   *
   * @param peopleId - The agent's people ID.
   * @returns Observable - Return an observable of object.
   */
  archiveAgent(peopleId: number): Observable<{ success: string }> {
    return this.apiService.patch<{ success: string }>(
      'wis',
      `/directory/directories/agents/${peopleId}/archived`,
      null
    );
  }

  /**
   *
   * Blacklist an agent.
   *
   * @param peopleId - The agent's people ID.
   * @returns Observable - Return an observable of object.
   */
  blacklistAgent(peopleId: number): Observable<{ success: string }> {
    return this.apiService.patch<{ success: string }>(
      'wis',
      `/directory/directories/agents/${peopleId}/blacklisted`,
      null
    );
  }

  /**
   *
   * Change the administrator of an agent.
   *
   * @param adminPeopleId - The admin's people ID.
   * @param agentPeopleId - The agent's people ID.
   * @returns Observable - Return an observable of object.
   */
  changeAgentAdministrator(adminPeopleId: number, agentPeopleId: number): Observable<{ success: string }> {
    return this.apiService.patch<{ success: string }>(
      'wis',
      `/directory/directories/agents/${agentPeopleId}/administrator`,
      {
        adminIdPeople: adminPeopleId
      }
    );
  }

  /**
   *
   * Add a new comment.
   *
   * @param number peopleId - The agent's people ID.
   * @param string comment - The new comment.
   * @returns Observable - Return an observable of object.
   */
  addComment(peopleId: number, comment: string): Observable<{ success: string }> {
    return this.apiService.post<{ success: string }>('wis', `/directory/directories/agents/${peopleId}/comment`, {
      comment
    });
  }

  /**
   *
   * Get all agent's comments.
   *
   * @param number peopleId - The agent's people ID.
   * @param filters - Filters that defined the REST call route granularity.
   * @returns Observable - Return an observable paginated of comments.
   */
  getAgentComments(
    peopleId: number,
    filters?: {
      all?: boolean;
      page?: number;
      number_per_page?: number;
    }
  ): Observable<PaginatedResultV2<Comment>> {
    const params = filters;

    return this.apiService.get<PaginatedResultV2<Comment>>(
      'wis',
      `/directory/directories/agents/${peopleId}/comments`,
      { params }
    );
  }

  /**
   *
   * Get agent's capacitations.
   *
   * @param number peopleId - The agent's people ID.
   * @returns Observable - Return an observable of capacitations.
   */
  getAgentCapacitations(peopleId: number): Observable<Array<Capacitation>> {
    return this.apiService.get<Array<Capacitation>>('wis', `/directory/agents/${peopleId}/capacitation`);
  }

  /**
   *
   * Add capacitation for an agent.
   *
   * @param number peopleId - The agent's people ID.
   * @param number subJobConcessionId - Sub job concession ID.
   * @param params - Optionnal params to add capacitation
   * @returns Observable - Return an observable of capacitation detail.
   */
  addAgentCapacitation(
    peopleId: number,
    subJobConcessionId: number,
    params?: {
      exempted?: boolean;
      blocked?: boolean;
    }
  ): Observable<CapacitationDetail> {
    const data = {
      idConcessionSubJob: subJobConcessionId,
      ...params
    };

    return this.apiService.post<CapacitationDetail>('wis', `/directory/agents/${peopleId}/capacitation`, data);
  }
}
