import { ComponentType } from '@angular/cdk/portal';
import { MatBottomSheetConfig } from '@angular/material';
import { Action, createAction, props } from '@ngrx/store';

import { BottomSheetListItem } from './../../models/bottom-sheet/bottom-sheet-list-item.interface';

export const dismissSheet = createAction('[BOTTOM_SHEET] DISMISS');

export const openListSheet = createAction(
  '[BOTTOM_SHEET] OPEN_LIST_SHEET',
  props<{ items: Array<BottomSheetListItem>; afterDismissedActions?: Array<Action>; }>()
);

export const openOverlaySheet = createAction(
  '[BOTTOM_SHEET] OPEN_OVERLAY_SHEET',
  props<{ component: ComponentType<any>; title: string; config?: MatBottomSheetConfig; afterDismissedActions?: Array<Action> }>()
);
