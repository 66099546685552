import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route } from '@angular/router';
import { camelCase } from 'lodash';

import { CORE_CONFIG, CoreConfig } from '../models';

/**
 * FeatureFlipGuard check if the feature's route is enable.
 * Feature's activation is set on environment file (featureFlags property).
 * You can use the guard for canActivate or canLoad.
 *
 * You can add a "feature" property on route data :
 * ```
 * // canActivate
 * {
 *    path: 'my-feature',
 *    component: MyFeatureComponent,
 *    canActivate: [FeatureFlipGuard],
 *    data: {
 *      feature: 'my-feature-name',
 *      ...
 *    }
 * }
 *
 * // canLoad
 * {
 *    path: 'my-feature',
 *    canLoad: [FeatureFlipGuard],
 *    loadChildren: ...,
 *    data: {
 *      feature: 'my-feature-name',
 *      ...
 *    }
 * }
 * ```
 *
 * if no "feature" property is specify, the guard will takes the path.
 * ```
 * {
 *    path: 'my-feature',
 *    component: MyFeatureComponent,
 *    canActivate: [FeatureFlipGuard]
 * }
 * ```
 */
@Injectable({
  providedIn: 'root'
})
export class FeatureFlipGuard implements CanActivate, CanLoad {

  constructor(@Inject(CORE_CONFIG) private config: CoreConfig) {}

  /**
   * Called by the router when the guard is used on Route's canActivate property.
   * @param route ActivatedRouteSnapshot - The route to navigate
   */
  canActivate(next: ActivatedRouteSnapshot): boolean {
    return this.isFeatureEnabled(next.routeConfig);
  }

  /**
   * Called by the router when the guard is used on Route's canLoad property.
   * @param route Route - The route to navigate
   */
  canLoad(route: Route): boolean {
    return this.isFeatureEnabled(route);
  }

  /**
   * Handles route access by checking a feature based on the route data feature property or route path.
   *
   * @param route Route - the route to allow access
   * @returns true if the feature is enabled.
   */
  private isFeatureEnabled(route: Route): boolean {
    const featureName = (route.data && route.data.feature) || camelCase(route.path);

    return this.config.featureFlags[featureName];
  }
}
