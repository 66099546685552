import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Capacitation, CapacitationProgram, CapacitationSubJob } from '../../models';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CapacitationService {
  constructor(private apiService: ApiService) {}

  /**
   * Fetch collaborator's capacitations.
   *
   * @param number collaboratorId - collaborator ID
   * @returns Observable - Return an observable of capacitations.
   */
  getCollaboratorCapacitations(collaboratorId: number): Observable<Array<Capacitation>> {
    const params = { collaborator: collaboratorId };

    return this.apiService.get<Array<Capacitation>>('wis', '/directory/capacitations/list', { params });
  }

  /**
   * Fetch capacitations of a concession.
   *
   * @param string slug - concession's slug
   * @returns Observable - Return an observable of capacitations.
   */
  getCapacitationsByConcessionSlug(slug: string): Observable<Array<CapacitationSubJob>> {
    return this.apiService.get<Array<CapacitationSubJob>>('wis', `/directory/capacitation/concession/${slug}`);
  }

  /**
   * Block a capacitation.
   *
   * @param number capacitationId - The ID of the capacitation.
   * @returns Observable
   */
  patchCapacitationBlocked(capacitationId: number): Observable<any> {
    return this.apiService.patch<any>('wis', `/directory/capacitation/${capacitationId}/blocked`, null);
  }

  /**
   * Exempte a capacitation.
   *
   * @param number capacitationId - The ID of the capacitation.
   * @returns Observable
   */
  patchCapacitationExempted(capacitationId: number): Observable<any> {
    return this.apiService.patch<any>('wis', `/directory/capacitation/${capacitationId}/exempted`, null);
  }

  /**
   * Fetch collaborator's capacitation programs.
   *
   * @param number collaboratorId - The collaborator ID.
   * @returns Observable - A list of capacitation programs.
   */
  getCollaboratorCapacitationPrograms(collaboratorId: number): Observable<Array<CapacitationProgram>> {
    const params = { collaborator: collaboratorId };

    return this.apiService.get<Array<CapacitationProgram>>('wis', '/directory/capacitations/programs/list', { params });
  }
}
