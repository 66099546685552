import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { RouterStateUrl } from '../models/router';
import { MenuState } from './menu/menu.reducer';
import { NotificationsState } from './notifications/notifications.reducer';
import { ReferencesState } from './references/references.reducer';
import { UserState } from './user';

export interface CoreState {
  user: UserState;
  router: RouterReducerState<RouterStateUrl>;
  references: ReferencesState;
  notifications: NotificationsState;
  menu: MenuState;
}

export const getCoreState: MemoizedSelector<object, CoreState> = createFeatureSelector<CoreState>('core');

export const getRouterState = createSelector(
  getCoreState,
  (state: CoreState): RouterReducerState<RouterStateUrl> => state.router
);

// USER ---------------------
export const selectUserState = createSelector(getCoreState, (state: CoreState): UserState => state.user);
// --------------------------

// REFERENCES ---------------
export const getReferencesSelector = createSelector(getCoreState, (state: CoreState) => state.references);
// --------------------------

// NOTIFICATION -------------------
export const getNotificationsSelector = createSelector(getCoreState, (state: CoreState) => state.notifications);
// --------------------------

// Menu ---------------------
export const getMenuState = createSelector(getCoreState, (state: CoreState): MenuState => state.menu);
// --------------------------
