import { NgModule } from '@angular/core';

import { LayoutsModule } from './components/layouts/layouts.module';
import { ActionMenuModule } from './components/ui/action-menu/action-menu.module';
import { AddressModule } from './components/ui/address/address.module';
import { AvatarModule } from './components/ui/avatar/avatar.module';
import { ChipListModule } from './components/ui/chip-list/chip-list.module';
import {
  CollaboratorsAutocompleteModule,
} from './components/ui/collaborators-autocomplete/collaborators-autocomplete.module';
import { CopyToClipboardModule } from './components/ui/copy-to-clipboard/copy-to-clipboard.module';
import { CountryAutocompleteModule } from './components/ui/country-autocomplete/country-autocomplete.module';
import { CurrencyInputModule } from './components/ui/currency-input/currency-input.module';
import { DocumentViewerModule } from './components/ui/document-viewer/document-viewer.module';
import { InputGeocodeModule } from './components/ui/input-geocode/input-geocode.module';
import { LoaderModule } from './components/ui/loader/loader.module';
import { OverlayLayoutModule } from './components/ui/overlay-layout/overlay-layout.module';
import { PdfViewerModule } from './components/ui/pdf-viewer/pdf-viewer.module';
import { PhoneInputModule } from './components/ui/phone-input/phone-input.module';
import { PlaceInputModule } from './components/ui/place-input/place-input.module';
import { RatingModule } from './components/ui/rating/rating.module';
import { SliderModule } from './components/ui/slider/slider.module';
import { SpeedDialModule } from './components/ui/speed-dial/speed-dial.module';
import { TableModule } from './components/ui/table/table.module';
import { TimepickerInputModule } from './components/ui/timepicker-input/timepicker-input.module';
import { UploadInputModule } from './components/ui/upload-input/upload-input.module';
import { UploaderModule } from './components/ui/uploader/uploader.module';
import { XeditableModule } from './components/ui/xeditable/xeditable.module';

@NgModule({
  imports: [
    ActionMenuModule,
    AvatarModule,
    ChipListModule,
    LayoutsModule,
    XeditableModule,
    AddressModule,
    RatingModule,
    PhoneInputModule,
    PlaceInputModule,
    InputGeocodeModule,
    CollaboratorsAutocompleteModule,
    CountryAutocompleteModule,
    SpeedDialModule,
    PdfViewerModule,
    UploaderModule,
    UploadInputModule,
    LoaderModule,
    DocumentViewerModule,
    SliderModule,
    TableModule,
    CurrencyInputModule,
    TimepickerInputModule,
    OverlayLayoutModule,
    CopyToClipboardModule
  ],
  exports: [
    ActionMenuModule,
    AvatarModule,
    ChipListModule,
    LayoutsModule,
    XeditableModule,
    AddressModule,
    RatingModule,
    PhoneInputModule,
    PlaceInputModule,
    InputGeocodeModule,
    CollaboratorsAutocompleteModule,
    CountryAutocompleteModule,
    SpeedDialModule,
    UploaderModule,
    PdfViewerModule,
    UploadInputModule,
    LoaderModule,
    DocumentViewerModule,
    SliderModule,
    TableModule,
    CurrencyInputModule,
    TimepickerInputModule,
    OverlayLayoutModule,
    CopyToClipboardModule
  ]
})
export class ComponentsModule {}
