import { Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ExcelService, fromSnackbarActions, SnackbarDisplayTypes } from '@iad-digital/iad-ngx-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as fromExportTableActions from './export-table.actions';

@Injectable()
export class ExportTableEffects {
  exportTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromExportTableActions.exportTable),
      switchMap(({ title, table, config }) =>
        this.excelService.exportTable(this.translateService.instant(title), table, config).pipe(
          map(() =>
            fromSnackbarActions.show({
              message: _('shared.table.export.success'),
              displayType: SnackbarDisplayTypes.SUCCESS
            })
          ),
          catchError(() => [
            fromSnackbarActions.show({
              message: _('shared.table.export.error'),
              displayType: SnackbarDisplayTypes.ERROR
            })
          ])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private excelService: ExcelService,
    private translateService: TranslateService
  ) {}
}
