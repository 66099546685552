import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable, Injector, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { User } from '../models';
import { AuthService } from '../services/auth/auth.service';
import { UtilService } from '../services/util/util.service';
import { WindowRef } from '../services/window/window.service';
import { CoreState } from '../store';
import * as RouterActions from '../store/router';
import { CORE_CONFIG, CoreConfig } from './../models/core-config/core-config.interface';
import { AuthConstant } from './../services/auth/auth.constants';
import { UrlService } from './../services/url/url.service';
import * as AuthActions from './../store/auth/auth.actions';
import { HttpConstants } from '../constants/http/http.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;

  constructor(
    @Inject(CORE_CONFIG) private config: CoreConfig,
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private store: Store<CoreState>,
    private utilService: UtilService,
    private urlService: UrlService,
    private windowRef: WindowRef,
    private injector: Injector
  ) {}

  /**
   * Handle authentication error.
   * @param err - request error
   */
  handleAuthError(err: HttpErrorResponse): Observable<any> {
    const data = this.utilService.toCamelCase(err.error);
    switch (err.status) {
      case 401:
        if (err.headers.get('X-SSO-SESSION-ERROR') === 'login_requested') {
          this.redirectToLogin();
        } else if (err.error.message === 'Invalid JWT Token') {
          this.redirectToLogin();
        }
        break;
      case 403:
        this.store.dispatch(RouterActions.go({ path: ['/403'] }));
        break;
      case 423:
        if (data.forceRedirect) {
          const encodedUrl = encodeURIComponent(this.authService.getCurrentPathUrl());
          const path = data.forceRedirect[Object.keys(data.forceRedirect)[0]];
          this.windowRef.nativeWindow.location.href = `${path}?returnto=${encodedUrl}`;
        }
        break;
    }
    return throwError(err.error);
  }

  /**
   * Intercept method
   * @param req - request
   * @param next - next handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(HttpConstants.SKIP_INTERCEPTOR_HEADER)) {
      const headers = req.headers.delete(HttpConstants.SKIP_INTERCEPTOR_HEADER);
      return next.handle(req.clone({ headers })).pipe(
        map((event: HttpEvent<any>) => this.camelizeResponse(event)),
        catchError(err => throwError(err))
      );
    }

    this.authService = this.injector.get(AuthService);

    // add custom header for cas auth
    const reqClone: HttpRequest<any> = req.clone({
      withCredentials: req.url.includes('/common/geocode') ? false : true
    });
    reqClone.headers.delete('X-Requested-With');

    if (
      this.authService &&
      this.config.enabledJwt &&
      this.authService.isTokenExpired() &&
      !req.url.includes('/manager/get-token') &&
      !req.url.endsWith('/people/me')
    ) {
      // if JWT is enabled and token is expired, make a getToken call and retry the previous xhr call.
      this.authService.logout();
      return this.authService.getAuthToken().pipe(
        tap((user: User) => this.store.dispatch(AuthActions.loginSuccess({ user }))),
        //  We need to set up the Bearer manually to get the new token, because the JwtInterceptor not works when token is expired.
        // reqClone.headers.append('Authorization', `Bearer ${this.storage.get(AuthConstant.JWT_TOKEN_KEY)}`);
        switchMap(() =>
          this.handleNextRequest(
            req.clone({
              setHeaders: {
                Authorization: `Bearer ${this.storage.get(AuthConstant.JWT_TOKEN_KEY)}`
              }
            }),
            next
          )
        ),
        catchError((err: any) => {
          this.redirectToLogin();
          return EMPTY;
        })
      );
    } else {
      return this.handleNextRequest(reqClone, next);
    }
  }

  /**
   * Handle next request coming from interceptor.
   * @param req - http request object
   * @param next - next Http Handler
   */
  private handleNextRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => this.camelizeResponse(event)),
      catchError((err: any) => this.handleAuthError(err))
    );
  }

  /**
   * Apply a toCamelCase on backend response.
   * @param event - Http event
   */
  private camelizeResponse(event: HttpEvent<any>): HttpEvent<any> {
    return event instanceof HttpResponse && event.body
      ? event.clone({
          body: this.utilService.toCamelCase(event.body)
        })
      : event;
  }

  /**
   * Redirect to login and clear sessionStorage data.
   */
  private redirectToLogin(): void {
    this.authService.logout();
    this.windowRef.nativeWindow.location.href = this.authService.getAuthLoginUrl();
  }
}

export const AuthInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
};
