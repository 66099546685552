import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../../material.module';
import { PlaceInputModule } from '../place-input/place-input.module';
import { CountryAutocompleteModule } from './../country-autocomplete/country-autocomplete.module';
import { AddressComponent } from './address.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule.forChild(),
    PlaceInputModule,
    CountryAutocompleteModule
  ],
  declarations: [AddressComponent],
  exports: [AddressComponent]
})
export class AddressModule {}
