import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatTooltipModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

import { CopyToClipboardComponent } from './copy-to-clipboard.component';
import { CopyToClipboardDirective, CopyToClipboardTooltipDirective } from './copy-to-clipboard.directive';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    OverlayModule,
    TranslateModule
  ],
  exports: [CopyToClipboardDirective, CopyToClipboardTooltipDirective, CopyToClipboardComponent],
  declarations: [CopyToClipboardDirective, CopyToClipboardTooltipDirective, CopyToClipboardComponent]
})
export class CopyToClipboardModule {}
