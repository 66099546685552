import { InjectionToken } from '@angular/core';
import {TopbarEnvironment} from "./core-config.enum";

export const CORE_CONFIG = new InjectionToken<CoreConfig>('CORE_CONFIG');

export class CoreConfig {
  production: boolean;
  name: string;
  apis: Array<CoreApiConfig>;
  urls: Array<UrlProperty>;
  locale: CoreLocaleConfig;
  enabledJwt: boolean;
  featureFlags?: Record<string, boolean>;
  topbarEnvironment?: TopbarEnvironment;
}

export interface CoreApiConfig {
  id: string;
  url: string;
  host?: string;
  options: Options;
}

export interface UrlProperty {
  id: string;
  url: string;
  urls?: Array<SubUrlProperty>;
}

export interface SubUrlProperty {
  name: string;
  url: string;
}

export interface CoreUrlConfig {
  id: string;
  name?: string;
  url?: string;
  urls: Array<CoreUrlConfig>;
}

export interface CoreLocaleConfig {
  default: string;
  fallback: string;
  localeKeys: Array<string>;
  availables: Array<Available>;
  directories: Array<string>;
}

export interface Available {
  name: string;
  locale: any;
}

export interface Options {
  headers?: Headers;
  withCredentials?: boolean;
}

export interface Headers {
  Authorization: string;
}
